import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Malloc(){
  return (
    <>
      <TopHeader title="Malloc and Free" />
      <p>
      Malloc and Free are ways to dynamically allocate memory. What does that mean?
      
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default Malloc;
