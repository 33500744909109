import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Strings(){
  return (
    <>
      <TopHeader title="Strings (Charactar Arrays)" />
      <p>
      A String is actually just a character array. Each character is stored in an element in the array. 
      Below shows a sample program that initializes the same string in different ways.
      </p>
      <CodeBlock code={`
public class string1
{
  public static void main(String[] args)
  {
    String str1 = "shoCode";
    char [] x1 = str1.toCharArray();
    char x2[] = {'s','h','o','C','O','D','E', ' '};
    char[] x3 = new char[8];
    x3[0] = 's';
    x3[1] = 'h';
    x3[2] = 'o';
    x3[3] = 'C';
    x3[4] = 'O';
    x3[5] = 'D';
    x3[6] = 'E';
    x3[7] = ' ';
    String str4 = "shoCode";
    char [] x4 = new char[100];
    x4 = str4.toCharArray();
    System.exit(0);
  }
}
`}/>
<br/>
      <br/>
      <h3><strong>String Input/Output</strong></h3>
      <p> The split() function can be used to scan strings to scan in strings. In this case, will read a characters 
        into a string until it sees whitespace(newline, tab, space, etc.). This next program shows how you can scan in strings
        as a character array.
      </p>
      <CodeBlock code={`
import java.util.Scanner;

public class string2
{
  public static void main(String[] args)
  {
    Scanner input = new Scanner(System.in);
    
    System.out.println("Enter your full name (separated by a space): ");
    String nameData[] = input.nextLine().split(" ");
    
    String firstName = nameData[0];
    String lastName = nameData[1];

    System.out.println("First name: " + firstName);
    System.out.println("Last name: " + lastName);
  }
}
`}/>
<p> 
  Output: User enters sho\nCODE
</p>
<CodeBlock notCode code={`
sho     <-User Input
CODE    <-User Input
First Name: sho
Last Name: Code
`}/>
<br/>

<p>
  If instead, you wanted to scan an entire line of words or characters including all it's whitespace. 
  This can be accomplished using the nextLine() function.  Similar to the nextInt() function. 
An example of this is shown below.
</p>
<CodeBlock  code={`
mport java.util.Scanner;

public class string3
{
  public static void main(String[] args)
  {
    Scanner input = new Scanner(System.in);

    System.out.println("Enter a sentence with less than 100 characters: ");
    String str = input.nextLine();

    char [] c = str.toCharArray();

    System.out.println("Your sentence is below:");
    for(int i=0; i<str.length(); i++)
    {  
      System.out.print(c[i]);  
    }
  }
`}/>
<br/>
<p>Output:</p>
<CodeBlock notCode code={`
Enter a sentence with less than 100 characters:
shoCODE is AWESOME!         <-User Input
Your Sentence is below:
shoCODE is AWESOME!
`}/>
    </>
  );
}
export default Strings;
