import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Comments() {
  return (
    <>
      <TopHeader title="Comments" />

      <p>
        Comments are lines in the code that do not matter in the program. In other words, comments do not
        affect the output of a program at all.
        <br/>
        <br/>
        <h4>What are comments good for?</h4>
        Comments can be used to keep your own notes or give other developers clues about what
        the program is trying to accomplish. There are a few ways you can comment in python.
        You can use """, ''', or # in differnt ways. 
        <br/>
        <br/>
        If using the """ or ''' for comments then anything in between is ignored by the computer.
        If using the # then everything after the # that is on the same line is ignored by the computer.
        Some basic examples of a program with comments is shown below.
      </p>
      <CodeBlock code={`
"""
this is a multi line comment. Anthing between 
the three quotations will be ignored. This type 
is usually used for long comments like a description
"""

'''
it can also be written with
three apostrophes
'''

"""
The program always starts at the top 
and goes 1 line at a time.
"""

# This is a one line comment.
print("3")
# Anything after these '#' is ignored and can be placed between code
print("2")
print("1") # print 1 to the screen
print("0") # print 2 to the screen
print("Bye") # The program is finished
`} />
      <p>
        Can you guess what the output of this program is? 
        The program started at the top first line and executes statements one at a time (while ignoring all comments)
        until all statements have been completed.
        The output is shown below. Check to see if you guessed it right.
        </p>
        <br />
        <CodeBlock notCode code={`
3
2
1
0
Bye
`} />
        This page just showed basic functionality. More detail about printing to the
        screen will be shown in the Input/Output lesson. Before moving on,
        why not try building a program to print your own name, today's date, and
        your favorite color to the screen on different lines.

    </>
  );
}

export default Comments;
