import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Pointers(){
  return (
    <>
      <TopHeader title="Pointers" />
      <p>
      Pointers can be confusing at first to many programmers. In this section, I will attempt 
      to make it as clear as possible.
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default Pointers;
