/*!

=========================================================
* Paper Kit PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/mycustom.css";
//NavBar
import ShoNavbar from "components/Navbars/ShoNavbar.js";
// pages
import Home from "views/home/index.js";
import BeginnerIndex from "views/beginners/Index.js";
import DevToolsIndex from "views/devtools/Index.js";
//starter languages pages
import CIndex from "views/languages/c/Index";
import PythonIndex from "views/languages/python/Index";
import CppIndex from "views/languages/cpp/Index";
import JavaIndex from "views/languages/java/Index";
import JSIndex from "views/languages/javascript/Index";

//class pages
import Python100 from "views/classes/python100/Index.js";


//other languages pages
import CSharpIndex from "views/languages2/csharp/Index";
import SwiftIndex from "views/languages2/swift/Index";
import RIndex from "views/languages2/r/Index";
import MatlabIndex from "views/languages2/matlab/Index";
import BashIndex from "views/languages2/bash/Index";
import HTMLIndex from "views/languages2/html/Index";
import CSSIndex from "views/languages2/css/Index";

//Project Pages
import BankAcc from "views/projects/BankAcc/Index";
import GPACalc from "views/projects/GPACalc/Index";
import Passwd from "views/projects/Passwd/Index";

//More Info Pages
import ContactUs from "views/MoreInfo/ContactUs";
import Tutor from "views/MoreInfo/Tutor";
import SupportUs from "views/MoreInfo/SupportUs";

import LoginPage from "views/login/LoginPage";
import RegisterPage from "views/login/RegisterPage";
import Error404 from "views/errorpages/Error404.js";
import Unavailable from "views/errorpages/Unavailable.js";
//import Error422 from "views/errorpages/Error422.js";
//import Error500 from "views/errorpages/Error500.js";

import Shop from "views/shop/Shop.js";


import Firebase, { FirebaseContext } from "./components/Firebase";
import * as serviceWorker from "./serviceWorker";

import ScrollToTop from "./components/MyCustom/ScrollToTop.js";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <div className="app">
  <BrowserRouter>
    <ShoNavbar/>
    <ScrollToTop>
    <div className="section">
    <Switch>
    <Route path="/home" render={props => <Home {...props} />} />
    <Route path="/start" render={props => <BeginnerIndex {...props} />} />
    <Route path="/devtools" render={props => <DevToolsIndex{...props}/>}/>
    
    <Route path="/c" render={props => <CIndex {...props} />} />
    <Route path="/python" render={props => <PythonIndex {...props} />} />
    <Route path="/c++" render={props => <CppIndex {...props} />} />
    <Route path="/java" render={props => <JavaIndex {...props} />} />
    <Route path="/js" render={props => <JSIndex {...props} />} />

    <Route path="/python100" render={props => <Python100 {...props} />} />

    <Route path="/csharp" render={props => <CSharpIndex {...props} />} />
    <Route path="/swift" render={props => <SwiftIndex {...props} />} />
    <Route path="/r" render={props => <RIndex {...props} />} />
    <Route path="/matlab" render={props => <MatlabIndex {...props} />} />
    <Route path="/bash" render={props => <BashIndex {...props} />} />
    <Route path="/html" render={props => <HTMLIndex {...props} />} />
    <Route path="/css" render={props => <CSSIndex {...props} />} />

    <Route path="/bank-account" render={props => <BankAcc {...props} />} />
    <Route path="/gpa-calculator" render={props => <GPACalc {...props} />} />
    <Route path="/strong-password" render={props => <Passwd {...props} />} />

    <Route path="/contact-us" render={props => <ContactUs {...props} />} />
    <Route path="/tutoring" render={props => <Tutor {...props} />} />
    <Route path="/support-us" render={props => <Home {...props} />} />

    
    <Route path="/signin" render={props => <LoginPage{...props}/>}/>
    <Route path="/signup" render={props => <RegisterPage{...props}/>}/>

    <Route path="/shop" render={props => <Shop {...props} />} />
    <Route path="/unavailable" render={props => <Unavailable {...props} />} />


    <Redirect exact from="/" to="/home"/>
    <Route path="*" render={props => <Error404{...props}/>} />
    
    </Switch>
    
    </div>
    </ScrollToTop>
    <footer className="footer-gray">
      <div className="pull-right">
      © {new Date().getFullYear()},
          {" "}

          <a href="https://www.htechstudio.com" target="_blank" style={{color:"blue", fontWeight:"400"}}>H Tech Studio</a>
      </div>
    </footer>
  </BrowserRouter>
  </div>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();