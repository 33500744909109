import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG1(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Basic Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program to calculate the circumference of a circle. The program should allow the user 
        to enter a radius in meters then the program will display the circumfrence given the users radius. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
"use strict"
const prompt = require('prompt-sync')();
let radius; 
let circumference; 
radius = prompt('Please enter a radius as a Float'); 

circumference = 2*3.14*radius; 

console.log("A circle with radius", radius ," has circumference ", circumference); 
 
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program to calculate the average of 3 integers. The program should allow the user 
        to enter 3 integers then the program will display the average to the user. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
"use strict"
const prompt = require('prompt-sync')();

//ask user to enter 3 numbers
console.log("Please enter 3 integers:");
//scan in numbers to average
let num1 = prompt("Enter a number: ");
let num2 = prompt("Enter a number: ");
let num3 = prompt("Enter a number: ");

let avg = (num1 + num2 + num3)/3;
//print the average
console.log("The average of these 3 numbers is ", avg);
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build a program to print 0 to the screen if the user enters an even number. If 
        the user enters an odd number print 1. Use only integers.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
"use strict"
const prompt = require('prompt-sync')();

//declare varaibles
let x;

//scan in an integer
x = prompt("Please enter an integer:");

//print the ramainder of x when divided by two
console.log(x%2);
`}/>
    </Collapse>
    </>
  );
}

export default EG1;
