import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Math(){
  return (
    <>
      <TopHeader title="Basic Arithmetic" />
      <p>
      There are many arithmetic operations in all programming languages. We will focus 
      on the 4 operations shown below.
      <br/>
      <br/><strong>Addition</strong>is perfomed using the <strong>+</strong> operator
      <br/><strong>Subtraction</strong>is perfomed using the <strong>-</strong> operator
      <br/><strong>Multiplication</strong>is perfomed using the <strong>*</strong> operator
      <br/><strong>Division</strong>is perfomed using the <strong>/</strong> operator
      <br/><br/>
      Below is a program with basic math. Print statements are included so we can be sure, the operations 
      are doing what we expect. If you want to challenge yourserlf. Try tracing the program to predict the 
      output before you look at the output also given below.
      </p>
      <CodeBlock code={`
x = 5
y = 10
w = 2
z = 4
sum = diff = mult = 0

print("1: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
sum = x + z
print("2: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
sum = sum + 1
print("3: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
sum = sum + 5
print("4: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
x = x - 3
print("5: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
diff = sum - y
print("6: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
diff = diff-3
print("7: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
x = x + w + z
print("8: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
mult = x * diff
print("9: sum:", sum, " diff:", diff, " mult:", mult, " x:", x)
`}/>
 <p>
   Output:
 </p>
 <CodeBlock code={`
1: sum: 0, diff: 0, mult: 0, x: 5
2: sum: 9, diff: 0, mult: 0, x: 5
3: sum: 10, diff: 0, mult: 0, x: 5
4: sum: 15, diff: 0, mult: 0, x: 5
5: sum: 15, diff: 0, mult: 0, x: 2
6: sum: 15, diff: 5, mult: 0, x: 2
7: sum: 15, diff: 2, mult: 0, x: 2
8: sum: 15, diff: 2, mult: 0, x: 8
9: sum: 15, diff: 2, mult: 16, x: 8
`}/>
<p>
  The print statements tell us the variables current value right before we executed some 
  math. The print statement after the math shows what changed so we can easily see what'see
  going on at each line. We are taking advantage of the fact that the computer only executes 
  one instruction at a time. Let's go through some of the lines in the input by line number. 
  <br/>
  <br/>
  <strong>1: </strong>This line just printed the variables after they were initialized. It shows all the 
  original values of sum, diff, mult and x. I don't really print out
  <br/><strong>2: </strong>The line before this print instructed the computer to perform x+z. Variable 
  x and z hold 5 and 4 respectively so their sum comes out to 9. Then 9 is assigned
  to the sum variable and the print statement shows this.
  <br/><strong>3: </strong>The intstruction before this line was sum = sum + 1. The sum value before the 
  instruction was 9 so 9+1=10. Then 10 is stored in sum. Remember sum can only hold 1 number 
  so it holds 10.
  <br/><strong>4: </strong>A similar instruction to 3, but this time we add 5. The sum variable now holds 15
  <br/><strong>5: </strong>Another similar instruction to 3 but with x and subtraction. The x variable now 
    holds 2 since the value before the subtraction instruction was 5.
  <br/><strong>6: Another example of subtraction. The line before executed diff = sum - y, which 
    comes out to 5.
  </strong>
  <br/><strong>7: </strong> This one is similar 4 and 5 and prints the new diff value.
  <br/><strong>8: </strong>We can do more than one mathematical operation on a single line.
  <br/><strong>9: </strong>An example of multiplication.
</p>
<br/><br/>
  <p>
    In the print for 8. Above, we see that we can implement more than one mathematical 
    instruction at a time. The order of operations from algebra is a good rule to remember here.
    The execution priority looks like this:
    <br/> <br/>
    1. Parentheses
    <br/>2. Multiplication and Division
    <br/>3. Addition and Subtraction
    <br/><br/>
    In addition, you can execute many other commands in one line. We haven't learned them yet 
    but you should know that you can always use parentheses to make sure some things execute first.
    <br/><br/>

    <br/><br/>
    Let's Use Math to build our very first useful program.
    <br/>
    A common conversion many people have to do when working with lengths is a meters to feet
    conversion. Let's build a program that takes a user's input length in meters and displays the 
    equivalent length in feet. The code and sample output is shown below.
  </p>
  <CodeBlock code={`
# Get user input in feet
print("Please Enter a Length in Meters:")
meters = input()

# calculate using 1 meter = 3.28084 feet
feet = float(meters)*3.28084    
# meters was cast to a float to avoid multiplying a string with a float

# Print the output to the user
print(meters, "meters =", feet, "feet")

`}/>

    <p>
    Output:
    </p>
    <CodeBlock code={`
6.2   <-User Input    
6.2 meters = 20.3412 feet
`}/>
  <p>
    There are more basic examples to take a look at before you move on.
    In the next section of lessons you will learn how to control the flow of your programs.
    For instance, what if we wanted to let the user try again without the program exiting. 
    Or what if we wanted to build a conversion for both meters to feet and feet to meters and
    let the user choose which one they wanted. 
  </p>

    </>
  );
}

export default Math;
