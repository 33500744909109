import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function CondComp(){
  return (
    <>
      <TopHeader title="Conditional Compilation" />
      <p>
      Conditional compilation, like a macro, is also a preprocessor, which means this happens before compilation.
      Basically, you can control which parts of the program are going to be used in the final executable or which 
      parts should be removed.
      It's actually really simple and the commands behave like an if statement. Conditional compilation 
      is performed with a #define like in macros and #ifdef/#endif. The #ifdef marks the beginning 
      of the portion of code to be considered in compilation and the #endif marks the end. Two examples is shown below with different 
      outputs. 
      </p>
<CodeBlock code={`
#include<stdio.h>

//Defines a symbol named debug
#define Debug 

int main(){

  printf("A normal Hello\\n");
  printf("A normal Goodbye\\n");

  /* check if the symbol Debug is defined */
  #ifdef Debug 
  printf("Hello from inside #ifdef\\n");
  printf("Goodbye from inside #ifdef\\n");
  #endif
  //marks the end of #ifdef

  printf("Ending Program\\n");
  return 0;
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
A normal Hello
A normal Goodbye
Hello from inside #ifdef
Goodbye from inside #ifdef
Ending Program
`}/>
<br/>
<p> Now try removing the symbol named Debug that was defined.</p>
<CodeBlock code={`
#include<stdio.h>

//Defines a symbol named debug
//#define Debug 
/* The above line is now commented out! */

int main(){

  printf("A normal Hello\\n");
  printf("A normal Goodbye\\n");

  /* check if the symbol Debug is defined */
  #ifdef Debug
  printf("Hello from inside #ifdef\\n");
  printf("Goodbye from inside #ifdef\\n");
  #endif
  //marks the end of #ifdef

  printf("Ending Program\\n");
  return 0;
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
A normal Hello
A normal Goodbye
Ending Program
`}/>
<br/>
<p>
  You can see that without the symbol being defined, the #fdef/#endif code doesn't make it into the final
  executable. The symbol used can take on any name and is defined by the developer. Using conditional 
  compilation, you can one program and compile different versions of that program by just defining the right 
  symbols.
</p>
    </>
  );
}

export default CondComp;
