import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
// reactstrap components
import { Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// core components
import ReactPaypal from "../../components/Paypal/ReactPayPal.js";

function SupportUs() {

  return (
    <>
      <div>

        <Container>
          <h2><strong>Support us</strong></h2>
          <br/>
          <h5> 
            Your support will help the shocode developers create better tools for learning and will also help us 
            complete all the other languages for the website much quicker. Giving is very simple and 100% of your gift
            will instatnly be used towards the learning tools and website. We are loyal to our contributors which means 
            we will never share your private information with any Third parties and we will not harass you with emails or calls.
            </h5>
            <br/>
            <h5>To support us, you can click on the give button below to or you can <strong>Venmo</strong> us driectly <strong>@HTechStudio</strong></h5>
            <div>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="NKG6NSANNNDLJ" />
              <input type="image" 
              style={{display:"block", marginLeft:"auto", marginRight:"auto", width:"50%"}} 
              src="https://dl.dropbox.com/s/ppv25plqcbz2r71/whitegivebutton.png?dl=0" 
              border="0" 
              name="submit" 
              title="PayPal - The safer, easier way to pay online!" 
              alt="Donate with PayPal button" 
              />
              <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
            
            </div>

        </Container>
      </div>
    </>
  );
}

export default SupportUs;
