import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionComponents() {
  return (
    <>
      <div className="section section-components section-dark">
        <Row>
          <Col lg="6" md="12">
            <div className="image-container">
              <img
                alt="..."
                className="components-macbook"
                src={require("assets/mimg/sections/laptop-basic.png")}
              />
              <img
                alt="..."
                className="table-img"
                src={require("assets/mimg/sections/table.jpg")}
              />
              <img
                alt="..."
                className="share-btn-img"
                src={require("assets/mimg/sections/share-btn.png")}
              />
              <img
                alt="..."
                className="coloured-card-btn-img"
                src={require("assets/mimg/sections/coloured-card-with-btn.png")}
              />
              <img
                alt="..."
                className="coloured-card-img"
                src={require("assets/mimg/sections/coloured-card.png")}
              />
              <img
                alt="..."
                className="social-img"
                src={require("assets/mimg/sections/social-row.png")}
              />
              <img
                alt="..."
                className="pin-btn-img"
                src={require("assets/mimg/sections/pin-btn.png")}
              />
            </div>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container">
              <h3 className="title">shoCODE</h3>
              <h6 className="category">Learn The Basics Of Programming</h6>
              <h5 className="description">
                This site is designed with a beginner mindset but we think advance programmers 
                can also use this site to begin learning other languages as well. There are so 
                many development environments and so many different programming languages to choose 
                from. This can be very intimidating for begginers and we hope this content will make 
                it a bit easier. When learning from the beginning, we recommend getting a short introduction 
                that will help you pick where to start. Absolute beginners should start by clicking 
                <Link to="/start"> here</Link>. 
                If you're still not conviced that we can help, keep exploring below. There's a lot 
                that shoCODE can offer you.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;
