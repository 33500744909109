import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG6(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Structs Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program to track students using structs. Create a struct named Student. It's members should include 
        the student's first as a string, last name as a string, student id as an integer, and current GPA as a float. 
        The main program should allow the user to enter information for 3 students. The student variables created should 
        be in an array. After all the students information  has been entered, the program should the information back to the user.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>
#include <string>
using namespace std;

struct Student{
  string fname[100];
  string lname[100];
  int id;
  float GPA;
};

int main(){
  //Declare Variables
  struct Student s[3];
  
  for(int i=0; i<3; i++){
    cout << "Enter the first name of student: " << i << endl;
    cin >> s[i].fname;
    cout << "Enter the last name of student: " << i << endl;
    cin >> s[i].lname);
    cout << "Enter the id of student: " << i << endl;
    cin >> s[i].id);
    cout << "Enter the GPA of student: " << i << endl;
    cin >> s[i].GPA);
  }

  for(int i = 0; i<3; i++){
    cout << "Student: " << i << endl;
    cout << "Name: " << s[i].fname << " " << s[i].lname << endl;
    cout << "ID: " << s[i].id << endl << "GPA: " << s[i].GPA) << endl;
  }
  
  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program using structs to store the location of a person in an xy coordinate system. Create 
        a struct called Person. It's members should include, the person's first name as a string, the x position as 
        an integer and the y position as an integer. The main program should ask the user to input the information 
        for just 2 people. After all the information has been entered, the program should calculate and display the 
        differences between the x and y coordinates of both people.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>
#include <string>
using namespace std;

struct Person{
  string fname[100];
  int x, y;
};

int main(){
  //Declare Variables
  struct Person p1, p2;
  int diffx, diffy;

  cout << "Enter The first name of person 1:" << endl;
  cin >> p1.fname;
  cout << "Enter the x and y position as an integer:" << endl;
  cin >> p1.x >> p1.y;

  cout << "Enter The first name of person 2:" << endl;
  cin >> p2.fname;
  cout << "Enter the x and y position as an integer:"<< endl;
  cin >> p2.x >> p2.y;

  diffx = p2.x - p1.x;
  diffy = p2.y - p1.y;

  cout << "deltax = << diffx << "deltay = " << diffy << endl;
  return 0;
}
`}/>
    </Collapse>
    </>
  );
}

export default EG6;
