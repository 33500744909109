import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Input() {
  return (
    <>
      <TopHeader title="Input" />
      <br />
      <p>
        Now that we can print a variable to the screen, let's see how we can
           allow a user to store information into a variable in the program. Here
           we introduce a new function. The first function we learned was called print().
           This new function is called input(). The input() function will scan in user input
           and store it in a variable. The code below, shows how we can use the 
           input() function. 
  </p>
      <CodeBlock code={`
"""
Using the input() function. 
NOTE: Python automatically converts all input into a 
string.
"""

x = input()
print("The input you entered, ", x, ", is of type: ", type(x), sep = "")

"""
NOTE: Avoid using "input" as the variable name for an input
because this can be confusing.
"""
`} />
      <p>
      Python automatically converts all input into a string. This just means everything is 
      treated as a character. The number 123 and the string 123 are different. We will show 
      you how to convert a string back to a number in a moment.
      Avoid using "input" as the variable name for an input.
      Running the above code will show nothing at first. The input() statement is waiting
      for a user to input an integer. If you enter the number 35 and press enter, the output
      will be
  </p>
      <CodeBlock notCode code={`
35    <- User Input
You entered the number 35
`} />
      <p>
        It's better to put a string in the input() functio when asking for user input.
        It helps the user of the program understand what they are supposed to do. Consider the following
        program.
</p>
      <CodeBlock code={`
# notice the difference on the first line.
num = input("Please enter a decimal number: ")
# using input() like this helps the user understand what you are asking for 
print("You entered the number", num)
#this line checks what data type num is.
print("num is the following data type", type(num))
`} />
      <p>The output of the program with user input of 512 is shown below.</p>
      <CodeBlock notCode code={`
Please enter a decimal number:
5.12  <- User Input
You entered the number 5.12
num is the following data type: <class 'str'>
`} />
      <p>
      You can see from the above program that even though the number 5.12 was entered, the output showed that it was a string (str). The user
      entered 5.12 and the program received '5.12'. The number or float 5.12 is different from the string '5.12'. We can change strings that are numbers 
      into their int or float form by using a cast or casting.
      <br/>
      <br/>  
      To cast, you can use the int(), float(), or str() functions to turn whatever is inside the parenthesis into an int, float, or string.
      Since the input function always gives a string, we can use int(input()) and float(input()) to ask the user for ints or floats. The example
      below shows the same program as above, but now the type of the variable is changed to int by using the int() function.
</p>
<CodeBlock code={`
#notice we use float(input()) here.
num = float(input("Please enter a decimal number: "))
print("You entered the number", num)
#this line checks what data type num is.
print("num is the following data type", type(num))
`} />
      <p>The output of the program with user input of 512 is shown below.</p>
      <CodeBlock notCode code={`
Please enter a decimal number:
5.12  <- User Input
You entered the number 5.12
num is the following data type <class 'float'>
`} />
<p>
      THe output above confirms that the number the user inputted was casted as a float. In the orginal program the data type was a string but,
      in this program the data type is a float. This will be important later as we cannot really apply the same mathematical functions on
      strings like we can on ints or floats. For example the string '2' is different from the number 2. Let's test it using simple addition in 
      a short program.
</p>
<CodeBlock code={`
#Add the string '2' and '15' and show the result
print('2'+'15')
#Add int 2 and int 15 and show the result
print(2+10)
`} />
      <p>The output of the program with user input of 512 is shown below.</p>
      <CodeBlock notCode code={`
215
17
`} />
      <p>
            You can see from the output above that addition works with integers but with strings it adds the 2 strings 
            together by just putting the second string after the first string. So '2' + '15' became '215' because '2' and '15'
            are strings. When 2 and 15 are ints the 2+15 becomes 17 which is what we usually want with addition.
            If you are having trouble understanding this, please ask the instructor.
      </p>
      {/*
      <p>
        Now let's deal with controlling the decimal on those float values. By default, printf() will
        show 6 decimal places for float variables. We can control the width of the output and the decimal
        places you want to print. This is accomplished by changing your %f specifiers in printf().
      <br /><br />
        <strong>%w.df</strong> - w and d are ints. Print a decimal number with total width w and d decimal places.
      <br /><strong>%5.2f</strong> - print a float with total width 5 and 2 decimal places.
      <br /><strong>10.1f</strong> - print a float with total width 10 and 1 decimal place.
      <br /><br />
        The width is the total count of characters being printed to the screen. The decimal places is how many
        characters after the decimal you want to print. There's some small nuances you should be aware of.
      <br />1. The decimal or period counts towards the width. It counts as 1 character.
      <br /> 2. If the decimal place causes the width of the output to be greater than the width specified,
        the decimal places win and override the width. The width is ignored in this case.
      <br /> 3. If the number has the correct decimal places specified but is still shorter than the width
        specified, then the number is padded on the left side by spaces.
      <br />
        That's all so wordy and confusing. So check out the example below and then come back and read
        this to hopefully gain a better understanding.
    </p>
      <CodeBlock code={`
pi = 3.141593

# width 2 and 4 decimal places
print("%4.4f" % pi) # This is case 2 above

# width 6 and 2 decimal places
print("%6.2f" % pi) # This is case 3 above

# width 5 and 3 decimal places
print("%5.3f" % pi)

# width 6 and 2 decimal places and leading 0
print("%06.2f" % pi)
`} />
      <p>
        Output:
</p>
      <CodeBlock notCode code={`
3.1416
3.14
3.142
003.14
`} />
<p>

</p>
      */}
    </>
  );
}
export default Input;
