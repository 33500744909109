import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Pointers(){
  return (
    <>
      <TopHeader title="Pointers" />
      <p>
      Pointers are a way to indirectly access variables. Up until now, you have been accessing variables directly 
      by using the assingment (=) operator. Pointers give us a way to access the physical memory address where the data 
      is stored. 
      </p>
     <br/>
      <p> 
       A pointer is an address and you should have actually seen an address before. When we used scanf you use the the & 
       symbol with a variable. If your program had a varaibale declared as int x; then &x gives you the address of the 
       variable x in memory. We can store pointers to variables just like we can store numbers and characters but it does 
       look a little different. First let's try looking at what an address looks like. We can print addresses like we print 
       normal variables.
      </p>
      <CodeBlock code={`
#include<iostream>
using namespace std;
int main(){
  int x;
  x = 5;

  cout<<"The address of x is<< &x << endl;
  return 0;
}
`}/>
<br/>
<p>
Output: 
</p>
<CodeBlock notCode code={`
The address of x is 0x7ffe052a967c     <-Note: This will be different for everyone.
`}/>
<p>
  The address looks weird but it's just a number in a different format. Now why is the address different 
  for everyone. The variable information is stored in physical memory and the address is showing where x 
  is being stored on your specific computer.
</p>
<br/>
<p>
  Pointers have are their own data type. Just link int, float, and char, they need to be declared so we can 
  store them. It's possible to have pointers to any variable type. Below is some sample code showing how we 
  can declare an integer pointer, store the address of a variable into that pointer, and print that pointer 
  to the screen.
</p>
<CodeBlock code={`
#include<iostream>
using namespace std;
int main(){
  
  int *px;  // declares an integer pointer named px
  
  int x;    // declares an interger named x.
  x = 5;    // sets x to 5

  px = &x;  //sets px to the address of x
  
  /* printing px to the screen */
  cout<<"The address of x "<< px << endl;
 
  return 0;
}
`}/>
<p>
  Output: 
</p>
<CodeBlock notCode code={`
The address of x is 0x7ffd5af07ea4     <-Note: This will be different for everyone.
`}/>
<br/>
<p>
  Notice some important things in the above program. Putting the * symbol infront of the variable while declaring makes it 
  a variable. So to declare a pointer to a float or a pointer to a char it would look like this:
</p>
<CodeBlock code={`
  float *x;   // declares an integer pointer named x
  char *y;    // declares a character pointer named y
`}/>
<br/>
<p>
  If you wanted to store a float address you need to make sure you use a float pointer. To actually assigne the address 
  of a variable in memory to the pointer, we can use the & symbol like we did in the first program of this page. 
</p>
<br/>
<p>
  You can also access the the contents of the pointer (or contents of the address) using the derefence operator. The 
  tricky thing here is it's actually the * symbol again. Just remember that when you are declaring pointers (using kewords 
  like int, float, char,etc ...), then the * symbol creates a pointer. If a pointer already exists in your program, you can 
  access it with the * symbol. Below is another example.
</p>
<CodeBlock code={`
#include<iostream>
using namespace std;
int main(){
  
  int *px;  // declares an integer pointer named px
  
  int x;    // declares an interger named x.
  x = 5;    // sets x to 5
  
  /* print x to the screen */
  cout<<"x =  "<< x << endl;

  px = &x;  //sets px to the address of x
  *px =10;  //This sets the address contents to 10
  /* since px points to x, x now stores 10 instead of 5 */

  /* print x to the screen */
  cout"x =  " << x << endl;

  return 0;
}
`}/>
<p>
  Output: 
</p>
<CodeBlock notCode code={`
x = 5
x = 10
`}/>
<br/>
  <p>
    The above program shows that we can access the information stored at the pointers address. It may seem confusing 
    but just remember 3 things.
    <br/>
    <br/>
    <strong>1. </strong> How to declare a pointer. (eg. int * x;)
    <br/><strong>2. </strong> How to assign a pointer (eg. x = {`&y`};)
    <br/><strong>3. </strong> How to dereference a pointer (eg. *x = 5;)
  </p>
  <p>
    It seems like a weird way to access variable but it can be very useful. When we pass variables to functions, we 
    are actually taking a copy of whatever is stored in the variable. If we try to manipulate the value in the function 
    it wont work. Look at the next two examples. Let's say we want to change a variable in the main function from 
    another function. This isn't possible in the first example. The second example uses pointers and it is possible because 
    the pointer stores the address of where the variable is located in physical mememory.
  </p>
  <CodeBlock code={`
#include<iostream>
using namespace std;

void ChangeNumber(int x);

int main(){
  int x;
  x = 5;    // sets x to 5
  
  /* Call the function */
  ChangeNumber(x);

  /* print x to the screen */
  cout << "x = " << x <<endl;
 
  return 0;
}

void ChangeNumber(int x){
  x = 10;
  return;
}
`}/>
<p>
  Output: 
</p>
<CodeBlock notCode code={`
x = 5;      <- Tried to change x to 10 from the function but failed.
`}/>
<br/>
<p> It failed so now let's try passing a pointer to the function.</p>
<CodeBlock code={`
#include<iostream>
using namespace std;

void ChangeNumber(int *x);

int main(){
  int x;
  x = 5;    // sets x to 5
  
  /* Call the function */
  ChangeNumber(&x);

  /* print x to the screen */
  cout << "x = " << x << endl;
 
  return 0;
}

void ChangeNumber(int *x){
  *x = 10;
  return;
}
`}/>
<p>
  Output: 
</p>
<CodeBlock notCode code={`
x = 10;      <- it worked!
`}/>
<br/>
<p>
  This is why scanf() needs the & symbol. If we pass it a normal variable, we cannot edit the memory address the user wants 
  to store information in. But by give scanf() the address, we can access it's location and store the information in the right 
  place.
</p>

    </>
  );
}

export default Pointers;
