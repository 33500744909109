import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function PickLang() {
  return (
    <>
           <TopHeader title="Which Language Should I learn First" />
      <br />
      <p>There are a handful of ways and reasons to decide a language. In fact,
        I bet a bunch of different professors around the world are arguing about
        it at this very momemnt. Everyone language has it's purpose and every
        developer has their reasons. But as a new student, you really wont be able
        to understand them all until, you've built some programs yourself. At shoCODE,
        we identify four possible starting points depending on what you want to get out
        of it. The starting options are all equal and not listed in any particular order.
        Keep in mind that even if the recommendation below matches your goals, you really
        don't really have to start there. The most important thing to learn in the
        beginning is programming concepts. These concepts are similar in all languages and
        you will be able to pick up other languages quickly if you learn the basic concepts
        well. Any of the following choices will help you get to that level.
      </p>
      <br /> <h3>1. C/C++ (The Bread & Butter of Programming) </h3>
      <p>
        Programs written in C/C++ tend to be much faster and work closer to the hardware than other languages.
        C and C++ are both very powerful languages. These are two different languages
        but C code can exist without any changes in C++. Learning C then Learning C++
        is a good solid approach to the basics. At shoCODE, you'll see C and C++ share 
        the same code most of the time. This is because things like variables, and program 
        flow  control, etc., have the same syntax in C. So learning C means you are already 
        learning some C++.  The downside is
        that C/C++ is more abstract. It can be more difficult for beginners to pick up.
      </p>
      <p>
        Beginners who want to move into game programming, build Operating Systems, 
        dive deeper into machine language, work on firmware, work on embedded systems, 
        or work on cybersecurity could start here. Game Engines like Unreal and Unity 
        use C++ and C# respectively. Beginners should understand, that this route can be 
        challenging and may seem a little longer. However, if you're up for the challenge, 
        this learning C then C++ will give you a solid foundation and will be well worth it.
                      </p>
      <br /> <h3>2. Python (A Powerful Fast Track) </h3>
      <p>
        Python is a really powerful language and has very simple syntax similar to the 
        English language. This allows developers to write programs with fewer lines than 
        some other programming languages. Beginners usually become more motivated to learn 
        since it will only take a couple of weeks or maybe even days for some to start building 
        some very useful scripts. There are Many Open Source Frameworks and Tools available 
        to python developers, which makes developing cheaper and faster. However, Python does 
        have a complex side as well, which will still take as much time to learn as C/C++. 
        Python can sometimesjust not be a good fit for an application. Python adopts three different 
        programming paradims and can be treated in a procedural, an objecte-oriented, or functional 
        way. While this might sound like a good thing, it might confuse beginners when they see 
        examples on other sites. 
      </p>
      <p>
        Python is referred to as a general-purpose programming language and makes quick work when 
        prototyping projects.Beginners who want to learn about a diverse set of applications 
        could start here. Python is used in a variety of different projects from games, robotics,
        web services, CAD programs, etc. 
      </p>
      <br /> <h3>3. JavaScript (The Web Developer) </h3>
      <p>
        JavaScript has become ingrained with web technology. One of the reasons to
        start with JavaScript is that it probably already exists on your system.
        JavaScript is already installed on every modern web browser. The downside is
        you should probably know some HTML and CSS before you start your JavaScript
        learning. Don't shy away here though, as the bare basics of HTML and CSS are
        very easy to learn. In fact, most people are mistaken when they say they 
        program in HTML and CSS. HTML and CSS are a markup and style sheet 
        language. That's just a fancy way of saying these language can't give the 
        computer any instructions, they are mainly used for layout and styling of webpages.
        </p>
      <p>
        JavaScript is alos found on many desktop/serverside applications and in some 
        databases like MongoDB. These severside apps and databases are usually the 
        backend to webpages though. Beginners who want to build websites could start here. 
        JavaScript can be useful in making a site more visually appealing but can also be 
        used to write server side code. It's applications have been expanding from web to 
        mobile apps, desktop apps, and game development.
      </p>
      <br /> <h3>4. Java (The In Between) </h3>
      <p>
        Java is a fast and secure language. Some people say Java has easier syntax than C++, but we
        think it's just because a few minor differences between the two. Java is still used a lot in today's
        modern applications and has a huge support community. It is similarly as strict as C and 
        is an object orient programming language like C++. It will be faster than many other 
        languages but C/C++ will always win out in speed. Still, it will be very easy to switch 
        from Java to C++ if a specifica application is needed. 
      </p>
      <p>
        Java's applications are just diverse as all the other options we listed. It ranges from 
        Mobile/Desktop/Web/Server Applications to Games and Database connections. If you are debating 
        a lot Between learning C++ and Python and can't make up your mind. Java may be a good 
        in between place for you to start. 
      </p>
      <br />
      <br />
      <h3>How much does it really matter?</h3>
      <p>
        In our opinion, it doesn't. This is not the place for experts to argue about different
        languages. This is a place to start learning. There are poeple who might argue what the
        current job demand is  or even say most modern applications are built using blah
        language. The truth is as a beginner, you have no idea what any of that means. If you
        focus on learning the concepts of how to build a program, you can definitely translate
        that knowledge over to other languages. If you want to become a developer, there is a
        very strong chance that you will have to learn new languages anyways. Let's take a look
        at some of the code snippets below to print something to the screen in different
        languages.
      </p>
      <p>
        C - printf("Hello, World");
      </p>
      <p>
        C++ - cout::"Hello, World";
      </p>
      <p>
        Python - print("Hello, World")
      </p>
      <p>
        JavaScript - alert("Hello, World");
      </p>
      <p>
        JavaScript - console.log("Hello, World");
      </p>
      <p>
        Java - System.out.println("Hello, World");
      </p>
      <p>
        You can see above that all the languages are similar when it comes to
        printing something to the screen. Advanced programmers may not agree with
        the above as an example but we are certain that they would agree that
        concepts like variables, IF/Else, While Loops, For Loops, Functions,
        Arrays, and many more are all also very similar in each of those respective
        languages. If you want, go look at the If/Else section of a few of the starter 
        languages. You'll definitely see similarites mentioned here. You will get a 
        chance to learn all of the concepts no matter which language you choose.
      </p>
      <p>We hope you have a better idea of which language you want to start with.
        Feel free to do other research as well. Everyone at shoCODE, has started with a
        different language. Becoming a developer means, you'll be constantly learning new
        thing anyways. If you still don't know and you want us to pick for you, then start
        with python.
      </p>
      <p>
        After picking a language make sure you decide on the environment you want to learn
        that language in.
      </p>

    </>
  );
}

export default PickLang;
