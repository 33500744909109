import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Strings(){
  return (
    <>
      <TopHeader title="Strings (Charactar Arrays)" />
      <p>
      A String is actually just a character array. Each character is stored in an element in the array. 
      Strings will normally not fill up the entire array. For example, if you had an array of size 100
      and you wanted to store the string "hello". The string will only take up the first 5 elements. The 
      6th element in a string will be the null character. This helps the computer understand that the string 
      is over and we don't have to continue on to the other elements in the array.
      The null character is represented with '\0' similar to how newline is represented with '\n'; 
      </p>
      <p>
        Below shows a sample program that initializes the same string in different ways.
      </p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  char x1[] = "shoCODE";
  char x2[] = {'s','h','o','C','O','D','E',' '};
  char x3[8] = {'s','h','o','C','O','D','E',' '};
  char x4[100] = "shoCODE";
  return 0;
}
`}/>
<br/>
<p> All of the declarations above accomplish pretty much the same thing, but x4 is probably 
  the most flexible. The illustration below show's how the characters are stored in the array.
  </p>
  <br />
      <img
        alt="..."
        style={{ width: "30%" }}
        className="code-image"
        src={require("assets/mimg/code/c/string.png")}
      />
      <br />
      <p> There is one declaration that all students should avoid. It is possible to do this:
      <br/>
        <br/>
        <strong>char x[7]="shoCODE"</strong>
        <br/>
        <br/>
        This is <strong>VERY BAD</strong>. In the position x[7] the '\0' character was written. But remember if we declare x[7], the 
        variables in the array go from x[0] to x[6]. We cannot access data outside the declration we made. This is 
        also the reason that x4 is most flexible declaration. The variable x4 declares space for 100 characters. That 
        makes it possible to reuse x4 for other strings as long as we don't go over the 100 character limit.
      </p>
      <br/>
      <h3><strong>String Input/Output</strong></h3>
      <p> The scanf() function can be used with specifier %s to scan in strings. In this case scanf() will read a characters 
        into a string until it sees whitespace(newline, tab, space, etc.). This next program shows how you can scan in strings
        as a character array.
      </p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  char firstName[100];
  char lastName[100];
  cout << "Enter Your Full Name: " << endl;
  cin >> firstName >> lastName;
  cout << "First Name: " << firstName << endl;
  cout << "Last Name: " << lastName << endl;
  return 0;
}
`}/>
<p> 
  Output: User enters sho\nCODE
</p>
<CodeBlock notCode code={`
sho     <-User Input
CODE    <-User Input
First Name: sho
Last Name: Code
`}/>
<br/>
<p>
  The null character tells the printf() when it's done printing the string. Both arrays have 100 character spaces but the printf() 
  function knows when to stop printing the string based on the '\0'. Alos, look closely at the scanf() calls above. There is no & 
  symbol. That & symbol used to be in every scan statement you've done so far. You don't need the & symbol here because array 
  varaiables without the subscript brackets are the address to the array. If you're interested in what the & symbol was and why 
  we don't need it here check out the pointers lesson.
</p>
<br/>
<p>
  So you can use scanf() to scan in entire words as strings and process those strings like character arrays. But, what 
  if you wanted to scan an entire line of words or characters including all it's whitespace. 
  This can be accomplished using the fgets() function.  Similar to the putchar() function , the puts() funtion can display 
  entire strings. An Example showing the use of fgets() and puts() is given below. The fgets() function takes 3 parameters.
  The first parameter is the variable to hold the string. The second is the maximum length fgets() is allowed to transfer. 
  The third parameter is where to read the line from. The stdin in the third position below is usually the standar input 
  in a linux system.
</p>
<CodeBlock  code={`
#include <iostream>

using namespace std;

int main()
{
  char s[100];
  cout << "Enter a sentence with less than 100 characters: " << endl;
  fgets(s, 100, stdin);  // reads an entire line
  cout << "Your sentence is below: " << endl;
  puts(s);    // display string
  return 0;
}
`}/>
<br/>
<p>Output:</p>
<CodeBlock notCode code={`
Enter a sentence with less than 100 characters:
shoCODE is AWESOME!         <-User Input
Your Sentence is below:
shoCODE is AWESOME!
`}/>
    </>
  );
}

export default Strings;
