import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Structs(){
  return (
    <>
       <TopHeader title="Structures" />
      <p>
      Structs (short for structures) is creating a data type that is a combination of primitive
      data types. Primitive data types are things like int, float, char, etc. We can use a struct as 
      a user defined data type that groups common variables together.
      </p>
      <p>
        We can often use structs for common objects. One example of this is books. We don't exactly have 
        a data type called books like an int, float, char. But, we can make one. But what should a book variable 
        store. We can store multiple datatypes inside the book. Maybe the book should have price and 
        isbn number. The price could be a float and the isbn number could be an 
        integer. The code below shows how you can create a struct named Book and how to access it's internal members.
        The members are accessed with a period symbol.
      </p>
<CodeBlock code={`
#include<iostream>
using namespace std;

struct Book
{
    int isbn;
    float price;
};

int main()
{
    int i;
    /* b1 and b2 are both Books */
    struct Book b1, b2;

    /* access b1's members */
    b1.isbn = 10023456789;
    b1.price = 1.23;
    /* access b2's members */
    b2.isbn = 1002348978;
    b2.price = 3.21;

    cout << "b1-isbn: " << b1.isbn << endl;
    cout << "b1-price: " << b1.price << endl;
    cout << "b2-isbn: " << b2.isbn << endl;
    cout << "b2-price: " << b2.price << endl;
    endl;

    return 0;
}
`}/>
 <p>Output</p>
 <CodeBlock notCode code={`
b1-isbn: 1002345678 
b1-price: 1.230000 
b2-isbn: 1002348978 
b2-price: 3.210000 
`}/>
<br/>
<p>
  We could even add in character arrays for the title and the author to make it a little more complete.
  You can create collections of variables like this and model the collections on real objects. Here we used books.
  You can also create arrays of structs and even pointers to structs. This is shown in the example below using the 
  same book struct. To access members using pointers, you can dereference with the * symbol and a period or 
  use the arrow (->) operator on the pointer directly.
</p>
<CodeBlock code={`
#include<iostream>
using namespace std;

struct Book
{
    int isbn;
    float price;
};

int main()
{
    int i;
    /* b3 is a Book */
    struct Book b3;

    /* You can even create an array of Books */
    struct Book books[5]; 

    /* You can also create pointers for structs */
    struct Book *ptr_book;

    /* Accessing the books in the array */
    for(i = 0; i < 5; i++){
      books[i].isbn = 0000000000 + i;
      books[i].price = 0.99 * i;
    }

    /* print all data values for books in the array */
    for (i = 0; i < 5; i++){
      cout << "Book %d: " << i << endl;
      cout << "ISBN: %d " << books[i].isbn << endl;
      cout << "Price: %f " << books[i].price << endl;
    }
    endl;

    /* pointer to book example */
    ptr_book = &b3;
    (*ptr_book).isbn = 1472583690;
    ptr_book->price = 29.99;

    cout << "Pointer to b3 Data" << endl; 
    cout << "b3-isb: " << (*ptr_book).isbn << endl;
    cout << "b3-price: " << ptr_book->price << endl;
    return 0;
}
`}/>
 <p>Output</p>
 <CodeBlock notCode code={`
Book 0: 
ISBN: 0 
Price: 0.000000 
Book 1: 
ISBN: 1 
Price: 0.990000 
Book 2: 
ISBN: 2 
Price: 1.980000 
Book 3: 
ISBN: 3 
Price: 2.970000 
Book 4: 
ISBN: 4 
Price: 3.960000 

Pointer to b3 Data
b3-isb: 1472583690 
b3-price: 29.990000 
`}/>
<br/>
<p>
  It is important to mention that there is another way of creating struct variables. Instead of declaring them in your 
  main program it is possible to define them immediately after the struct definition. This method is shown below.
</p>
<CodeBlock code={`
#include<stdio.h>

struct Book
{
    int isbn;
    float price;
} b1, b2, books[5];

int main()
{
  /* use b1, b2, and the books array here */
}
`}/>
    </>
  );
}

export default Structs;
