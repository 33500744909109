import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG3(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Function Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a function to calculate the area of a rectangle. The function should 
        be given the width and height as an integer and return the area as an integer.
        In addition build a driver to test the function with a width and height 
        specified by the user. (Note: The driver is just a main() function to test another 
        function.) 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>

using namespace std;

int Rect_Area(int w, int h);

int main()
{
  //Declare Variables
  int width, height, area;

  //get the width and height from the user
  cout << "Enter a width and height(Integers):" << endl;
  cin >> width >> height;

  //calculate the rectangle area using the function
  area = Rect_Area(width, height);

  //print the result to the screen.
  cout << "The rectangle has area: " << area << endl;

}

int Rect_Area(int w, int h)
{
  return w*h;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a function that when given an integer, returns 0 if the integer 
        is odd and returns 1 if the integer is even. The function should print 
        out if the number is positive or negative. For example, if the function 
        is given the integer 3. The function should pring "3 is a postive integer".
        Then the function should return 0, since 3 is odd.
        <br/>
        In addition, test the function with user input from a driver(main()). The 
        driver should allow the user to test the function over and over with a 
        different number until the user enters 0. When 0 is entered by the user the 
        program should exit.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>

using namespace std;

int Is_Even(int x);

int main()
{
  //Declare Variables
  int num, isEven;

  //get the first value from the user
  cout << "Enter an Integer:" << endl;
  cin >> num;

  while(num != 0)
  {
    //call function
    isEven = Is_Even (num);
    
    //Check what the function returns.
    if(isEven)
      cout << num << " is even" << endl;
    else
      cout << num << " is odd" << endl;
      
      //update condition
      //get new value
      cin >> num;
  }
  return 0;
}

int Is_Even(int x)
{
  if(x>0)
    cout << x << " is positive" << endl;
  else
    cout << x << " is negative" << endl;
  if(x%2==0)
    return 1;
  else
    return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build 2 functions. The first function that is given an integer and returns 0 if the integer is 
        odd. If the integer is even, it should return the square of the number. 2 squared or (2^2) is 4.
        To perform the square, you should write a function that is given an integer and returns the square 
        of that integer. The main program should prompt the user to enter an integer. The program will display 
        0 if the integer is odd and will display the number^2 if it is even.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>

using namespace std;

int Process(int x);
int Square(int x);

int main()
{
  //Declare Variables
  int num, result;

  //Get the value from the user
  cout << "Enter an Integer" << endl;
  cin >> num;

  //get the result to print
  result = Process(num);

  cout << result << endl;

  return 0;
}

int Process(int x)
{
  int r;

  if(x%2==1)
  {
    return 0;
  }
  r = Square(x);
  return r;
}

int Square(int x)
{
  return x*x;
}
`}/>
    </Collapse>
    </>
  );
}

export default EG3;
