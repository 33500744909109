import React from "react";
// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG2(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Control Flow Section Examples" />
      <EGPreface/>
      
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program that takes 5 inputs from the user. The program should display the 
        smallest value entered before exiting. The inputs can be decimal numbers
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
# get 5 inputs and check their values

i = 0

while i<5:
  value = int(input("Enter a number: "))
  if i==0 or value<minimum:
    minimum = value
  i = i+1

print("The smallest number entered was", minimum)
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program that takes user input until the user enters -999. After -999 is entered,
        the program should print how many numbers were entered(not including -999) 
        and the largest value entered and then exit. The inputs should 
        only be integer but postive and negative values are allowed.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
# Get the first integer
value = int(input("Please Enter an Integer (-999 To Quit): "))
count = 0

# while loop until -999 is entered
while value!=-999:
  count = count+1
  # if first value or value > maximum
  if count==1 or value>maximum:
    maximum = value # set maximum

  value = int(input("Please Enter another Integer (-999 To Quit): "))

# Print the result to the screen
print("0 numbers were entered.") if count==0 else print(count, "numbers entered. The largest was", maximum)
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build a program that allows a user to enter 2 integers. The program should subtract 
        the second input from the first input. For example, the user enters 5 2. In this case the 
        program should perform 5-2 and report 3. In addition, the program should ignore the inputs 
        when the first integer is smaller than the second integer, and then ask for a new set of 
        integers. Once a valid input is detected, the program should perform the subtraction 
        and print the result to the screen.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
num1 = int(input("Please enter 2 integers (larger number first): "))
num2 = int(input())
flag = True

if num2>num1:
  flag = False

while flag == False:
  num1 = int(input("Please enter 2 integers (larger number first): "))
  num2 = int(input())
  if num2<num1:
    flag = True

diff = num1-num2

print("The difference of the two inputs is:", diff)
`}/>
    </Collapse>
    </>
  );
}

export default EG2;
