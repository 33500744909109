import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components


function Tutor() {
  return (
    <>
        <div>
        <Container>
        <h3><strong>Tutoring Options</strong></h3>
        </Container>
      </div>
    </>
  );
}

export default Tutor;
