import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "components/MyCustom/EGPreface.js";


function EG7(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Preprocessors Section Examples" />
       <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program to calculate the circumference of a circle using a macro for the number pi. The program should ask 
        the user for the radius of the circle, then calculate and display the corresponding circumference. The radius entered 
        by the user can be a decimal number.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
#define pi 3.14
int main(){
  //Declare Variables
  float r, circ;

  printf("Enter a radius:\\n");
  scanf("%f", r);

  circ =  2 * pi * r;
  printf("The circumference is %f.\\n", circ);

  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program that checks if a number is a multiple of 5. Use a function like macro that expands to 1 if the number given is a multiple 
        of 5. The function like macro should expand to 0 in all other cases. The program should ask the user to enter an integer. 
        The program will tell the user if the number is a multiple of 5 or not. Name the function like macro whatever you want.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
#define MultipleOf5(x) ((x%5)==0)

int main(){
  //Declare Variables
  int input;

  printf("Please enter an integer:\\n");
  scanf("%d", &input);

  if( MultipleOf5(input) ){
    printf("The number you entered IS a multiple of 5.\\n");
  }else{
    printf("The number you entered IS NOT a multiple of 5.\\n");
  }

  return 0;
}
`}/>
    </Collapse>
    </>
  );
}

export default EG7;
