import React from "react";
import { Link } from "react-router-dom";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function WhatNext() {
  return (
    <>
      <TopHeader title="What's Next" />
      <br />
      <p>
        Well, you have two options. 
        <br/><br/>
        1. If you're the type that likes to dive in and do examples quickly, 
        You can learn and use more code by selecting the language you have choosen 
        from the Languages Menu in the Navigation Bar at the top of this webpage.
        <br/>
        2. If you like to learn a little bit more background about programming 
        before moving on, you can go to the background section next by clicking 
       <Link style={{fontWeight:"bold"}}> here</Link>
        </p>

    </>
  );
}

export default WhatNext;
