import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Union(){
  return (
    <>
      <TopHeader title="Unions" />
      <p>
      Structs (short for structures) is creating a data type that is a combination of primitive
      data types. Structs are definitely a good starting point when starting to move into learning C++.
      The Struct in C and the Class in C++ share many similarities.
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default Union;
