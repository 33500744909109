import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function Expect() {
  return (
    <>
      <TopHeader title="What To Expect? Time Commitment? Frustration?" />
      <br />
      <p> 
        Programming can be very rewarding to learn but for many beginners there are times when 
        it can be very frustrating. The time commitment varies between students but in general, you will 
        spend a lot of time debugging programs and figuring out how much the little things actually matter.
      </p>
      <p>
        When you get stuck, you should initially be able to debug your own programs. This means using program 
        outputs to print to the screen to test and see which parts of the code are executing incorrectly. Debugging 
        is a skill that you will learn over time. Essentially, if you trace your program from the beginning, you should 
        be able to find the error since programs only execute instructions one step at a time (for now at least). 
      </p>
      <p>
        If you still can't find the error, it's best to get some help from a friend or search online for help. A fresh 
        set of eyes can often see things you may have missed. If you're waiting for a response online from a question, you 
        should take the time to relax or do a different type of task. Often times, just being away from the computer for a 
        little while could help you clear you mind and give you a fresh perspective.
      </p>
      <p>
        Many of the beginner languages are well documented in books and online sites. For those languages, there really is 
        no shortage of help. We hope to offer tutoring services here at shocode very soon.
      </p>
    </>
  );
}

export default Expect;
