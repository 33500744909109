import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function OtherControl(){
  return (
    <>
      <TopHeader title="Other Types Of Flow Control" />
      <p>
      This section will discuss other control flow statements you can use in Python. The 
      switch and do-while are commonly found in other programming langauges. We show some other 
      alternatives to these statements to achieve the same functionality in python.
      </p>
      
<h3><strong>Dictionary (emulating the Switch Statement)</strong></h3>
<p> The switch statement does not exist in Python.  
However, there are several ways to create sometihng similar.  
Here, we will do so by implementing a dictionary.
</p>
<CodeBlock code={`
"""
A switch statement does not exist in Python.  
However, there are several ways to create sometihng similar.  
Here, we will do so by implementing a dictionary.
"""

x = int(input("Please enter an integer: "))

def message(x):     # crating a funciton called message, which will use to call dictionary
    options = {     # creating a dictionary called options
        1 : "Welcome!",
        3 : "How may I help you?",
        5 : "What is your name?",
        7 : "Please wait a moment."
    }
    return options.get(x,"That option is not available.")   # this is a catch-all statement for any input that isn't defined

print(message(x))
`}/>
<br/>
<p>Output: User enters 3</p>
<CodeBlock notCode code={`
Please enter an Integer
3   <- User Input
How may I help you?
`}/>
<br/>
<p>Output: User enters 4</p>
<CodeBlock notCode code={`
Please enter an Integer
4   <- User Input
That option is not available.
`}/>
<p>Output: User enters 7</p>
<CodeBlock notCode code={`
Please enter an Integer
7   <- User Input
Please wait a moment.
`}/>
<br/>
<p>
  There can be any amount of valid code for each case. This includes things like if/else and 
  while statements. 
</p>

<br/>

<h3><strong>The Do While Loop</strong></h3>
      <p> Python does not have a do-while loop, but i can be emulated with a 
        while loop and when necessary, an if statement
      </p>
      <CodeBlock code={`
""" Python does not have a do-while loop, but it can me emulated with a while loop and when necessary, if statements. """

print("Enter an Integer")
num = int(input())
if num < 0:
  print("invalid input")

if num == 0:
  print("0! = 1")


fact = 1
i = num

while i>0:      # while statement
  """ begin of the do section """
  fact = fact*i
  i = i-1
  """ end of do section """

print(num, "! = ", fact, sep="")
`}/>
<p>Output: User enters 3</p>
<CodeBlock notCode code={`
3   <- User Input
3! =  6
`}/>
<br/>
    </>
  );
}

export default OtherControl;
