import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG3(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Function Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program to calculate the circumference of a circle. The program should allow the user 
        to enter a radius in meters then the program will display the circumfrence given the users radius. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  float radius, circumference;

  //Get the radius from the user
  printf("Please enter a radius in meters:\\n");
  scanf("%f",&radius);
  
  //Calculate the circumference
  circumference = 2*3.14159*radius;

  //Print the result to the screen
  printf("A circle with radius %f has circumference %f\\n",radius, circumference);
  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program to calculate the average of 3 integers. The program should allow the user 
        to enter 3 integers then the program will display the average to the user. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  int num1, num2, num3, avg;

  //Get the integers from the user
  printf("Please enter 3 integers:\\n");
  scanf("%d %d %d", &num1, &num2, &num3);

  //Calculate the average
  average = (num1 + num2 + num3) / 3.0 
  //use 3.0 or type cast to avoid integer division

  //Print the result to the screen
  printf("The average of the 3 numbers you entered is %d\\n",average);
  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build a program to print 0 to the screen if the user enters an even number. If 
        the user enters an odd number print 1. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  float num;

  //Get the number from the user
  printf("Please enter an integer:\\n");
  scanf("%f",&num;

  //Calculate and Print the result to the screen
  printf("%d\\n", num%2);
  return 0;
}
`}/>
    </Collapse>
    </>
  );
}

export default EG3;
