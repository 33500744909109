import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Union(){
  return (
    <>
      <TopHeader title="Unions" />
      <p>
      A union is similar to a struct in syntax but operates differently. In a struct, it is possible 
      to access all members of a struct at anytime in the program. Unions only allow access to one member 
      at a time. This is done to save space. A unions actual size in memory is only as large as it's largest 
      member. Then the union stores only information for 1 member at a time. The example below shows why this might 
      be more efficient or useful. The union below stores a distance either in meters of feet. We often won't need 
      both, so it's wise to not waste any of the extra memory to store both
      </p>
<CodeBlock code={`
#include<stdio.h>

union Distance
{
    float feet;
    float meters;
};

int main(){

  union Distance d1;
  
  d1.feet = 3.28084
  d2.meters = 1;

  cout << "feet = " << d1.feet << endl;
  cout << "meters = " << d1.meters << endl;
  return 0;
}

`}/>
<p>Output: </p>
<CodeBlock notCode code={`
feet = 0.0
meters = 1.0
`}/>
<br/>
<p>
  Notice that the program above didn't store both feet an meters. Once you assign a member in a union, that member becomes 
  active and all other members cannot store data. This is because the active member is using the shared memory space. This 
  is really an optimization problem if memory is really important to your program. Similar to structs we can also create an 
  array of unions and pointers to unions.
</p>
<CodeBlock code={`
#include<stdio.h>

union Distance
{
    float feet;
    float meters;
};

int main(){
  //declaring an array of unions
  union Distance d[10];

  //use array here
  //similar to accessing structs but
  //only 1 member can be active at a time.

}

`}/>
<p>Output: </p>
<CodeBlock notCode code={`

`}/>
<br/>
<p>
  Finally, there is also another way to declare unions. It's the same alternative method as structs and is shown below.
</p>
<CodeBlock code={`
#include<stdio.h>

union Distance
{
    float feet;
    float meters;
} d1, d2, distances[10];

int main(){

/* use d1, d2, and the distances array here */

}

`}/>
    </>
  );
}

export default Union;
