import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG4(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
       <TopHeader title="Arrays Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program to scan in 10 numbers(float) from a user. After recieving all the data, the program should 
        prompt the user to enter 1 or 2. If 1 is selected, the program should search for the number 10.0 in the data. The 
        program should display if it has that number or not. If 2 is slected, the program should test to see if there are 
        any data points above 100. The program should display if it found any data points over 100 or not.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  //Declare Variables
  float data[10], option, check;

  //Get the data from the users
  for(int i = 0; i <10; i++)
  {
    cin >> data[i];
  }

  //user should pick option 1 or 2
  cout << "Enter (1) to check if 10.0 is in the data set" << endl;
  cout << "Enter (2) to check if there are numbers over 100" << endl;
  cin >> option;

  check = 0; //check will be used in either option
  
  /* execute option 1 */
  if(option == 1)
  {
    for(int i = 0; i< 10; i++)
    {
      if(data[i]==10.0)
      {
        check = 1;
        break;
      }
    }
    if(check==1)
      cout << "10.0 Found!" << endl;
    else
      cout << "10.0 NOT Found!" << endl;
  }

  /* execute option 2 */
  if(option == 2)
  {
    for(int i = 0; i<10; i++)
    {
      if(data[i]>100)
      {
        check = 1;
        break;
      }
    }
    if(check==1)
      cout << "Numbers over 100.0 Found!" << endl;
    else
      cout << "No Numbers over 100.0 Found!" << endl;
  }

  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program to scan in the user's first name as a string. You can assume that someone's first name will never 
        be longer than 100 characters. The program should display how long the users first name is and also display the first the 
        5th character in the name. If there is no fifth character in someone's name, then the program should display
        'no fifth character'.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  //Declare Variables
  char name[100];
  int count;

  //Get user's first name
  cout << "Enter your first name" << endl;
  cin >> name;

  count = 1;
  while(name[count] != '\0')
  {
    count++;
  }

  //print the count to the screen
  cout << "The user's name is " << count << " characters long" << endl;
  if (count >= 5)
    cout << "the 5th character is " << name[4] << endl;
  else
    cout << "no fifth character" << endl;

  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build a mini guessing game with a 2D array. You should create a 5x5 array and choose an element to hold the 
        number 1. All other elements hold the number 0. Again, you should choose in the program where the one goes.
        When a user starts your program it will ask for coodinates (like battleship) for where your 1 is. The player 
        can chose the Row (0-4) and the Column (0-4). The player should have 3 chances to guess. If the player guesses 
        correct the program should display "Winner!". If the player uses all 3 turns without a correct guess the program 
        should display "Loser!".
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  //Declare Variables
  int field[5][5];
  int gRow, gCol; 
  int win = 0;

  //initialize all to 0
  for(int i = 0; i<5; i++)
  {
    for(int j = 0; j<5; j++)
    {
      field[i][j] = 0;
    }
  }
  //pick where the 1 goes
  field[2][3] = 1;

  //loop 3 chances for the player to guess
  for(int i = 0; i<3; i++)
  {
    cout << "Please enter guess for the Row(0-4) and Column(0-4):" << endl;
    cin >> gRow >> gCol;
    if(field[gRow][gCol] == 1)
    {
      win = 1;
      break;
    }
    else
    {
      cout << "Incorrect" << endl;
    }
  }
  if(win == 1)
    cout << "Winner!" << endl;
  else
    cout << "Loser!" << endl; 

  return 0;
}
`}/>
</Collapse>
    </>
  );
}

export default EG4;
