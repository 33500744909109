import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function If(){
  return (
    <>
      <TopHeader title="If/Else Statement" />
      <p>
      The IF/Else statement allows us to make decisions based on conditional statements. If the 
      conditional statement is true the code in the if block runs. If it's false the code in the else 
      block runs. Take a look at the simple example below.
      </p>
      <CodeBlock code={`
"use strict"
const prompt = require('prompt-sync')();
 
let x = 12925;

if(x > 9000){
    console.log("Your number seems a little small");
}
else{
    console.log("Its over 9000!");
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
It's over 9000!
`}/>
<br/> 
<p>
  If the variable x was set to a lower number(below 9000), the other print statment would have been 
  printed to the screen. You should be aware the the curly braces are actually not needed in 
  this particular case. There is only 1 statement in the if section and the else section, so curly 
  braces here are option. Using the curly braces on many lines of code is called a compound statement.
  Basically, the curly braces allow us to add in as much code as we like for the if and the else 
  portions.
</p>
<CodeBlock code={`
"use strict" 
const prompt = require('prompt-sync')();

let x = 426;

//The program makes a decision based on the value of x
if(x < 9000) //this is the parameter that the computer checks if true or false
{ //if x is less than 9000 this code runs
    console.log("Your number seems a little small");
    console.log("I'll multiply your number by 2");
    x = x * 2;// this is the calculation x is multiplied by two 

    console.log("your new number is " + x);
}

else //if the condition was false this code runs 
{
    console.log("That's a pretty big number!");
    console.log("I'll divide your number by 2");
    x = x / 2; // this is the calculation x is divided by two 
    console.log("your new number is " + x);
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
Your number seems a little small
I'll multiply your number by 2
your new number is 852
`}/>
  <p>If this is confusing, then always use curly braces wont break your programs. You should just 
    be aware of this fact when reading another developer's code. 
  </p>
  <p>
    Any valid code, can go inside and if/else statement. Even another if/else statement. 
    Nesting if/else statements are a common way to control your programs flow. Check out 
    the example below.
  </p>
  <CodeBlock code={`
"use strict"

let x = 12;
let y = -4;
if(x > 0){
	if(y > 0){
		console.log("x and y are positive\n");
	}else{
		console.log("x is positive and y is negative\n");
	}
}else{
	if(y > 0){
		console.log("x is negative and y is positive\n");
	}else{
		console.log("x and y are negative\n");
	}
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
x is positve and y is negative
`}/>
<br/>
<p>
  A coumpound conditional statement could have also been used. In addition, else statements 
  are optional. In JavaScript you can leave out the else statement if it's not needed. The example below 
  demonstrates the use of compound conditional statments and using if without else.
</p>
<CodeBlock code={`
"use strict"
let x = 12;
let y = -4;
if(x>0 && y>0){
    console.log("x and y are positive");
}
if(x>0 && y<0){
    console.log("x is positive and y is negative");
}
if(x<0 && y>0){
    console.log("x is negative and y is positive");
}
if(x<0 && y<0){
    console.log("x and y are negative");
}
`}/>
<p>
  Outuput:
</p>
<CodeBlock notCode code={`
x is positve and y is negative
`}/>
<br/>
<p>
  The if/else statement has one more variation that should be discused. This is called the
  if/else if. The variation takes advantage of the fact that you don't necessarilly need brackets 
  and the ability to nest multiple if statments. If you look closely at the example below, you will 
  see an else that runs only one statement and the statment is a new if statment. 
</p>
<CodeBlock code={`
"use strict"

let x = 4;

if(x==1){
    console.log("The number is 1\n");  
}else if(x==2){
    console.log("The number is 2\n");
}else if(x==3){
    console.log("The number is 3\n");
}else if(x==4){
    console.log("The number is 4\n");
}else{
    console.log("The number is not one of the options\n");
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
The number is 4
`}/>
<br/>
<p>
  Nesting the control statements like this ensures that only 1 (the first true if statement) will 
  run. In the other example above with multiple If statements on the same level, all the if statments 
  have a chance to be true and run their code. In this example, only the first true statement will run 
  it's code. The last else at the end, works like a catch all. If none of the statements above it are 
  true, then the last else will definitely run. Again, this last else statement is also optional.
</p>
<br/>
<p> There is another way to accomplish similar functionality by using a switch 
statement. But, that will be explained in another sections.
</p>
    </>
  );
}

export default If;
