import React from 'react';


function EGPreface(){
    return(
        <div>
        <p>
      The examples in the Examples sections will make use of only the techniques in that 
      section and the sections before it. A problem statement is given and you are encouraged to
      try the problem on your own before you reveal the solution. The solutions given are not the 
      only solution. It is possible to obtain the same result with different program designs.
      </p>
      <br/>
      <br/>
      </div>
    );

}
export default EGPreface;