import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Cast(){
  return (
    <>
      <TopHeader title="Type Casting (Type Conversions)" />
      <p>
Type casting allows us to change the data type of a variable for one line of code.
This can be useful as programmers often need to sometimes treat variables differently
to accomplish certain tasks. It's actually a very simple operation. You'll see more use
cases in future examples and larger examples. For now let's just learn the syntax and 
how to use it. A sample code is shown below.
</p>
<CodeBlock code={`
var = 9.8 # declares & initializes var to hold 9.8, var is a float

print("the value is", int(var)) # <-casting var as an int
`} />
<p> Output:</p>
<CodeBlock notCode code={`
the value is 9
`} />
<p>
  Casting has been shown before when you were learning the input() function. All inputs 
  are treated as strings. We simply need to cast those strings as int or float to perform 
  mathematical operations on them.
</p>
<br/>
<br/>
<h3><strong>Integer Division</strong></h3>
<br/>
<p>
  Python automatically handles integer division. In some languages, when you 
  divide 2 ints the result is an int. For example 1/2 = 0.5. But since the result 
  must be an int the answer would be 0. Another example is 3/2 = 1. Python automatically 
  handles the fact that if division is performed, a float will be returned so you can receive 
  a decimal number back. You can always cast the float to an int if you need integer division.
</p>
      <CodeBlock code={`
x = 5
y = 2

a = x/y                  # python will automatically return a float from integer division unless a is casted to be an int
b = x/y
print("a =", int(a))     # printing an int division by casting a to be an int
print("b =", b)          # printing the results of normal divison
print("x:", x, " y:", y) # printing x and y again to check.

`}/>
<p>Output:</p>
<CodeBlock notCode code={`
a = 2
b = 2.500000
x:5 y:2
`} />

<p>
  There are many other uses for typecasting. Often 
  it's easier to temporarily change a character's type instead of storing two different 
  types of the same information. You will definitely see more use cases as you build larger 
  programs.
</p>
    </>
  );
}

export default Cast;
