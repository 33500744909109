import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function DIY() {
  return (
    <>
            <TopHeader title="DIY Development Environment" />
      <br />
      <p>
        You may use any method to set up your development environment on your own. The code provided on this site should work if your environment is set 
        up correctly.
      </p>
    </>
  );
}

export default DIY;
