import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Rand(){
  return (
    <>
      <TopHeader title="Random Number Generation" />
      <p>
      Random number generation is useful in programming.
      you can use the rand.nextInt() to generate a random integer or pass the method an integer 
      to generate a random number from 0 to the passed integer. Both are demonstrated in the program 
      below.
      </p>
      <CodeBlock code={`
import java.util.Random;

public class rand1
{
  public static void main(String[] args)
  {
    Random rand = new Random();
    int x = rand.nextInt(); // generate a random int
    int y = ran.nextInt(10); // generate a random int between 0 and 10

    System.out.println(x);
    System.out.println(y);
  }
}
`}/>
      <CodeBlock notCode code={`
1804289383      <-should be different for everyone and everytime you run the program.
4               <-should be different for everyone and everytime you run the program.
`}/>
<br/>
    </>
  );
}

export default Rand;
