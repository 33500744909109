import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Array2Func(){
  return (
    <>
      <TopHeader title="Passing Arrays To Functions" />
      <p>
        When an array is created, a pointer is also being created. The example code below shows that the name 
        of the array variable is actually a pointer and the address can be printed. Since arrays allocate memory 
        space for all variables next to each other, we can also use pointer to look at each value in the array. 
        The name of the variable points to the first value. The name + 1 points to the second value and so on.
        The example uses the pointer to access variable and uses the subscript operator on the 4th index(x[3]) to 
        show that both ways are valid. In general we can access an elemnt in the array by using *(x + i), where i is 
        the ith element of the array.
      </p>
<CodeBlock code={`
#include<iostream>
using namespace std;

int main(){
  int x[4];
  *x = 100;
  *(x+1) = 101;
  *(x+2) = 102;
  x[3] = 103;

  cout << "array pointer: " <<  x << endl;
  cout << "index 0: " << *x << endl;
  cout << "index 1: " << *(x+1) << endl;
  cout << "index 2: " << *(x+2) <<endl;
  cout << "index 3: " << x[3] << endl;
  return 0;
}
`}/>
<p>Oupput: </p>
<CodeBlock notCode code={`
array pointer: 0x7ffd5af07ea4   <-- Different for everyone
index 0: 100
index 1: 101
index 2: 102
`}/>
<br/>
  <p> 
    The example above shows that a pointer is created and we can actually increment by integer values to access 
    elements in an array. It's important to know that in the above example the address stored in x should never change.
    For example, you should never perfomr x++ or x=x+1;. Those commands will lose the start of the array for x and commands 
    like x[2] = 3;, would not be able to function correctly. If you wanted to increment like that, you could declare a 
    seperate pointer and initialize it's value to the address of the array. Then manipulate the new pointer any way you like.
  </p>
  <br/>
  <h3><strong>Passing 2D arrays to Functions</strong></h3>
  <p>
    What does this mean when passing arrays to functions? If you pass an array to a function, you are actually passing 
    a pointer to the array. We can define the prototype for a function in two different ways but essentially they mean 
    the same thing. A pointer is being passed.
  </p>
  <CodeBlock code={`
/* This function takes a pointer to an integer */
/* An array can also be sent to this function */
int ArrayFunction1(int *x);

/* This function also take a pointer to an array */
int ArrayFunction2(int x[]);
`}/>
  <br/>
  <p>
    This is important because if we are passing pointers, remember that the function is not accessing a copy of the data.
    The function will be accessing the actual array data in memory. 
    Let's try an example. 
  </p>
  <br/>
  <p>
    Build a function that takes two parameters. One parameter is an array of floats. The other parameter is the length of 
    the array. The function should sum all values in the array and then reset all the array values to zero. The function 
    should then return the sum calculated earlier. The main function should create the array (5 floats long using  your 
    own values). After the array is created the main program should print the data to the screen. Then main() should 
    call the function (passing the array and the count). Finally, the function should reprint the array to the screen.  
  </p>
  <CodeBlock code={`
#include<iostream>
using namespace std;

float SumArray(float a[], int count);

int main(){
  float x[5];
  int i, total;
  
  /* Initialize values to 0, 1.1, 2.2, etc */
  for(i = 0; i < 5; i++)
    x[i] = 1.1 * i;

  /* Print values to the screen */
  for(i = 0; i < 5; i++)
    cout << x[i] <<"\\t" << endl; // \\t is tab
  endl;
  
  /* call function */
  /* Only the pointer to the array is passed */
  total  = SumArray(x, 5);

  cout << "The sum is: " << total << endl;;
  
  /* Print values to the screen */
  for(i = 0; i < 5; i++)
    cout << x[i] << "\\t" << endl;
  endl;

  return 0;
}

float SumArray(float a[], int count){
  int sum = 0;
  for(int i = 0; i < count; i++){
    /* accumulate sum */
    sum += a[i];
    
    /* Reset element at index i */
    a[i] = 0;
  }

  return sum;
}
`}/>
<p>Oupput: </p>
<CodeBlock notCode code={`
0.0 1.1 2.2 3.3 4.4
The sum is: 11.0
0.0 0.0 0.0 0.0 0.0 
`}/>
<br/>
<p>
  Notice we only passed the pointer of the array to the function and the function only returned the sum. 
  The function did not return a different arrary or any array values. But, the actual array values were 
  changed because we passed a pointer to the array. This was not possible before
  when normal variables were being passed to the function.
</p>
<br/>
<h3><strong>Passing 2D arrays to Functions</strong></h3>
<p>
  The data in memory for for a 1D array is stored in a consectutive row. This is why we can use *(x+i) to access the data. 
  Actually a 2D array is also stored as one row of data. The first row folowed by the second row followed by the 3rd row and 
  so on. So to access a 2D array from a single pointer we can use the general form *(*(x+i)+j), where i and j are the row 
  and column of the element we want to access. If you take a closer look at the at form, you'll see that this is just treating 
  each row as it's own individual arrays. Performing *(x+i) just gives us the ith row's address. Since it is an address another 
  way to access it is *(x[i]+j). Using both these forms are shown below.

</p>
<CodeBlock code={`
#include<iostream>
using namespace std;

int main(){
  int x[3][3];
  int count = 0;
  int i, j;

  /* Initializes an array to store the count */
  for( i = 0; i<3; i++){
          for ( j = 0; j<3; j++){
                  x[i][j] = count; 
                  count++;
          }   
  }   

  /* Print the Array 3 Different ways */
  /* Using Subscript */
  for(i = 0; i< 3; i++){
    for(j = 0; j<3; j++){
      cout << x[i][j] << "  " << endl; 
    }
    endl;
  }
  endl;

  /* Using pointer */
  for(i = 0; i< 3; i++){
    for(j = 0; j<3; j++){
      cout << *(*(x+i)+j) << "  " << endl;
    }
    endl;
  }
  endl;

  /* Using pointer 2 (mixed) */
  for(i = 0; i< 3; i++){
    for(j = 0; j<3; j++){
      cout << *(x[i]+j) << "  " << endl;
    }
    endl;
  }
  return 0;
}
`}/>
<p>Oupput: </p>
<CodeBlock notCode code={`
0	1	2	
3	4	5	
6	7	8	

0	1	2	
3	4	5	
6	7	8	

0	1	2	
3	4	5	
6	7	8	
`}/>
<br/>
<p>
  The above ways to access are all equivalent and do the same thing. The main take away is to realize the the data 
  is stored in one consecutive row. This means if we pass a 2D array to a function, the function needs to know the 
  collumn size (how much data is in each row). This way, the pointers know how to increment the rows and columns 
  correctly. Below is how you can set up passing a simple 2D array to a function. Let's use the same SumArray function 
  from the problem in 1D arrays to accumulate the sum for a 2D array.
</p>
<CodeBlock code={`
#include<iostream>
using namespace std;

float SumArray2(float a[3][3], int rowSize, int colSize);
/* Only the column size is required */
/* Alternate Valid Prototype */
/* float SumArray2(float a[][3], int rowSize, int colSize); */
int main(){
  float x[3][3];
  int i,j, total, count;

  /* Initialize values to 0, 1.1, 2.2, etc */
  count = 0;
  for(i = 0; i<3; i++){
    for(j = 0; j<3; j++){
        x[i][j] = 1.1 * count;
        count++;
    }
  }

  /* Print values to the screen */
  for(i = 0; i < 3; i++){
    for(j = 0; j< 3 ; j++){
      cout << x[i][j] << "  " << endl;
    }
    endl;
  }
  endl;
  
  /* call function */
  /* Only the pointer to the array is passed */
  total  = SumArray2(x, 3, 3);

  cout << "The sum is: " << total << endl;
  
  /* Print values to the screen */
  for(i = 0; i < 3; i++){
    for(j = 0; j< 3 ; j++){
      cout << x[i][j] << "  " << endl;
    }
    endl;
  }
  endl;
  
  return 0;
}

float SumArray2(float a[3][3], int rowSize, int colSize){
  int sum = 0;
  for(int i = 0; i < rowSize; i++){\
    for(int j = 0; j< colSize; j++){
      /* accumulate sum */
      sum += a[i][j];
      
      /* Reset element at index i */
      a[i][j] = 0;
    }
  }

  return sum;
}
`}/>
<p>Oupput: </p>
<CodeBlock notCode code={`
0.0	1.1	2.2	
3.3	4.4	5.5	
6.6	7.7	8.8	

The sum is: 36
0.0	0.0	0.0	
0.0	0.0	0.0	
0.0	0.0	0.0
`}/>
<br/>
<p>
  Just like in 1D arrays, we just pass the name (pointer to the array) to the function. We don not have to pass anything 
  else. However, the prototype must be set up correctly in order to recieve a 2D array.
</p>

    </>
  );
}

export default Array2Func;
