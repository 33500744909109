import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function If(){
  return (
    <>
      <TopHeader title="If/Else Statement" />
      <p>
      The IF/Else statement allows us to make decisions based on conditional statements. If the 
      conditional statement is true the code in the if block runs. If it's false the code in the else 
      block runs. Take a look at the simple example below.
      </p>
      <CodeBlock code={`
x = 12925

# The program makes a decision on which code to run
# Only one of the options (if or else) will run
# Indentation matters in Python!  Be consistent.

if x < 9000:
  print("Your number seems a little small")
else:
  print("It's over 9000!")

# The program will then continue here

`}/>
<p>Output: </p>
<CodeBlock notCode code={`
It's over 9000!
`}/>
<br/> 
<p>
  If the variable x was set to a lower number(below 9000), the other print statment would have been 
  printed to the screen. Here's another example of putting more code in the if and else blocks.
</p>
<CodeBlock code={`
x = 426
# The program makes a decision
if x < 9000:
  print("Your number seems a little small")
  print("I'll multiply your number by 2")
  x = x*2
  print("your new number is", x)
else:
  print("That's a pretty big number number!")
  print("I'll divide your number by 2")
  x = x/2
  print("your new number is", x)

# The program continues on
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
Your number seems a little small
I'll multiply your number by 2
your new number is 852
`}/>
  <p>
    Any valid code, can go inside and if/else statement. Even another if/else statement. 
    Nesting if/else statements are a common way to control your programs flow. Check out 
    the example below.
  </p>
  <CodeBlock code={`
x = 12
y = -4

if x > 0:
  if y > 0:
    print("x and y are positive")
  else:
    print("x is positive and y is negative")
  
else:
  if y > 0:
    print("x is negative and y is positive")
  else:
    print("x and y are negative")
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
x is positve and y is negative
`}/>
<br/>
<p>
  A coumpound conditional statement could have also been used. In addition, else statements 
  are optional. In C you can leave out the else statement if it's not needed. The example below 
  demonstrates the use of compound conditional statments and using if without else.
</p>
<CodeBlock code={`
x = 12
y = -4
if x > 0 and y > 0:
  print("x and y are positive")
if x > 0 and y < 0:
  print("x is positive and y is negative")
if x < 0 and y > 0:
  print("x is negative and y is positive")
if x < 0 and y < 0:
  print("x and y are negative")
`}/>
<p>
  Outuput:
</p>
<CodeBlock notCode code={`
x is positve and y is negative
`}/>
<br/>
<p>
  The if/else statement has one more variation that should be discused. This is called the
  elif which is short for else/if. Only the first block with a true condition will run in this case.
</p>
<CodeBlock code={`
x=4

if x==1:
  print("The number is 1")
elif x==2:
  print("The number is 2")
elif x==3:
  print("The number is 3")
elif x==4:
  print("The number is 4")
else:
  print("The number is not one of the options")
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
The number is 4
`}/>
<br/>
<p>
  Nesting the control statements like this ensures that only 1 (the first true if statement) will 
  run. In the other example above with multiple If statements on the same level, all the if statments 
  have a chance to be true and run their code. In this example, only the first true statement will run 
  it's code. The last else at the end, works like a catch all. If none of the statements above it are 
  true, then the last else will definitely run. Again, this last else statement is also optional.
</p>
<br/>
<p> There is another way to accomplish similar functionality by using a switch 
statement. But, that will be explained in another sections.
</p>
    </>
  );
}

export default If;
