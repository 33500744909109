import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function IO() {
  return (
    <>
      <TopHeader title="Input/Output (I/O)" />
      <h3><strong>Output</strong></h3> <br />
      <p>
        Now you know how to declare variables and store information in those variables.
        What can we do with variables? We can manipulate them, print them to the screen,
        and even store input from a user. This lesson will focus on printing a
        variable's contents to the screen and storing user input into a variable.
        To print a variable to the screen, we will still use the print function. 
        The program
        below is the exact same program from the last lesson but has the addition of print
        statements.
      </p>
      <CodeBlock code={`
x = 1024 
y = 3.14
ch = 'y'

# printing to the screen
print("the value in x is", x)
print("y = ", y)
print(ch, "is stored in ch")

# Print Multiple Variables to the screen
print("x:", x, " y:", y, " ch:", ch)
`} /><p>
        Output:
</p>
      <CodeBlock notCode code={`
the value in x is 1024
y = 3.14
y is stored in ch
x: 1024 y: 3.140000 ch: y
`} />
<p>
        Note that we can use this print statement to test our programs. We can make sure
        the code is doing what we expect. Consider the following code from the last lesson.
        By adding in print statements we can see what the program is executing in order.
</p>
<CodeBlock code={`
x = 5 # declares and initializes x to hold 5
y = 0 # declares and initializes y to hold 0
a = b = 9.8 # a and b both hold 9.8

print("x:", x, " y:", y, " a:", a, " b:", b)
y = x # y now holds 5
print("x:", x, " y:", y, " a:", a, " b:", b)
b = 3.14 # b now holds 3.14
print("x:", x, " y:", y, " a:", a, " b:", b)
a = b # a now holds 3.14
print("x:", x, " y:", y, " a:", a, " b:", b)
`} />
<p>
        Output:
</p>
      <CodeBlock code={`
x:5 ,y:0 ,a:9.800000 ,b:9.8000000
x:5 ,y:5 ,a:9.800000 ,b:9.8000000
x:5 ,y:0 ,a:9.800000 ,b:3.1400000
x:5 ,y:0 ,a:3.140000 ,b:3.1400000
`} />
      <br />
      <br />
      <h3><strong>Input</strong></h3>
      <br />
      <p>
        Now that we can print a variable to the screen, let's see how we can
           allow a user to store information into a variable in the program. Here
           we introduce a new function. The first function we learned was called print().
           This new function is called input(). The input() function will scan in user input
           and store it in a variable. The code below, shows how we can use the 
           input() function. 
  </p>
      <CodeBlock code={`
"""
Using the input() function. 
NOTE: Python automatically converts all input into a 
string.
"""

x = input()
print("The input you entered, ", x, ", is of type: ", type(x), sep = "")

"""
NOTE: Avoid using "input" as the variable name for an input
because this can be confusing.
"""
`} />
      <p>
      Python automatically converts all input into a string. This just means everything is 
      treated as a character. The number 123 and the string 123 are different. We will show 
      you how to convert a string back to a number in a moment.
      Avoid using "input" as the variable name for an input.
      Running the above code will show nothing at first. The input() statement is waiting
      for a user to input an integer. If you enter the number 35 and press enter, the output
      will be
  </p>
      <CodeBlock notCode code={`
35    <- User Input
You entered the number 35
`} />
      <p>
        It's better to put a print statement before an input (or scan) statement when asking for user input.
        It helps the user of the program understand what they are supposed to do. Consider the following
        program.
</p>
      <CodeBlock code={`
num = input("Please enter a decimal number: ")
print("You entered the number", num)
print("num is not a float, but a string:", type(num))
`} />
      <p>The output of the program with user input of 512 is shown below.</p>
      <CodeBlock notCode code={`
Please enter a decimal number:
512  <- User Input
You entered the number 512
`} />
      <p>
        You can write multiple input statements in a row to scan in any number 
        of inputs from the user.
</p>
      <CodeBlock code={`
"""
In order to convert the inputs from a 
string to another data type, they must 
be cast into the desired type.
"""

print("Please enter two ints and a float:")
x2 = input()
x1 = input()
y = input()

# Here we are casting each variable into the desired data type
# and also printing to the screen.
print("x1:", int(x1), " x2:", int(x2), " y:", float(y))     

`} />
      <p>
        Output: Note the input statement scans for x2 before x1.
</p>
      <CodeBlock notCode code={`
Please enter two ints and a float:
25 4 91.7    <- User Input

x1: 4
X2: 25
y: 91.700000
`} />
      <p>
        Now let's deal with controlling the decimal on those float values. By default, printf() will
        show 6 decimal places for float variables. We can control the width of the output and the decimal
        places you want to print. This is accomplished by changing your %f specifiers in printf().
      <br /><br />
        <strong>%w.df</strong> - w and d are ints. Print a decimal number with total width w and d decimal places.
      <br /><strong>%5.2f</strong> - print a float with total width 5 and 2 decimal places.
      <br /><strong>10.1f</strong> - print a float with total width 10 and 1 decimal place.
      <br /><br />
        The width is the total count of characters being printed to the screen. The decimal places is how many
        characters after the decimal you want to print. There's some small nuances you should be aware of.
      <br />1. The decimal or period counts towards the width. It counts as 1 character.
      <br /> 2. If the decimal place causes the width of the output to be greater than the width specified,
        the decimal places win and override the width. The width is ignored in this case.
      <br /> 3. If the number has the correct decimal places specified but is still shorter than the width
        specified, then the number is padded on the left side by spaces.
      <br />
        That's all so wordy and confusing. So check out the example below and then come back and read
        this to hopefully gain a better understanding.
    </p>
      <CodeBlock code={`
pi = 3.141593

# width 2 and 4 decimal places
print("%4.4f" % pi) # This is case 2 above

# width 6 and 2 decimal places
print("%6.2f" % pi) # This is case 3 above

# width 5 and 3 decimal places
print("%5.3f" % pi)

# width 6 and 2 decimal places and leading 0
print("%06.2f" % pi)
`} />
      <p>
        Output:
</p>
      <CodeBlock notCode code={`
3.1416
3.14
3.142
003.14
`} />
<p>

</p>
      <br /><br />
      <h3><strong>Character I/O</strong></h3>
      <br /><p>
      As we have seen, All inputs are automatically treated as a string,
      which is an array of character. More on arrays later, but for now 
      characters are automatically scanned in using the input() function.
      </p>
    </>
  );
}
export default IO;
