import React from "react";
// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG2(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Control Flow Section Examples" />
      <EGPreface/>
      
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program that takes 5 inputs from the user. The program should display the 
        smallest value entered before exiting. The inputs can be decimal numbers
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  float input, min, i;
  
  //get 5 inputs and check their values
  for(for i = 0; i<5 ;i++){
    //scan a value
    printf("Enter a number\\n");
    scanf("%f", &input);
    
    //if first input or input < min
    if(i==0 || input < min) 
        min = input; //set min value
  }

  //Print the result to the screen
  printf("The smallest number entered was %f\\n",min);
  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program that takes user input until the user enters -999. After -999 is entered,
        the program should print how many numbers were entered(not including -999) 
        and the largest value entered and then exit. The inputs should 
        only be integer but postive and negative values are allowed.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  int input, max, count;

  //Get the first integer
  printf("Please Enter an Integer(-999 To Quit):\\n");
  scanf("%d", &input);
  count = 0;
  
  //while loop until -999 is entered
  while(input != -999){
      count = count + 1;
      //if first input or input > max
      if(count == 1 || input > max)
        max = input; //set max

      printf("Please Enter another Integer(-999 To Quit):\n");
      scanf("%d", &input);
  }
  //Print the result to the screen
  if(count == 0)
    printf("0 numbers were entered.\\n");
  else
    printf("%d numbers entered. The largest was %d\\n",count, max);

  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build a program that allows a user to enter 2 integers. The program should subtract 
        the second input from the first input. For example, the user enters 5 2. In this case the 
        program should perform 5-2 and report 3. In addition, the program should ignore the inputs 
        when the first integer is smaller than the second integer, and then ask for a new set of 
        integers. Once a valid input is detected, the program should perform the subtraction 
        and print the result to the screen.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  int num1, num2, diff;

  do{
    printf("Please Enter 2 Integers.(larger number first)\\n");
    scanf("%d %d", &num1, &num2);
  }while(num1 < num2);
  
  //calculate difference
  diff = num1 - num2;
  
  //Print the result to the screen
  printf("The difference of the two inputs is: %d\\n", diff);
  return 0;
}
`}/>
    </Collapse>
    </>
  );
}

export default EG2;
