import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function IO() {
  return (
    <>
      <TopHeader title="Input/Output (I/O)" />
      <h3><strong>Output</strong></h3> <br />
      <p>
        Now you know how to declare variables and store information in those variables.
        What can we do with variables? We can manipulate them, print them to the screen,
        and even store input from a user. This lesson will focus on printing a
        variable's contents to the screen and storing user input into a variable.
        To print a variable to the screen, we will still use the console.log() function.
      </p>
      <CodeBlock code={`
"use strict"
 
//declare variables and set to values
let x= 1024;
let y= 3.14;
let ch= 'y';
 
//print the value of x to screen
console.log("the value in x is " + x);
//print the value of y to screen
console.log("y= "+ y);
//print the character stored in ch to screen
console.log(ch + " is stored in ch");
 
//print all the variables and their stored value to screen 
console.log("x:" + x + " y:" + y + " ch:" + ch); 
`} /><p>
        Output:
</p>
      <CodeBlock notCode code={`
the value in x is 1024
y = 3.14
y is stored in ch
x: 1024 y: 3.140000 ch: y
`} />

      <p>
        Note that we can use these prints to test our programs. This will allow us to make sure
        the code is doing what we expect. Consider the following code from the last lesson.
        By adding in print statements we can see what the program is executing in order.
</p>
      <CodeBlock code={`
//  The following program shows how variables can be given different values, including those held by other variables
 
 
"use strict"
 
let x = 5;  //declares and initializes x to hold 5
let y = 0;  //declares and initializes y to hold 0
let a, b;   //declare two variables
a = b = 9.8;    //a and b both hold 9.8
 
// prints readout of variables' values
console.log("x: %d, y: %d, a: %f, b: %f", x, y, a, b);	
y=x; //y now holds 5;
// prints readout of variables' values
console.log("x: %d, y: %d, a: %f, b: %f",x,y,a,b);
b = 3.14; //b now holds 3.14
// prints readout of variables' values
console.log("x: %d, y: %d, a: %f, b: %f",x,y,a,b);
a = b; //a now holds 3.14
// prints readout of variables' values
console.log("x: %d, y: %d, a: %f, b: %f",x,y,a,b);
`} />
      <br />
      <br />
      <h3><strong>Input</strong></h3>
      <br />
      <p>
        Now that we can print a variable to the screen, let's see how we can
           allow a user to store information into a variable in the program. Here
           we introduce a new function. The first function we learned was console.log().
           This function is called prompt(). 
  </p>
    <p><strong>For the sake of this class, we will be using an npm library called 
      prompt-sync. It allows us to scan in user input using the command prompt().</strong></p>
      <CodeBlock code={`
"use strict" 
/* This line below is required if you need to use prompt */
const prompt = require('prompt-sync')();

// Declare a variable named x
let x;
//Scan in a value for x 
x = prompt('');
//Printt the number you entered for x
console.log('You entered the number %d\\n', x);
`} />
      <p>
       Running the above code will show nothing at first. The scanf statement is waiting
        for a user to input an integer. If you enter the number 35 and press enter, the output
        will be
  </p>
      <CodeBlock notCode code={`
35    <- User Input
You entered the number 35
`} />
      <p>
        It's better to put a string in prompt when asking for user input.
        It help the user of the program understand what they are supposed to do. Consider the following
        program.
</p>
      <CodeBlock code={`
"use strict" 
/* This line below is required if you need to use prompt */
const prompt = require('prompt-sync')();

// Declare a variable named x
let x;
//Scan in a value for x 
x = prompt('Please enter a decimal value');
//Print the number you entered for x
console.log('You entered the number %d\\n', x); 
// the \\n just stands for newline
`} />
      <p>The output of the program with user input of 5.12 is shown below.</p>
      <CodeBlock notCode code={`
Please enter a decimal number:
5.12  <- User Input
You entered the number 5.12
`} />
      <p>
        It is possible to perform multiple prompts one after another to scan in more data.
        Below shows an example of this.
</p>
      <CodeBlock code={`
"use strict"    
const prompt = require('prompt-sync')();

//Declare variables
let x1;
let x2;
let y;

//Prompt the user to enter two int and a float
console.log("Please enter two integers and a float (Each followed by [Enter]): \\n");
    //Gets the first integer input from the user
    x1 = prompt();
    //Gets the second integer input from the user
    x2 = prompt();
    //Gets the first float input from the user
    y = prompt();

//Displays user input
console.log("\\nx1: %d\\n x2: %d\\n y: %f\\n", x1, x2, y);
// again \\n just means newline here
`} />
      <p>
        Output: Note the scan statement scans for x2 before x1.
</p>
      <CodeBlock notCode code={`
Please enter two ints and a float:
25 4 91.7    <- User Input

x1: 4
X2: 25
y: 91.700000
`} />
      <p>
        Now let's deal with controlling the decimal on those float values. By default, printf() will
        show 6 decimal places for float variables. We can control the width of the output and the decimal
        places you want to print. This is accomplished by changing your %f specifiers in printf().
      <br /><br />
        <strong>%w.df</strong> - w and d are ints. Print a decimal number with total width w and d decimal places.
      <br /><strong>%5.2f</strong> - print a float with total width 5 and 2 decimal places.
      <br /><strong>10.1f</strong> - print a float with total width 10 and 1 decimal place.
      <br /><br />
        The width is the total count of characters being printed to the screen. The decimal places is how many
        characters after the decimal you want to print. There's some small nuances you should be aware of.
      <br />1. The decimal or period counts towards the width. It counts as 1 character.
      <br /> 2. If the decimal place causes the width of the output to be greater than the width specified,
        the decimal places win and override the width. The width is ignored in this case.
      <br /> 3. If the number has the correct decimal places specified but is still shorter than the width
        specified, then the number is padded on the left side by spaces.
      <br />
        That's all so wordy and confusing. So check out the example below and then come back and read
        this to hopefully gain a better understanding.
    </p>
      <CodeBlock code={`
"use strict"
/* define variables */  
let pi = 3.141593;
  //width 2 and 4 decimal places
  console.log("%2.4", pi); //This is case 2 above
  
  //width 6 and 2 decimal places
  console.log("%6.2f", pi); //This is case 3 above
  
  //width 5 and 3 decimal places
  console.log("%5.3f",pi);
 
  //width 6 and 2 decimal places and leading 0
  console.log("%06.2f", pi);
`} />
      <p>
        Output:
</p>
      <CodeBlock notCode code={`
3.1416
3.14
3.142
003.14
`} />
<p>

</p>
      <br /><br />
      <h3><strong>Character I/O</strong></h3>
      <br /><p>
      Character I/O behaves differently than when you are scanning numbers like ints and floats.
      When scanning ints and floats, we can seperate input by whitespace (space, tab, newline, etc.) and 
      prompt() is able to process those inputs. With characters we read one character at a time, which includes
      space, tab, and newline. That is because those are also characters.
      <br/><br/>
      Character I/O can be accomplished by just using a prompt to read in a string of character. We can 
      then access each character by index in the string that was scanned in.
      </p>
      <CodeBlock code={`
"use strict"

const prompt = require('prompt-sync')();

//declare your variables
let str;
let ch1, ch2, ch3, ch4;

//declare the variable as a single character
ch4 = "y"; 

//get the entered value as a string
str = prompt();

//set ch1, ch2, and ch3 equal to the first second and third values of the string
ch1 = str.charAt(0); //returns the character at postition 0 from str
ch2 = str.charAt(1); //returns the character at position 1 from str
ch3 = str.charAt(2); //returns the character at postion 2 from str

//print each character
console.log("The user entered "+ch1 + ch2 + ch3);
`} /> 
<p>
  Output:
</p>
<CodeBlock notCode code={`
s 5   <-User Input
The user entered s 5 
`} />
<br/><br/>
    <p>Working with characters can be tricky for new students. 
      This is because whitespace characters (tabs, newlines, spaces, etc.) all 
      count as individual characters. In addition, digits like 1, 2, and 3 also 
      have a character representation. The character 5 is not the same as the integer 
      5. In fact, characters do hold an integer value. The integer value is normally the 
      ascii value of the character. Each character has an asigned ascii value and can be 
      casted as an integer. You can also cast an ascii value to a character if you need that 
      character. Looking at an ascii chart, you also see that the character 'X' is different from 
      'x'. All of these factors tend to make learning about characters a large task. Sometimes 
      students understand characters more after progressing to Arrays and Strings. Strings are 
      generally easier for new students because the specifier(%s) causes scanf to be whitespace 
      tolerant, similar to what we saw with numbers.
    </p>

    <p>
      There are many specifiers a developer could use in console.log() because there are 
      many data types. These lessons are focused more towards new students and will mostly cover
      int, float, and char.
    </p>
    </>
  );
}
export default IO;
