import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Hello() {
  return (
    <>
      <TopHeader title="Hello World" />
      <p>
        Create a new .c file called hello.c and insert the following code.
      </p>
      <CodeBlock
        code={`
#include <stdio.h>
int main()
{
  printf("Hello, World\\n");
  return 0;
}
`} />
      <p>
        Then compile your code and run the program.
        If Everything went smoothly you should see something similar to the output below.
      </p>
      <CodeBlock notCode code={`
Hello, World
`} />
      <p>
        Let's break it down.
      <br />

        <strong>{`#include<stdio.h>`} </strong>- This allows use of the printf() function. It includes many
        other functions as well. You will learn some of them later.
      <strong><br />{`int main()`} </strong>- Every C program has a main function. The program executes code that
      the programmer writes. The program starts at the {`'{'`} after the main function and ends
      at the {`'}'`} that matches the starting brace. The int in the front of main is called the
        return type of the function. This means the function will return an integer to wherever
        it was called. More on this will be discussed in the functions section.
      <br /><strong>{`printf(" ");`} </strong>- This function prints to the screen everything within the quotation marks.
      <br /><strong>{`\\n`} </strong>- This backslash n didn't print to the screen! In C, the code must specify when newlines
      are inserted. The {`\\n`} is how we tell the program to move to a newline. Just try taking it out
      then recompile your program and run it again. Notice the difference?  You can use {`\t`} to insert
        tabs into your print statements as well.
        <br /><strong>{`return 0;`} </strong>- This return statement is optional in the main() function and will be discussed 
        in the functions section. For now, you should know that the return statement ends the current function (
          which is main() in the above code). If the main() function ends, then the program ends. Any code after
          the return statement will not execute since code executes in order, one step at a time.
    </p>

      <br />
      <p>
        In addition, notice that every open {`'('`} has a corresponding closing {`')'`}. Similarly, every open {`'{'`}
        also has a crresponding closing {`'}'`}. You will have to make sure your parentheses and curly braces match up.
        It's also the same for square braces [] and other grouping markings.
    </p>
      <p>Every C statement ends with a semicolon(;) so some white space in your code doesn't really matter.
        Take a look at the variations below. They both will have the same output.
       </p>
      <Row>
        <Col>
          <CodeBlock code={`
#include <stdio.h>
int main(){
printf("Hello, World\\n");
printf("Bye, World\\n");
}
`} />
        </Col>
        <Col>
          <CodeBlock code={`
#include <stdio.h>
int main(){

  printf("Hello, World\\n");

  printf("Bye, World\\n");
}
`} />
        </Col>
        <Col>
          <CodeBlock code={`
#include <stdio.h> 
int main(){printf("Hello, World\\n");
printf("Bye, World\\n")}
`} />
        </Col>
      </Row>
      <p>
        This allows programmers to space out code to make it more readable. Comments 
        can also be used to keep notes or give other developers clues about what 
        the program is trying to accomplish. There are two ways to comment in C.
        The first is the multi line comment and the second is a single line comment.
        Comments are completely ignored by the compiler and will not effect the executable 
        program. Both types of comments are shown below. 
      </p>
      <CodeBlock code={`
/*this is a multi line comment. Anthing between 
the '/*' and '*\\' will be ignored */
#include <stdio.h>

/* the main function always runs first */
int main()
{ 
  //This is a one line comment.
  printf("3\\n");
  //Anything after these '//' is ignored
  printf("2\\n"); 
  
  printf("1\\n");
  printf("0\\n"); 
  printf("Bye\\n"); //The program is finished
  return 0;
}
`} />
<p>Output:</p>
<CodeBlock notCode code={`
3
2
1
0
Bye
`} />
  <p> 
    The program started at the main() function and executed statements one at a time 
    until the program ends. This is an important concept in programming and will be 
    important to you as a programmer.
    <br/>
    This page just showed basic functionality. More detail about printing to the 
    screen will be shown in the Input/Output lesson. Before moving on,
    why not try building a program to print your own name, today's date, and 
    your favorite color to the screen on different lines.
  </p>
    </>
  );
}

export default Hello;
