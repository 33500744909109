import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Macros(){
  return (
    <>
      <TopHeader title="Macros" />
      <p>
      Macros 
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default Macros;
