import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Strings(){
  return (
    <>
      <TopHeader title="Strings (Charactar Arrays)" />
      <p>
      A String is actually just a character array. Each character is stored in an element in the array. 
      Below shows a sample program that initializes the same string in different ways.
      </p>
      <CodeBlock code={`
"use strict" 

var x1 = "shoCODE"
var x2 = ('s','h','o','C','O','D','E',' ');
var x3 = ('s','h','o','C','O','D','E',' ');
var x4 = "shoCODE";
`}/>
<br/>
      <h3><strong>String Input/Output</strong></h3>
      <p> The split() function can be used to scan strings to scan in strings. In this case, will read a characters 
        into a string until it sees whitespace(newline, tab, space, etc.). This next program shows how you can scan in strings
        as a character array.
      </p>
      <CodeBlock code={`
//Prompt the user for their name as a string
let name = prompt("Enter Your Full Name: ");
 
//Split full name into first and last name.
let firstName = name.split(" ")[0];
let lastName = name.split(" ")[1];
 
//Print first and last name statement.
console.log("First Name: " + firstName + " ");
console.log("Last Name: " + lastName + " ");
`}/>
<p> 
  Output: User enters sho\nCODE
</p>
<CodeBlock notCode code={`
sho     <-User Input
CODE    <-User Input
First Name: sho
Last Name: Code
`}/>
<br/>
<p>
What if you needed to scan an entire line of words or characters including all it's whitespace. 
This can be accomplished by just not using the split() function. 
</p>
<br/>
<CodeBlock  code={`
const prompt = require('prompt-sync')();

let x;

//Prompt the user to enter a string
console.log("Enter a sentence with less than 100 characters: ");
    //Gets the input from the user
    x = prompt();

//Displays sentence
console.log("Your sentence is below: ");

//Prints string
console.log(x);
`}/>
<br/>
<p>Output:</p>
<CodeBlock notCode code={`
Enter a sentence with less than 100 characters:
shoCODE is AWESOME!         <-User Input
Your Sentence is below:
shoCODE is AWESOME!
`}/>
    </>
  );
}
export default Strings;
