import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Variables() {
  return (
    <>
      <TopHeader title="Variables" />
      <p>
      You can think of variables as containers or boxes that can hold only one item. 
      The type of an item is called a <strong>data type</strong>. 
      In Python, sometimes we need to tell the program what data type a variable 
      can hold. There are many data types but for now, let's focus on the three 
      data types shown below.
      <br />
        <br />
        <strong>Data Types</strong>: <br/>
        <strong>int</strong>  - this an integer (whole number like 1, 2, 3, -5, -10, etc ...)
      <br /><strong>float</strong> - this is a decimal number (1.5, 2.8, 7.4, 9.1, etc ...)
      <br /><strong>str</strong>  - this is a string of characters. ("Hello", "Hello5", "SHOCode7")
      <br />
        <br />
        You've seen strings in the previous lesson. "Hello World!" is a string.
        Python will automatically set variables to a particular data type
        simply by inferring what our stored data type is. For example, here's a 
        short program.
      </p>



      <CodeBlock code={`
x = 1024  # x holds the integer 1024
y = 3.14  # y holds the float 3.14
z = 'hello5'  # z holds string 'hello5'
`} />
      <p>
        Variables can be named with almost any name. Above, we use 'x', 'y', and 'z' as variable names but
        You can use names like 'x_is_an_integer', 'characterx', or 'num5'. Sometimes it's best to use names that make sense for your specific problem.
        For example a coin counting program migh have the variable name 'coinCount'. The only names you cannot 
        use are keywords already taken by the Python language. For instance you cannot name your 
        variable int, float, or char. Why? Well, we don't want to change the meaning 
        of those keywords, so an error will be shown when compiling.
</p>
<br/><br/>
<p>
  A common mistake is to think a variable can hold more than one "thing". Remember that
  they can only hold one at a time. So if I assign another value to the variable (using the =), 
  that variable will hold 
  a new value. Consider the code below.
</p>
<CodeBlock code={`
"""
When creating variables, only characters 
can be used as the start of the variable 
name. For example, 3x cannot be a variable.
"""
x = 5     # at this line x holds the number 5, and x is an integer
x = 10    # x no longer holds 5, it holds 10, and is an integer

`} />
<p>
  <strong>Pop Quiz</strong> - What will the above program print to the screen if we compiled and 
  executed the program? The correct answer is nothing! There are no print statements 
  in the program so nothing will be printed to the screen. The next lesson will show you 
  how to print a variable to the screen. The following code just shows some different ways 
  that we can declare and initialize variables.
</p>
<CodeBlock code={`
x = 5   # declares & initializes x to hold 5, x is an integer
y = 0   # declares & initializes y to hold 0, y is an integer

a = b = 9.8   # a and b both hold 9.8, and both are floats

y = x     # y now holds 5
b = 3.14  # b now holds 3.14
a = b     # a now holds 3.14

# the 3 lines below store different names in each variable
# each name variable below is holding a string.
name1 = 'Chris'
name2 = 'David'
name3 = 'Emily'

`} />

    </>
  );
}

export default Variables;
