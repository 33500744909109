import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Cast(){
  return (
    <>
      <TopHeader title="Type Casting (Type Conversions)" />
      <p>
Type casting allows us to change the data type of a variable for one line of code.
This can be useful as programmers often need to sometimes treat variables differently
to accomplish certain tasks. It's actually a very simple operation. You'll see more use
cases in future examples and larger examples. For now let's just learn the syntax and 
how to use it. A sample code is shown below.
</p>
<CodeBlock code={`
"use-strict"

let x = 9.8; 
//here we print x but we call parseInt() to cast it as an integer.
console.log("the value is " + parseInt(x));
`} />
<p> Output:</p>
<CodeBlock notCode code={`
the value is 9
`} />
<br/>

<p>
 There are many other uses for typecasting. Often 
  it's easier to temporarily change a character's type instead of storing two different 
  types of the same information. You will definitely see more use cases as you build larger 
  programs. It's important to note that if you use parseInt() or parseFloat(), it only changes 
  the data type for that one line. Another example is given below.
</p>
      <CodeBlock code={`
"use strict"

let x = 6;      
let y = 2.5;        
let a;              
let b;              

a = x/y;        
b = (x)/parseInt(y);
console.log("a = ", a); //Notice how a and b are different here
console.log("b = ", b);
console.log("x:", x, "y:", y); // x and y remain the same
`}/>
<p>Output:</p>
<CodeBlock notCode code={`
a =  2.4
b =  3
x: 6 y: 2.5
`} />

<p>
  We see that we were successfully able to change the type of x and y for one line only.
  Using x and y later in the print statement as integers worked and those variables
  retained their orignal integer values. 
</p>
    </>
  );
}

export default Cast;
