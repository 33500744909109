import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Recursion(){
  return (
    <>
      <TopHeader title="Recursion" />
      <p>
      Recursion happens when a function calls itself. That might sound a little weird but it 
      does have it's uses. If a problem can be broken down into a very small repetitive process, 
      that would make the problem a perfect candidate for recursion. Some languages are better 
      suited to handle recursion but it should be introduced so you know what it is. The program 
      below is another factorial program. We do this using a function but notice that there are 
      no loops. In previous examples, loops are needed to multiply values over and over again.
      with recursion, no loops are needed since the function calls itself as many times as it needs.
      </p>
      <CodeBlock code={`
import java.util.Scanner;

public class recursion
{
    public static void main(String[] args)
    {
        int n, result;
        System.out.println("Enter an Integer");
        Scanner input = new Scanner(System.in);
        n = input.nextInt();
        
        result = Factorial(n);
        System.out.println(n + "! = " + result);
        
        System.exit(0);
    }

    public static int Factorial(int n) 
    {
        if (n > 1) 
            return n*Factorial(n-1);
        else 
            return 1;
    }
}
`}/>
<br/>
<p>
  Try the above program with the number 2 and then the number 3. It will take some getting used 
  to in order to start identifying the problems that can be solve like this. You can always think 
  of problems that need while or for loops. If the process is simple enough and almost identical,
  you should be able to find a way to program recursively instead.
</p>
    </>
  );
}

export default Recursion;
