import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Setup(){
  return (
    <>
      <TopHeader title="Setup - How To Compile & Run" />
      <p>
        If your Environment is already set up and you know how to run programs, then 
        you can skip this section.
        </p>
        <br/><br/><p> 
        There are two options for setting up your system for all the starter languages on 
        this site.
      </p>
      <br/>
      <p>
      1. Use shoCODE's All-In-One (AIO) starter package (Link Here)
      <br/>2. Set up your own Environment for the language you want. (Link Here)
      </p>
      
      <br/><p>
      The shoCODE Package is a Linux environment. Since programs are nothing but text 
      you can choose any text editor you want. Just make sure your files have the right 
      extension for the language you are learning.
      </p>

      <p>
        C programing uses the .c extension. In the shoCODE AIO, you can compile on the
        command line by using the gcc command.
      </p>
      <p>
        For example, you can create a .c text file named hello.c and fill it in with the 
        following code:
      </p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  printf("Hello, World");
}
`}/>
      <p>
        
      </p>

      <p>Save the file and make sure you stay in the same folder as the saved file.
        You can then compile the file by typing the following command in the command line.
      </p>
      <CodeBlock code={`
gcc hello.c        
`}/>
      <p>
        You should now see that an executable file named a.out was created in your current folder.
        Finally, you can run by using the command shown below.
      </p>
      <CodeBlock code={`
./a.out        
`}/>
      <p>
        One simple improvement we can make right of the back is the ability to rename the a.out file
        to whatever we want. For instance say we wanted to rename are executable from 'a.out' to 'hi'.
        The commands to compile and execute would change to this.
      </p>
      <CodeBlock code={`
gcc hello.c -o hi
./hi
`}/>
      <p>
        If all went well, you should see Hello, World printed to the screen followed by your program 
        ending. Now, don't go and skip the next lesson. The Hello World lesson will go more in detail 
        about the code and the nuances of printing to the screen.
      </p>
    </>
  );
}

export default Setup;
