import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Output() {
  return (
    <>
      <TopHeader title="Output" />
      <p>
        Now you know how to declare variables and store information in those variables.
        What can we do with variables? We can manipulate them, print them to the screen,
        and even store input from a user. This lesson we will focus on printing a
        variable's contents to the screen and storing user input into a variable.
        To print a variable to the screen, we will still use the print function. 
      </p>
      <br/><br/>
      <p>
       Let's start with something very simple by redoing the hello world program using a varible.
       Check out the code below. Notice that we can simply print variables contents to the screen by using
       the print() function.
      </p>
      <CodeBlock code={`
greeting = "Hello World!" #this stores the string into the variable greeting
print(greeting) # this prints the contents of greeting to the screen.

#Note: this is not very different from print("Hello World!") learned in the first lesson.
`} /><p>
        Output:
</p>
      <CodeBlock notCode code={`
Hello World!
`} />
      <p>  
        Now that you know how to print the contents of a variable to the screen, let's try it out with other data types.
        The program below is the exact same program from the last lesson but has the addition of print
        statements. See if you can guess the output before looking at the output below.
      </p>
      <CodeBlock code={`
x = 1024 
y = 3.14
z = 'hello5'

# printing to the screen
print("the value in x is", x)
print("y = ", y)
print(z, "is stored in z")

# Print Multiple Variables to the screen in the same line
print("x:", x, " y:", y, " z:", z)
`} />
<p>
        Output:
</p>
      <CodeBlock notCode code={`
the value in x is 1024
y = 3.14
hello5 is stored in z
x: 1024 y: 3.140000 z: hello5
`} />
<p>
        Note that we can use this print statement to test our programs. We can make sure
        the code is doing what we expect. Consider the following code from the last lesson.
        By adding in print statements we can see what the program is executing in order.
</p>
<CodeBlock code={`
x = 5 # declares and initializes x to hold 5
y = 0 # declares and initializes y to hold 0
a = b = 9.8 # a and b both hold 9.8

print("x:", x, " y:", y, " a:", a, " b:", b)
y = x # y now holds 5
print("x:", x, " y:", y, " a:", a, " b:", b)
b = 3.14 # b now holds 3.14
print("x:", x, " y:", y, " a:", a, " b:", b)
a = b # a now holds 3.14
print("x:", x, " y:", y, " a:", a, " b:", b)
`} />
<p>
        Output:
</p>
      <CodeBlock code={`
x:5 ,y:0 ,a:9.800000 ,b:9.8000000
x:5 ,y:5 ,a:9.800000 ,b:9.8000000
x:5 ,y:0 ,a:9.800000 ,b:3.1400000
x:5 ,y:0 ,a:3.140000 ,b:3.1400000
`} />
<p>
      If you had a hard time with the above program remember that instruction run 1 at a time from top to bottom. So try tracing through the program
      one instruction at a time and you should be able to understand why the output is the way it is.
      If you are still struggling with the last program or the output is not what you expected, then this is a good time to ask your
      instructor for help.
</p>
      <br />
      <br />
      <p>  
        To help understand data types just a little bit more, you can also use the type() function. The type() function takes a variable as 
        an input and the output shows what data type the variable is (int, float, or string for now). We can use this in combination with the print 
        function to investigate variables and data types. Here is some example code.
      </p>
      <CodeBlock code={`
x = 1024 
y = 3.14
z = 'hello5'

# printing to the screen
print("x is", type(x))
print("y is ", type(y))
print("z is ", type(z))

`} />
<p>
        Output:
</p>
      <CodeBlock notCode code={`
x is <class 'int'>
y is  <class 'float'>
z is  <class 'str'>
`} />
<p>
        The output confirms that x is an int, y is a float, and z is a str.
</p>

    </>
  );
}
export default Output;
