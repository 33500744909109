import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Hello() {
  return (
    <>
      <TopHeader title="Hello World" />
      <p>
        Your first program is to give a very simple instruction to the computer. The goal is to make the computer
        print out the phrase "Hello World!" to the screen. We can accomplish this in python by using the print() function.
        The print() function will display whatever is between the prenthesis. An example is shown below.
      </p>
      <CodeBlock
        code={`
print("Hello World!")
`} />
      <p>
        You can type this program into your code editor and run it. After running the program the output should be displayed as shown below.
      </p>
      <CodeBlock notCode code={`
Hello World!
`} />
      <p>
        If you get an error, or the output doesn't display correctly, first make sure you typed it exactly as it is above.
        If that still doesn't fix the issue, then ask your Instructor.
        Notice that anything that is typed between the two quotation marks will be printed to the screen.
        <br /><br />

        Let's try using the print() functions twice in one program. Take a look at the program below. Can you guess what the output will be
        when the program runs?
      </p>
      <CodeBlock
        code={`
print("Hello World #1")
print("Hello World #2")
`} />
      <p>
        The output to the above program is shown below. Did you guess it right?
      </p>
      <CodeBlock notCode code={`
Hello World #1
Hello World #2
`} />
      <p>
        The last program is a program with 2 statements or 2 instructions.
        The instructions don't run at the same time. Every program will
        run one instruction at a time. It will start from the top and work it's way down each instruction until
        there are no instructions left.
      </p>
    </>
  );
}

export default Hello;
