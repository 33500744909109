import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function Learn() {
  return (
    <>
      <TopHeader title="How To Learn to Think? How to figure things out?" />
      <br />
      <p>
        Thinking like a computer doesn't normally come naturally to all people.
      </p>
    </>
  );
}

export default Learn;
