import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Rand(){
  return (
    <>
      <TopHeader title="Random Number Generation" />
      <p>
      Random number generation is useful in programming. Python can generate random numbers several 
      different ways by importing the random module. A variety of ways to generate random 
      numbers is shown below.
      </p>
      <CodeBlock code={`
"""
Python can generate random numbers several different ways by importing the random module.
"""

import random as r

print(r.random())   # random() generates a random float between 0 and 1
print(r.choice([10,5,2,176,7,12,51,56,1,]))   # randomly chooses a number from the set list
print(r.randrange(1,11))    # generates a random integer between 1 and 11
print(r.randrange(1,11,2))  # generates a random integer between 1 and 11, skipping every second integer
print(r.uniform(1,11))      # generates a random float between 1 and 11
`}/>
<br/>
<p>
    You can use any of the above examples to choose how you want to generate random numbers. 
    The output of the program above should be different each time you run it.
</p>
    </>
  );
}

export default Rand;
