import app from "firebase/app";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC9vkCTAcviqtYQu_L2BqqRPIPok_ou6RM",
  authDomain: "shocode-7fe2b.firebaseapp.com",
  databaseURL: "https://shocode-7fe2b.firebaseio.com",
  projectId: "shocode-7fe2b",
  storageBucket: "shocode-7fe2b.appspot.com",
  messagingSenderId: "451552623799",
  appId: "1:451552623799:web:143b146f0cb484159f2132",
  measurementId: "G-D3RP8Y4VF1"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.database = app.database();
  }

  // *** AUTH API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** USER API ***
  user = uid => this.database.ref(`users/${uid}`);
  users = () => this.database.ref("users");
}
export default Firebase;
