import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG5(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Pointers Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a function to swap two floats given the pointers of two float variables. 
        The main function should allow the user to input two floats. The first number should be stored in a variable 
        named x and the second number in a variable named y. After calling the swap function, the variable values 
        should be switched and displayed to the screen. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>

void swapf(float *a, float *b);

int main(){
  //Declare Variables
  float x, y;
  printf("Enter two float values: \\n");
  scanf("%f %f", &x, &y);
  swapf(&x, &y);
  
  printf("x: %f, y: %f \\n", x, y);
  
  return 0;
}

void swapf(float *a, float *b){
  float temp;
  *a = temp;
  *a = *b;
  *b = temp;
  return;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program to sum the values in an array of length 5. The user should assign all 5 integers in the array.
        All access to array elements should be done with pointers instead of the subscript operator.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  int sum, x[5];

  /*user inputs values into the array */
  for(int i = 0; i<5; i++){
    printf("Enter Integer %d\\n", i);
    scanf("%d", (x+i));
  }
  /*calculate the sume */
  sum = 0;
  for(int i = 0; i<5; i++){
    sum += *(x+i);
  }
  printf("The sum is %d\\n", sum);
  return 0;
}
`}/>
    </Collapse>
    </>
  );
}

export default EG5;
