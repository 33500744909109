import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function New(){
  return (
    <>
      <TopHeader title="Dynamic Memory Allocation: Malloc and Free" />
      <p>
      New and Delete are ways to dynamically allocate memory. When you create an array of variable like 
      int x[100], the program allocates all the space needed when the program starts. This can be inefficient since 
      you might not actually need all that space. For example, what if we had to build a program that would take 
      in prices for items and store them in an array. The program should print the sum of all those items to the screen.
      This is a simple shopping cart. If we use int prices[100] and the shopper only picks 5 items, then we are also 
      holding on to 95 other blocks of memory that aren't being used. That unused memory is locked to this program and 
      no other programs can use it. It would be nice to be able to allocate only the memory that we need. Below is the 
      simple shopping cart example, using the new and delete to only use the exact amount of memory needed. The 
      new keyword returns a pointer to an array that is created. The pointer is the same type as the variable used. 
      In this case we are using new float.
      </p>
      <CodeBlock code={`
#inlcude<iostream>
using namespace std;

int main(){
  float sum = 0;
  float *prices; //Just a float pointer
  /* prices will later point to the new memory created by new */

  int numOfItems;
  //Get the number of items for the array from the user
  cout << "Enter the number of Items in your shopping cart" << endl;
  cin >> numOfItems;

  //let's declare enough space for the number of items entered
  prices = new float[numOfItems];
  
  /* prices is now a pointer to a block of memory for float values */
  /* We can access with the pointer or subscript operations like arrays */

  for(int i = 0; i< numOfItems; i++){
      cout << "Please Enter the price for item " << i+1 << endl;
      cin >> prices[i]; 
      /* or - cin >> *(prices+i); */
  }

  for(int i = 0; i< numOfItems; i++){
    sum += prices[i];
  }

  cout << "Your Total is: " << sum << endl;

  /* we must free the memory if we dynamically allocated it */
  delete [] prices
  return 0;
}
`}/>
<br/>
<p>
  Using delete to free memory is a good practice. It should be done. This allows the system to retake 
  control of the memory when your done with it. If the program closes without freeing dynamically 
  allocated memory, this is known as a memory leak. If this memory never get's freed and we keep allocating 
  new memory, it could cause a system to run out of memory. Now, It's important to note that most modern operating 
  systems will do some sort of clean up and not allow that to happen. Developers should never depend on this clean up 
  and should always free dynamically allocated memory.
</p>
    </>
  );
}

export default New;
