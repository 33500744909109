import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function ArgsToMain(){
  return (
    <>
      <TopHeader title="Arguments To Main" />
      <p>
      The main() function is a funtion like any other function. As a function, parameters 
      can be passed to the function. Check out the example below on how we can pass arguments 
      to main. A grasp on concepts like loops, arrays, strings, and pointers are needed for this. Each 
      argument can be entered on the command line seperated by spaces so each argument is treated as a 
      strin.
      The main function's first parameter is an integer. It's named argc in the example below. This 
      gives us a count of how many arguments have been passed to the main function. The argv variable 
      below is the more complicated one. It holds a pointer for each argument because each argument is 
      a string. The below example shows how to recieve basic arguments.
      </p>
      <CodeBlock code={`
#include<stdio.h>

int main(int argc, char *argv[]){
        printf("The arguments are \\n");
        for(int i = 0; i<argc;i++)
                printf("%s\\n",argv[i]);
        return 0;
}
`}/>
<p>Ouput: Assume the name of the program is a.out</p>
<CodeBlock notCode code={`
./a.out -p -r debug force release -x     <- User runs a.out with the following arguments
The arguments are
./a.out
-p
-r
debug
force
release
-x
}
`}/>
<br/>
<p>
  You can see above that the very first argument is the name of the program itself. Every other argument is listed in the order it was entered. 
  This is how you can build programs with different functionality depending on how the user wants to use the program. One example might be to 
  build a program that check if the user enters the debug arguemnt. This argument is tied to an option that let's extra print statements run to 
  give the user more information as it's executed. 
</p>
<br/>
<p>
  An example program is shown below is a simple template on how you can possibly use some flow control based on main() arguments.
</p>
<CodeBlock code={`
#include<stdio.h>

int main(int argc, char *argv[]){
        if(argc == 1){
          /* no arguments were entered */
          /* set variables to the program normally */
          
        }else if( argc > 1){

          /* arguments were entered */
          /* process all arguments */
          /* set flags and variables to run the program with arguments */
        }

        /* The program executes here */
        /* If/esle, swtich, and other control can be used */
        /* To Control Flow of the Program based on arguments entered */

        return 0;
}
`}/>
    </>
  );
}

export default ArgsToMain;
