import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function SepComp(){
  return (
    <>
      <TopHeader title="Seperate Compilation" />

      <p>
      Separate compilation is pretty much the same on all systems. The only difference might be 
      in compiling. This section will discuss how to seperate function into files and how to 
      compile the seperate files together using GNU on Linux. Again, seperating functions into files 
      will be the same no matter which system you are compiling on.
      </p>
      <br/>
      <p>
        To separate functions to different files, you should understand what a function is 
        and what a function prototype is. Below is a program taken from the functions lesson. This 
        example will be used to show how to seperate functions into seperate files.
      </p>
<CodeBlock code={`
/* This is normally your only .cpp file */
#include<stdio.h>
void printhello(void); <- This will go into function.hpp

int main(){           <- This whole section will go into main.cpp
    printhello();
    return 0;
}

void printhello(void){   <- This whole section will go into function.cpp
  cout << "Hello, World!" << endl;
}
`}/>
<br/>
<p> The above function is normally your only .c file. Suppose the files is named name.c. You might 
  compile such a file using the 'gcc name.c' command. We can seperate this file into 3 different files.
  The names are choosen arbitrarily. These files are main.c, function.c, and function.h. In addition, 
  a new #include statement must be added to include the prototype. The .h file is called a header file.
  those are the files we normally include, such as stdio.h. The seperation is shown in the 3 files below.
</p>
<CodeBlock code={`
/* function.hpp file */
void printhello(void);
`}/>
<br/>
<CodeBlock code={`
/* function.cpp file */
void printhello(void)
{
  cout << "Hello, World!" << endl;
}
`}/>
<br/>
<CodeBlock code={`
/* main.cpp file */

#include <iostream>
#include "08_seperate_function.hpp" /* This line was added*/

using namespace std;

int main()
{
  printhello();
  return 0;
}
`}/>
<br/>
<p>
  If you trace the parts that moved closely, you'll see that it wasn't that bad. The prototype is 
  actually located in the main program through the #include "function.h" line. That means the main 
  function can use the printhello() function. Now why do we use quotation marks instead of the less 
  than and greater than brackets in the include. Well, If you use the brackets, this tells the program 
  that the functions you want to use are located in a special directory. Your compiler knows how to 
  find that special directory. If you use quoatation marks, then the compiler knows that this is a user 
  defined function and that the user will include any code needed for the function prototypes in the 
  header file.
</p>
<br/>
<p>
  Compiling becomes slightly different. Instead of 'gcc name.c', we would use the 'gcc main.c 
  function.c' command. Remember since the #include had quotation marks, the compiler is expecting 
  the user to add in the extra code needed for the functions so it must be included. If you don't add 
  the extra file, containing the function code, the compiler will not be able to find the code for the 
  function and the developer will get a linking error.
</p>
<br/>
<p>
  Normally, many prototypes are added to the .h files and all their corresponding function will go in 
  the corresponding .c file. In fact, you have already experienced this. The #include{'<stdio.h>'} includes 
  prototype for the printf() and scanf() functions. We don't need to compile with the corresponding .c because 
  of the {'< >'} brackets. Remember, we only need to compile with extra code when we use quotation marks since 
  that means it is a user defined file. So now, you finally know what that #include{'<stdio.h'} is.
</p>

<p>
  Developers should also not group their functions randomly. They should group them according to use. 
  For example the math.h library contains many headers for math specific functions and the stdio or 
  standard input output library contains many headers for functions realated to IO. Try to bundle your 
  user defined functions in bundles that make sense.
</p>
<br/>
<p>
  The last thing to note about seperation is that it actually doesn't help us manage larger programs 
  efficiently yet. What it does enable is something called makefiles. Makefiles are specific to the 
  linux operating system. Using Makefiles with the knowledge of seperate compilation can help developers 
  manage larger programs very efficiently. There is a makefile lesson in the the linux section. Makefiles 
  are not language specific and you can use a makefile to compile and run programs in different languages.
</p>
    </>
  );
}

export default SepComp;
