import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Arrays(){
  return (
    <>
<TopHeader title="Arrays" />
      <p>
        Arrays are one of the most usefule data type to programmers. Why is this only being introduced
        now. To work with and make full use of array, you will need to apply much of
        your previous learning. If you can master arrays, you will definitely be able to build
        useful programs.
      </p>
      <br />
      <p>An array is a group of variables in memory that are all the same data type. Consider
      a problem where you need to declare 100 variables. Declaring that many variables will
      take a considerable amount of time and space in you code. We can accomplish with the
      variable declaration shown below.
      </p>
      <CodeBlock code={`
/* Declares 100 integer variables */
int x[100]; 

/* x[0] is the first int */
/* x[1] is the second int */
/* ... x[99] is the last int */
`} />
      <br />
      <p>
        The above code declares an array of 100 integers from x[0] to x[99]. The square brackets
        are called the subscript operator and are used to access each of the array elements.
        It starts at subscript 0. 100 integers means it must end at subscript 99. The image below
        illustrates how you can think of an array that stores 5 ints and how to access each element.
      </p>
      <br />
      <img
        alt="..."
        className="code-image"
        style={{ width: "20%" }}
        src={require("assets/mimg/code/c/array1.png")}
      />
      <br />
      <p>
        Another advantage to using arrays is now we can store data in these arrays and process
        the data whenever we want. For example, a program you may have come across in these lessons
        is to calculate the average of integers input by a user. We can calculate this by summing
        all the ints while keeping count. Then we would divide the sum by the count. But what if we
        wanted to access the data again? We couldn't, and we would have to ask the user for the orignal
        data input again. With arrays we can save that data and process that data when we choose. The example
        below builds the averager program with arrays.
      </p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  int x[3];
  int avg, sum, i;

  //get three numbers from the user
  //and store in the array
  for(i=0; i<3; i++)
  {
    cout << "Enter an Integer:" << endl;
    cin >> x[i];
  }
  //Now all the user's data is in x
  //calculate the average using the data
  sum = 0;
  for(i=0; i<3; i++)
  {
    sum += x[i];
  }
  avg = sum /3.0;
  cout << "the average is " << avg << endl;
}
`} />
      <p>
        It may not be apparently useful in the above situation. But consider a program where we ask
        the user to input data. Then display a menu to the user asking what they want to do with the data.
        The user could ask for the sum, average, smallest, largest, etc. After the user selects an option
        the program can calculate what's needed using the data in the array. The program can then display
        the options in the menu again and any calculations can be performed when the user requests it. Below,
        is a program that does exactly that but the data type is float instead of int. Arrays can be declared
        with any valid data type.
</p>
      <p>
        The program below asks the user for 10 numbers. It then displays a menu to the user with the
        following options: Enter 0 to quit, 1 to display the averag, or 2 to dsiplay the largest value.
        This program uses a uses the following: for loops, a do while loop and a switch statment.
</p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  float x[10], temp;
  int option;
  for(int i = 0; i < 10; i++)
  {
    cout << "Please Enter a Value:" << endl;
    cin >> x[i];
  }
  do{
    cout << "Options(0-quit, 1-average, 2-smallest" << endl;
    cin >> option;
    switch(option)
    {
      case 0: break;
      case 1:
          temp = 0;
          for(int i = 0; i< 10; i++)
          {
            temp += x[i];
          }
          cout << "The average is " << temp/10.0 << "." << endl;
          break;
      case 2:
          temp = x[0];
          for(int i = 1; i<10; i++)
          {
            if(x[i]<temp)
            {
              temp=x[i];
            }
          }
          cout << "the minimum value is " << temp << "." << endl;
          break;
      default:
          cout << "That is not a valid Option" << endl;
    }

  }
  while(option!=0);
}
`} />
      <p>
        It's also possible to store 2D arrays. This is accomplished by using two subscript operators.
  </p>
      <CodeBlock code={`
  int x[3][3]; //creates a 2D array with 3 rows and 3 columns.
`} />
      <p>
        This is pretty similar to storing data in a matrix. To access the array elements, use the
        subcript operator twice to access each row and each column. There is an image below, illustrating
        the differences between a 1D Array and a 2D array.
</p>
      <br />
      <img
        alt="..."
        style={{ width: "30%" }}
        className="code-image"
        src={require("assets/mimg/code/c/array2.png")}
      />
      <br />
      <p>
        This can be further tested by using the program below. The program sets up a 5x5 2D array.
        The program uses a nested for loop to count and store the count in each element. Finally,
        another nested for loop is used to print out the 2D array like a matrix.
      </p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  int x[5][5];
  int count = 0;

  //Count Each Cell
  //Each cell stores it's count
  for(int i = 0; i < 5; i++)
  {
    for(int j = 0; j < 5; j++)
    {
      x[i][j] = count;
      count++;
    }
  }

  //print out the array like a matrix
  for(int i = 0; i < 5; i++)
  {
    for(int j = 0; j < 5; j++)
    {
      cout << x[i][j] << '\t';
    }
    cout << endl;
  }
}
`} />
      <p>Output: </p>
      <CodeBlock notCode code={`
0   1   2   3   4
5   6   7   8   9
10  11  12  13  14
15  16  17  18  19
20  21  22  23  24
`} />
      <br />
      <p>
        It is possible to support larger dimension arrays such as 3D, 4D, and so on. It is important to know that you can 
        create arrays of any data type. When creating an array of characters, it is called a string. Check out the strings lesson 
        for more info. There is also anther way to access variables and to loop through strings. Up until now you have been using 
        the assignment operator (which is the = sign) to directly access variable. You can also access variables through pointers. 
        By creating an array, you are also creating a pointer. Check out the Pointers Lesson for more on that.
      </p>
    </>
  );
}

export default Arrays;
