import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";
import Editor from "../../../components/Editor/Editor.js";
import Compiler from "../../../components/Compiler/Compiler.js";
import CodeEditorWindow from "components/MonacoEditor/CodeEditorWindow.js";

function Online() {
  return (
    <>
      <TopHeader title="Develop Online with shoCODE" />
      <br />
      <p>
        The online code runner is probably the easiest way to start learning.
      </p>
      <br/><br/>
      <Compiler/>
      {/*<CodeEditorWindow />*/}
    </>
  );
}

export default Online;
