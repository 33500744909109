import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Rand(){
  return (
    <>
      <TopHeader title="Random Number Generation" />
      <p>
      Random number generation is useful in programming. We will include 2 new libraries instead of just stdio.h to use the rand() function.
      The rand() function returns a pseudo-random integer between 0 and RAND_MAX. Now RAND_MAX varies between implementation but is usually 
      a very large number. The srand() function is used to seed the rand() function with an integer. You can put any unsigned integer into the 
      srand function but in the example below use time(0). The time() function, if given 0 or NULL it will return the time since January 1, 1970
      at the time of writing this article. What maters it it returns an integer that changes. Which helps us seed the pseudo-random integers with 
      srand(). If srand() is not properly seeded, then a common problem is seeing the same random integer pop up over and over.
      </p>
      <CodeBlock code={`
#include<stdio.h>
#include<stdlib.h>
#include<time.h>

int main(){
  srand(time(0));
  int x = rand();
  printf("%d\\n", x);
}
`}/>
      <CodeBlock notCode code={`
1804289383      <-should be different for everyone and everytime you run the program.
`}/>
<br/>
<p>
  If you run the program multiple times, you should see a different integer popping out everytime. Right now this isn't very useful, but 
  we can make it pretty useful by using the modulus(%) operator. The modulus returns the remainder after division so we can limit the rand()
  functions output. If we have any number x and perfomr x%10. Then the operation will only return numbers 0 through 9. If there was a remainder 
  of 10 then the number can be divided again and the operation will return 0. See the basics section under math to review modulus (%).
  Below is a program that shows this.
</p>
<CodeBlock code={`
#include<stdio.h>
#include<stdlib.h>
#include<time.h>

int main(){
  srand(time(0));
  int x = rand() % 10;
  printf("%d\\n", x);
}
`}/>
      <CodeBlock notCode code={`
3      <-should be different for everyone and everytime you run the program.
`}/>
<br/>
<p>
  If you rerun the above program, the outputs should only be from 0 through 9. If you wanted to generate only numbers between 1 and 10 then we could 
  add 1 to every single number (rand()%10 +1) and now we have a pseudo-random number generator between 1 and 10. Below is a function to generate a 
  pseudo-random number between two integers named begin and end. The integer begin must be less than the integer end. If end is bigger, the function 
  will return 0.
</p>
<CodeBlock code={`
#include<stdlib.h>
#include<time.h>

int Rand_Between(int begin, int end){
  if(begin>end)
    return 0;
  
  int num;
  srand(time(0));
  num = (rand()%(end-begin+1)) + begin;
  return num;
}
`}/>
<p>
  The above function just generalized the algorithm used above to create randomm numbers between 1 and 10.
</p>
    </>
  );
}

export default Rand;
