import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Hello() {
  return (
    <>
      <TopHeader title="Hello World" />
      <p>
        Create a new .java file called hello.java and insert the following code.
      </p>
      <CodeBlock
        code={`
public class hello
{
  public static void main(String[] args)
  {
    System.out.println("Hello World!");
  }
}
`} />
<p>
  Then compile your code and run the program.
  If Everything went smoothly you should see something similar to the output below.
</p>
<CodeBlock notCode code={`
Hello, World
`} />
      <p>
        Let's break it down.
      <br />

        <strong>{`public class hello{ }`} </strong>- Every application begins with a class definition. This 
        class definition must be the same name as the file name. In this case, it's named hello.
        The code goes in between the curly braces.
      <strong><br />{`public static void main(String[] args)`} </strong>- This is called the main method.
      Every application in java needs a main method. The computer starts executing code starting at the main metthod.
      the programmer writes. The program starts at the {`'{'`} after the main method and ends
      at the {`'}'`} that matches the starting brace. You will learn the meaning of pulic, static, and void 
      in later lessons. For now, just include them in your programs.
      <br /><strong>{`System.out.println("Hello World!");`} </strong>- This function prints to the screen everything within the quotation marks.
    </p>

      <br />
      <p>
        In addition, notice that every open {`'('`} has a corresponding closing {`')'`}. Similarly, every open {`'{'`}
        also has a corresponding closing {`'}'`}. You will have to make sure your parentheses and curly braces match up.
        It's also the same for square braces [] and other grouping markings.
    </p>
      <p>A general template you can use while you are learning Java is shown below.
       </p>
        <Col>
          <CodeBlock code={`
public class HelloWorld {
  public static void main(String[] args) {
      // Write your code here
  }
}
`} />
        </Col>
      <p>
         There are two ways to comment in Java.
        The first is the multi line comment and the second is a single line comment.
        Comments are completely ignored by the compiler and will not effect the executable 
        program. Both types of comments are shown below. 
      </p>
      <CodeBlock code={`
/*this is a multi line comment. Anthing between 
the '/*' and '*\' will be ignored */

public class 02_comments
{
  /* The main() method is the the entry point to the application. */
  public static void main(String[] args)
  {
    // This is a one line comment.
    System.out.println("3");
    // Anything after the '//' is ignored
    System.out.println("2");

    System.out.println("1");
    System.out.println("0");
    System.out.println("Bye");  // The program is finished
  }
}
`} />
<p>Output:</p>
<CodeBlock notCode code={`
3
2
1
0
Bye
`} />
  <p> 
    The program started at the main() function and executed statements one at a time 
    until the program ends. This is an important concept in programming and will be 
    important to you as a programmer.
    <br/>
    This page just showed basic functionality. More detail about printing to the 
    screen will be shown in the Input/Output lesson. Before moving on,
    why not try building a program to print your own name, today's date, and 
    your favorite color to the screen on different lines.
  </p>
    </>
  );
}

export default Hello;
