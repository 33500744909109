import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function SepComp(){
  return (
    <>
      <TopHeader title="Seperating Functions" />

      <p>
      Separate compilation is pretty much the same on all systems. The only difference might be 
      in compiling. This section will discuss how to seperate function into files and how to 
      compile the seperate files together using GNU on Linux. Again, seperating functions into files 
      will be the same no matter which system you are compiling on.
      </p>
      <br/>
      <p>
        To separate functions to different files, you should understand what a function is 
        and what a function prototype is. Below is a program taken from the functions lesson. This 
        example will be used to show how to seperate functions into seperate files.
      </p>
<CodeBlock code={`
#the function is defined
def printhello():
  print("Hello, World!")

#the program start here.
printhello()
`}/>
<br/>
<p> The above function is normally your only .py file. Let's learn to seperate the 
  function into a separate file. This will be helpful with organization and will also keep 
  our programs small and easier to read.
</p>
<CodeBlock code={`
# function.py file 
#the function is defined
def printhello():
  print("Hello, World!")
`}/>
<br/>
<CodeBlock code={`
/* main.py file */
# the main.py file
from function.py import printhello

#the program start here.
printhello()
`}/>
<br/>

<p>
  If you run main.py you will see the printhello() function get's executed. Now we have two files.
  Anytime you need to use the printhello function you can just reuse your code and import it.
  This can be really handy when your functions become very large.
</p>
<br/>
    </>
  );
}

export default SepComp;
