import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Variables() {
  return (
    <>
      <TopHeader title="Variables" />
      <p>
      You can think of variables as containers that can hold only one item. 
      The type of an item is called a data type. 
      In Python, sometimes we need to tell the program what data type a variable 
      can hold. There are many data types but for now, let's focus on the three 
      data types shown below.
      <br />
        <br />
        <strong>int</strong>   - declares a container for an integer (whole number)
      <br /><strong>float</strong> - declares a container for a decimal number
      <br /><strong>char</strong>  - declares a container to hold a character
      <br />
        <br />
        Python will automatically set variables to a particular data type above 
        simply by inferring what our stored data type is. For example, here's a 
        short program.
      </p>



      <CodeBlock code={`
x = 1024  # x holds the integer 1024
y = 3.14  # y holds the float 3.14
ch = 'y'  # ch holds character y

"""
NOTE: We could also write x and y like below,
x = int(1024)  # x holds the integer 1024
y = float(3.14)  # y holds the float 3.14
This explicitly says x is an integer container
and y is float container.
"""

`} />
      <p>
        Variables can be named with almost any name. You can use names like 
        'x_is_an_integer', 'characterx', or 'num5'. The only names you cannot 
        use are keywords already taken by the Python language. You'll see more keywords 
        as you learn but you know some already. For instance you cannot name your 
        variable int, float, or char. Why? Well, we don't want to change the meaning 
        of those keywords, so an error will be shown when compiling.
</p>
<p>
  A common mistake is to think a variable can hold more than one "thing". Remember that
  they can only hold one at a time. So if I assign another value to the variable (using the =), 
  that variable will hold 
  a new value. Consider the code below.
</p>
<CodeBlock code={`
"""
When creating variables, only characters 
can be used as the start of the variable 
name. For example, 3x cannot be a variable.
"""
x = 5     # at this line x holds the number 5, and x is an integer
x = 10    # x no longer holds 5, it holds 10, and is an integer

`} />
<p>
  <strong>Pop Quiz</strong> - What will the above program print to the screen if we compiled and 
  executed the program? The correct answer is nothing! There are no print statements 
  in the program so nothing will be printed to the screen. The next lesson will show you 
  how to print a variable to the screen. The following code just shows some different ways 
  that we can declare and initialize variables.
</p>
<CodeBlock code={`
x = 5   # declares & initializes x to hold 5, x is an integer
y = 0   # declares & initializes y to hold 0, y is an integer

a = b = 9.8   # a and b both hold 9.8, and both are floats

y = x     # y now holds 5
b = 3.14  # b now holds 3.14
a = b     # a now holds 3.14
`} />

    </>
  );
}

export default Variables;
