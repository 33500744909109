import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function WhatNext(){
  return (
    <>
      <TopHeader title="What's Next" />
      <p>
      If you've finished all the topics on this page, you should be very proud of yourself. That 
      is definitely an accomplishement. There are a couple of things you can do. You could try out 
      some of the project examples in C. You can also start learning C++ and skip most of the basics.
      C++ offers different Input/Output functions but most of the other code you learned in C will be
      exactly the same as C++. In Fact, one of the first lessons in the C++ section is to go and learn 
      C programming first. What you'll be learning in C++ is an Object Oriented Programming Language.
      It's kind of like a struct.
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default WhatNext;
