import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
// core components

function ShoNavbar() {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main" color="dark">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/home" tag={Link}>
              shoCODE
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              Home
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle style={{fontSize:"x-small"}} className="mr-2" color="default" id="beginners" caret nav>
                  Beginners
                </DropdownToggle>
                <UncontrolledTooltip placement="bottom" target="beginners">
                  Learn From The Beginning.
                </UncontrolledTooltip>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/start" tag={Link}>
                    Start Here
                  </DropdownItem>
                  <DropdownItem to="/start/expectation" tag={Link}>
                    What To Expect
                  </DropdownItem>
                  <DropdownItem to="/start/learning" tag={Link}>
                    How To Learn/Think
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle style={{fontSize:"x-small"}} className="mr-2" color="default" id="dev-tools" caret nav>
                  Dev Tools
                </DropdownToggle>
                <UncontrolledTooltip placement="bottom" target="dev-tools">
                  Find out which tools we offer.
                </UncontrolledTooltip>
                <DropdownMenu className="dropdown-danger" right>
                <DropdownItem header className="dropHeader">
                  Recommended
                  </DropdownItem>
                  <DropdownItem to="/devtools/online" tag={Link}>
                    Online
                  </DropdownItem>
                  <DropdownItem header className="dropHeader">
                  Other
                  </DropdownItem>
                  <DropdownItem to="/devtools/vm" tag={Link}>
                    Virtual Machines
                  </DropdownItem>
                  <DropdownItem to="/devtools/diy" tag={Link}>
                    DIY Environment
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle style={{fontSize:"x-small"}} className="mr-2" color="default" id="languages" caret nav>
                  Starter Languages
                </DropdownToggle>
                <UncontrolledTooltip placement="bottom" target="languages">
                  Pick a Language to Learn.
                </UncontrolledTooltip>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/c" tag={Link}>
                    C
                  </DropdownItem>
                  <DropdownItem to="/python" tag={Link}>
         
                    Python
                  </DropdownItem>
                  <DropdownItem to="/c++" tag={Link}>
            
                    C++
                  </DropdownItem>
                  <DropdownItem to="/java" tag={Link}>
              
                    Java
                  </DropdownItem>
                  <DropdownItem to="/js" tag={Link}>
            
                    Javascript
                  </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle style={{fontSize:"x-small"}} className="mr-2" color="default" id="other-lang" caret nav>
                  Other Languages
                </DropdownToggle>
                <UncontrolledTooltip placement="bottom" target="other-lang">
                  Check out some other languages.
                </UncontrolledTooltip>
                <DropdownMenu>
                  {/*to="/csharp"*/}
                  <DropdownItem to="/unavailable" tag={Link}>
                    C#
                  </DropdownItem>
                  {/*to="/swift"*/}
                  <DropdownItem to="/unavailable"  tag={Link}>
                    Swift
                  </DropdownItem>
                  {/*to="/r"*/}
                  <DropdownItem to="/unavailable"  tag={Link}>
                    R
                  </DropdownItem>
                  {/*to="/matlab"*/}
                  <DropdownItem to="/unavailable"  tag={Link}>
                    MATLAB
                  </DropdownItem>
                  <DropdownItem to="/unavailable"  tag={Link}>
                    Verilog
                  </DropdownItem>
                  <DropdownItem to="/unavailable"  tag={Link}>
                    VHDL
                  </DropdownItem>
                  <DropdownItem to="/unavailable"  tag={Link}>
                    Dart
                  </DropdownItem>
                  {/*to="/bash"*/}
                  <DropdownItem to="/unavailable"  tag={Link}>
                    Bash
                  </DropdownItem>
                  {/*to="/html"*/}
                  <DropdownItem  to="/unavailable" tag={Link}>
                    HTML
                  </DropdownItem>
                  {/*to="/css"*/}
                  <DropdownItem to="/unavailable"  tag={Link}>
                    CSS
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle  color="default" id="info" caret nav>
                  More
                </DropdownToggle>
                <UncontrolledTooltip placement="bottom" target="info">
                  Contact us, get help, or support our effort!
                </UncontrolledTooltip>
                <DropdownMenu className="dropdown-danger" right>
                
                <DropdownItem tag={Link}>
                <i className="nc-icon nc-laptop" />
                    Project Examples (Comming Soon)
                  </DropdownItem>
                  {/*to="/contact-us*/}
                  <DropdownItem>
                    <i className="nc-icon nc-chat-33" />
                   <a href="mailto:shocode.contact@gmail.com" style={{color:"black", fontWeight:"400"}}>Contact Us</a>
                  </DropdownItem>
                  {/*to="/tutoring"*/}
                  <DropdownItem tag={Link}>
                    <i className="nc-icon nc-chat-33" />
                    Get Tutoring (Comming Soon)
                  </DropdownItem>
                  <DropdownItem to="/support-us" tag={Link}>
                    <i className="nc-icon nc-money-coins" />
                    Support shoCODE
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Button
                  className="btn-round"
                  color="danger"
                  to="/shop"
                  tag={Link}
                  id="shop-button"
                >
                  {/*<i className="nc-icon nc-circle-10" /> */}
                  <i className="nc-icon nc-cart-simple" /> Shop
                </Button>
                <UncontrolledTooltip placement="bottom" target="shop-button">
                  Shop for Code Packs
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ShoNavbar;
