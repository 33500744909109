import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG1(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Basic Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program to calculate the circumference of a circle. The program should allow the user 
        to enter a radius in meters then the program will display the circumfrence given the users radius. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
import java.util.Scanner;

public class example1
{
  public static void main(String[] args)
  {
    Scanner input = new Scanner(System.in);
    //Declare Variables
    float radius, circumference;

    //Get the radius from the user
    System.out.println("Please enter a radius in meters:");
    radius = input.nextFloat();
    
    //Calculate the circumference
    circumference = 2*3.14159f*radius;

    //Print the result to the screen
    System.out.println("A circle with radius " + radius + " has circumference " + circumference);
    System.exit(0);
  }
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program to calculate the average of 3 integers. The program should allow the user 
        to enter 3 integers then the program will display the average to the user. 
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
import java.util.Scanner;

public class example2
{
  public static void main(String[] args)
  {
    // Declare Variables
    int num1, num2, num3;
    float avg;

    // Get the integers from the user
    System.out.println("Please enter 3 integers:");
    Scanner input = new Scanner(System.in);
    num1 = input.nextInt();
    num2 = input.nextInt();
    num3 = input.nextInt();

    //Calculate the average
    avg = (num1 + num2 + num3) / 3.0f;
    // use 3.0f or type cast to avoid integer division

    // Print the result to the screen
    System.out.println("The average of the 3 numbers you entered is " + avg);
    System.exit(0);
  }
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build a program to print 0 to the screen if the user enters an even number. If 
        the user enters an odd number print 1. Use only integers.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
import java.util.Scanner;

public class example3
{
  public static void main(String[] args)
  {
    // Declare Variables
    int num;

    // Get the number from the user
    Scanner input = new Scanner(System.in);
    System.out.println("Please enter an integer:");
    num =  input.nextInt();

    // Calculate and Print the result to the screen
    System.out.print(num%2);
    System.exit(0);
  }
}
`}/>
    </Collapse>
    </>
  );
}

export default EG1;
