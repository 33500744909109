import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Functions(){
  return (
    <>
      <TopHeader title="Functions" />

      <p>
      Functions are a great way to make programs more modular. This allows the developer 
      to break down a problem into multiple steps. The developer can program each of the steps 
      individually, which enables the developer to test each step to makes sure it's working correctly. 
      The developer can put these steps together to solve the actual problem. Functions can also 
      reduce repetative code by creating a general solution that you can use repeatedly and 
      reliably. An very basic analogy could be, your boss asks you to build a program that paints a 
      tree. You can break this down into many steps. First build a function to paint the outline of 
      the tree. Then build two more functions to paint the leaves green and the rest of the tree brown.
      You can test each function rigorosly to make sure it works. Then put it all together by calling 
      each function in order. What if your boss asks you to paint 1000 trees? That's right, you can 
      use a loop to call your functions 1000 times.
      </p>
      <br/>
      <p>You've actually already some functions. The previous sections have shown main(), printf(), 
        and scanf(). These are all functions. The parenthases '()' are how you pass information to 
        the function. Let's start with a very short example. Build a function named Add2Ints 
        to add two integers and return the sum of those integers. This is shown below.
      </p>
<CodeBlock code={`
#include<stdio.h>

//This is a function called Add2Ints
int Add2Ints(int num1, int num2){
  int result;
  result = num1+num2;
  return result;
}

//this is the main function
//The main function always runs first
int main(){
  int x, y, sum;
  x = 5;
  y = 3;

  /* This is calling the function */
  sum = Add2Ints(x, y); 
  /* x is in the first position and the value in x get's passed to num1 */
  /* y is in the secont position and the value in y get's passed to num2 */
  /* The function returns a number to where the function is called. */
  /* The sum variable stores the result that the function returns. */

  printf("%d + %d = %d\\n", x, y, sum);
  return 0;
}
`}/>
<p>Output: </p>
<CodeBlock code={`
5 + 3 = 8
`}/>
<br/>
<p> Here's a break down of what is happening when the function is being called.
  <br/>1. x is in the first position and the value in x get's passed to num1. 
  <br/>2. y is in the second position and the value in y get's passed to num2. 
  <br/>3. Now we move to the function Add2Ints. In the function, an integer variable 
  called results is declared.
  <br/>4. Then, the result variable stores the sum. 
  <br/>5. The result is returned using the return keyword. Notice that result
  is an int and the data type right before Add2Ints is also int. That int in front of the function 
  name is called a return type. The return type of a function must match what is being returned. In 
  this case the match so, we're okay. 
  <br/>6. The return tells the program to exit the current function and return the contents of the 
  result variable to where the function was called. 
  <br/>7. Back in the main() function, the sum variable stores the result that the function returns.
</p>
<br/>
<p>
  Why does the function have to come before the main function? It's pretty much like declaring 
  variables before actually using them. A program can't execute x = 5;, without seeing an int x; 
  first. Similarly, a program can't use a function, that it doesn't know exists. This way of building 
  functions is like int x = 5;, where we declare the variable and set it's contents at the same time.
  We declare the function and declare it's contents before the main function calls it. It is possible 
  to split this up. Here is where we introduce Prototypes. Using prototypes is important because, it 
  must be used if you want to seperate your files later on. For instance, large programs can be hundreds 
  of thousands of lines long. We don't want to write everything in one file. It would be so hard to make 
  changes. So we can use functions in seperate files to keep everything modular. Below, shows the exact 
  same program using prototypes instead.
</p>
<CodeBlock code={`
#include<stdio.h>

//The Prototype for the function called Add2Ints
int Add2Ints(int num1, int num2); 
/* The parameter names are not needed here */
/* The below comment is an alternate protoype */
/* int Add2Ints(int , int); */


//this is the main function
//The main function always runs first
int main(){
  int x, y, sum;
  x = 5;
  y = 3;

  /* This is calling the function */
  sum = Add2Ints(x, y); 
  /* x is in the first position and the value in x get's passed to num1 */
  /* y is in the secont position and the value in y get's passed to num2 */
  /* The function returns a number to where the function is called. */
  /* The sum variable stores the result that the function returns. */

  printf("%d + %d = %d\\n", x, y, sum);
  return 0;
}

//The Add2Ints's function definition.
int Add2Ints(int num1, int num2){
  int result;
  result = num1+num2;
  return result;
}
`}/>
<p>Output: </p>
<CodeBlock code={`
5 + 3 = 8
`}/>
<br/>
<p>
  There's not much a difference there right. Just make sure you use a semicolon with the prototype 
  and use curly braces to write your code for the function below the main function. This is actually 
  a common problem so pay attention to this. The main function has it's own curly braces and the 
  function must also have it's own. You cannot define the function inside of another function. Below
  shows simple layouts. Pay attention to the curly braces! You've been warned (twice).
</p>

<CodeBlock code={`
#include<stdio.h>

int function1( all parameters for the function here ){
      ... Function Code ...
}

int main(){
      ... Main Function Code... 
      //You can call function1 here or in other functions
}
`}/>
<br/>
<p> OR</p>
<CodeBlock code={`
#include<stdio.h>
//Function Prototype
int function1( all parameters for the function here );

int main(){
      ... Main Function Code... 
      //You can call function1 here or in other functions
}

//Function Definition
int function1( all parameters for the function here ){
  ... Function Code ...
}

`}/>
<br/>
<p>
  There is a data type you can use if you don't want to return anything from a function or pass 
  anything to a function. The data type is void.  
</p>
<CodeBlock code={`
#include<stdio.h>
void printhello(void);

int main(){
    printhello();
    return 0;
}

void printhello(void){
  printf("Hello, World!\\n");
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
Hello, World!
`}/>
<br/>

<CodeBlock code={`
Hello, World!
`}/>
<br/>
<p>
You can pass any valid data type as parameters to a function. In our example we only passed two 
parameters(num1 and num2). You can pass as many as you like, but if the list get's large consider 
breaking your function up into two seperate functions. Later lessons, will cover how to pass structures 
and pointers to functions as well. This allows the developer greater flexibility. But in this lesson, 
students should focus on learning the basics of functions.
</p>
<br/>
<p>
  There's a couple of nuances you should be aware of here. First every function has it's own local 
  variables. In the above examples, main can have a variable x and the function can also have a variable 
  x. Those variables are not the same variable. Each function keeps track of their own variables. These 
  variables are local variables. Another lesson, will discuss global variables.
Also notice that if you build a C program with just a function and no main() function, the program will not run.
Every C program needs a main() function because main() is the first function that runs.
 If you are building a function and you want to test it, you must test it with a 
 main() function. A main() function that is specifically built to test a function is often called a 
 <strong>driver.</strong> Below are a few more examples of functions.
</p>
<br/>
<p>Example 1: Build a function that converts meters to feet. Test the function 5 times with user 
  input.
</p>
<CodeBlock code={`
#include<stdio.h>
float MetersToFeet(float meters);
int main(){
  float m, f, i;

  for( i = 0; i < 5; i++){
    printf("Enter a distance(meters):\\n");
    scanf("%f", &m);
    f = MetersToFeet(m);
    printf("%f meters = %f feet\\n", m, f);
  }
  return 0;
}
float MetersToFeet(float meters){
  float feet;
  feet = meters*3.28084;
  return feet;
}
`}/>
<br/>
<p>Example 2: Build a function that takes two parameters. The first parameter should be an int and 
  the second parameter should be a float. The first parameter is the number of lines the function 
  should print and the second parameter is the number that should be repeated on each line. The 
  function should return nothing. Test the function with user input.
</p>
<CodeBlock code={`
#include<stdio.h>
void PrintXTimes(int count, float data);
int main(){
  float input;
  int num;

  printf("Enter the number of lines you want to print\\n");
  scanf("%d", &num);
  printf("Enter the value to show for each line \\n");
  scanf("%f", &input);

  PrintXTimes(num, input);
  return 0;
}
void PrintXTimes(int count, float data){
  for(int i = 0; i< count; i++)
  {
    printf("%f\\n", data);
  }
}
`}/>
<br/>
<p>Example 3: Build a functon to perform float division on two integers. Test the function with user 
  input.
</p>
<CodeBlock code={`
#include<stdio.h>

float DivdeInts(int , int);

int main(){
  int num, den;
  printf("Please Enter a Numerator and Denominator as Integers:\\n");
  scanf("%d %d", &num, &den);

  printf("%d/%d = %f\\n", num, den, DivideInts(num, den));
  return 0;
}

float DivdeInts(int x, int y){
  return (float)x/(float)y;
}
`}/>
<br/>
<p> 
  Finally, notice that the main() function has a return type and we often return 0. This stops the main 
  program and essentially ends the program. The return value is passed back to whatever system called 
  the main function. A return of 0 usually means the c program finished without any problems. Now,
  you finally know what the int in front of main is. It's the main function's return type. Actually,
  you can also pass the main function variables. You can find information on that in the Arguments to Main Lesson.
</p>
<h3><strong>Multiple Functions</strong></h3>
<p>If a function exists in a file, you can call that function from any other function. In fact, 
  a function can call itself (recursion). Learning to call multiple functions is just an extension 
  of the basics of functions. The computer will execute each command and each function call in order and 
  just process everything one step at a time.
</p>
    </>
  );
}

export default Functions;
