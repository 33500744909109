import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function IO() {
  return (
    <>
      <TopHeader title="Input/Output (I/O)" />
      <h3><strong>Output</strong></h3> <br />
      <p>
        Now you know how to declare variables and store information in those variables.
        What can we do with variables? We can manipulate them, print them to the screen,
        and even store input from a user. This lesson will focus on printing a
        variable's contents to the screen and storing user input into a variable.
        The first program shows a simple way to print variables to the screen using the 
        exact same program from the last lesson. The program has additional print statements 
        to print out the contents of the variables.
      </p>
      <CodeBlock code={`
public class output1
{
  public static void main(String[] args)
  {
    int x = 1024; 
    float y = 3.14f;
    char ch = 'y';

    //printing to the screen
    System.out.println("the value in x is " + x);
    System.out.println("y = " + y);
    System.out.println(ch + " is stored in ch");

    //Print Multiple Variables to the screen
    System.out.println("x: " + x + " y: " + y + " ch: " + ch);
    System.exit(0);
  }
}
`} /><p>
        Output:
</p>
      <CodeBlock notCode code={`
the value in x is 1024
y = 3.14
y is stored in ch
x: 1024 y: 3.140000 ch: y
`} />
      <p>
      We can use printf instead of println which is closer to C programming. 
        We will need ot use this function with something called conversion specifiers. 
        The code must
        specify if it's an int, float, etc. When variables are printed to the screen,
        the print statment needs to know what kind of variable it's printing. This will 
        be shown in the following examples.
        <br/>
        The conversion specifiers are the <strong>%d, %f , and %c
        for printing ints, floats, and chars respectively.</strong>
      </p>

      <p>
        Note that we can use this printf statement to test our programs. We can make sure
        the code is doing what we expect. Consider the following code from the last lesson.
        By adding in print statements we can see what the program is executing in order.
    </p>
      <CodeBlock code={`
public class output2
{
  public static void main(String[] args)
  {
    int x = 5;    //declares and initializes x to hold 5
    int y = 0;    //declares and initializes y to hold 0
    float a, b;   //declare two decimal numbers
    a = b = 9.8f;  //a and b both hold 9.8
    
    System.out.printf("x: %d, y: %d, a: %f, b: %f\n",x,y,a,b);
    y = x; //y now holds 5;
    System.out.printf("x: %d, y: %d, a: %f, b: %f\n",x,y,a,b);
    b = 3.14f; //b now holds 3.14
    System.out.printf("x: %d, y: %d, a: %f, b: %f\n",x,y,a,b);
    a = b; //a now holds 3.14
    System.out.printf("x: %d, y: %d, a: %f, b: %f\n",x,y,a,b);
  }
}
`} />
      <p>
        Output:
</p>
      <CodeBlock code={`
x:5 ,y:0 ,a:9.800000 ,b:9.8000000
x:5 ,y:5 ,a:9.800000 ,b:9.8000000
x:5 ,y:0 ,a:9.800000 ,b:3.1400000
x:5 ,y:0 ,a:3.140000 ,b:3.1400000
`} />
      <br />
      <br />
      <h3><strong>Input</strong></h3>
      <br />
      <p>
        Now that we can print a variable to the screen, let's see how we can
           allow a user to store information into a variable in the program. Here
           we introduce a new function called Scanner, which allows us to access the nextInt() 
           method.
           Scanner comes from the import java.util.Scanner statement at the top of the code.
           The code below, shows how we can use the the scanner to scan input from the user.
  </p>
      <CodeBlock code={`
import java.util.Scanner;

public class input1
{
  public static void main(String[] args)
  {
    System.out.println("Enter an integer:");
    Scanner input = new Scanner(System.in); //creates a scanner named input
    int x = input.nextInt(); //access the nextInt() method in the scanner created above.
    System.out.printf("You entered the number %d\n", x);
  }
}
`} />
      <p>
        The output of the code is shown below. The user must enter something in order for the 
        code to continue passed the nextInt() method call. Let's say the user enters 35. Then, 
        the output would be shown below.
  </p>
      <CodeBlock notCode code={`
35    <- User Input
You entered the number 35
`} />
      <p>
      If you're asking how can we scan in a float instead of an int, then you're in luck.
        Scanner also has a method called nextFloat() and it's use is demonstraded below.
</p>
      <CodeBlock code={`
import java.util.Scanner;

public class input2
{
  public static void main(String[] args)
  {
    Scanner input =  new Scanner(System.in);
    System.out.println("Please enter a decimal number:");

    float num = input.nextFloat();
    System.out.println("You entered the number " + num);
  }
}
`} />
      <p>The output of the program with user input of 512 is shown below.</p>
      <CodeBlock notCode code={`
Please enter a decimal number:
512  <- User Input
You entered the number 512
`} />
      <p>
        An example below is given to show how to scan in multiple inputs into a program.
        The \n is simply the new line character that prints out a new line to the screen.
</p>
      <CodeBlock code={`
import java.util.Scanner;

public class input3
{
  public static void main(String[] args)
  {
    Scanner input = new Scanner(System.in);
    System.out.println("Please enter two ints and a float:");
    int x1 = input.nextInt();
    int x2 = input.nextInt();
    float y = input.nextFloat();
    System.out.print("\nx1: " + x1 + "\nx2: " + x2 + "\ny: " + y);
  }
}
`} />
      <p>
        Output: Note the scan statement scans for x2 before x1.
</p>
      <CodeBlock notCode code={`
Please enter two ints and a float:
25 4 91.7    <- User Input

x1: 4
X2: 25
y: 91.700000
`} />
      <p>
        Now let's deal with controlling the decimal on those float values. For this you would
        use printf to control the prints. By default, printf() will
        show 6 decimal places for float variables. We can control the width of the output and the decimal
        places you want to print. This is accomplished by changing your %f specifiers in printf().
      <br /><br />
        <strong>%w.df</strong> - w and d are ints. Print a decimal number with total width w and d decimal places.
      <br /><strong>%5.2f</strong> - print a float with total width 5 and 2 decimal places.
      <br /><strong>10.1f</strong> - print a float with total width 10 and 1 decimal place.
      <br /><br />
        The width is the total count of characters being printed to the screen. The decimal places is how many
        characters after the decimal you want to print. There's some small nuances you should be aware of.
      <br />1. The decimal or period counts towards the width. It counts as 1 character.
      <br /> 2. If the decimal place causes the width of the output to be greater than the width specified,
        the decimal places win and override the width. The width is ignored in this case.
      <br /> 3. If the number has the correct decimal places specified but is still shorter than the width
        specified, then the number is padded on the left side by spaces.
      <br />
        That's all so wordy and confusing. So check out the example below and then come back and read
        this to hopefully gain a better understanding.
    </p>
      <CodeBlock code={`
public class spacing
{
  public static void main(String[] args)
  {
    float pi = 3.141593f;

    // width 2 and 4 decimal places
    System.out.printf("%2.4f\n", pi); // This is case 2 above

    // width 6 and 2 decimal places
    System.out.printf("%6.2f\n", pi); // This is case 3 above

    // width 5 and 3 decimal places
    System.out.printf("%5.3f\n", pi);

    // width 6 and 2 decimal places and leading 0
    System.out.printf("%06.2f\n", pi);
    System.exit(0);
  }
}
`} />
      <p>
        Output:
</p>
      <CodeBlock notCode code={`
3.1416
3.14
3.142
003.14
`} />
<p>

</p>
      <br /><br />
      <h3><strong>Character I/O</strong></h3>
      <br />
      Character I/O behaves differently than when you are scanning numbers. You can use 
      the scanner to scan in only one char at a time by using the code below.
      <br/><br/>
      <CodeBlock code={`
import java.util.Scanner;

public class character_io
{
  public static void main(String[] args)
  {
    System.out.println("Enter a character:");
    Scanner input = new Scanner(System.in); //creates a scanner named input
    char x = reader.next().charAt(0); // scans in one character and stores it in x
    System.out.printf("You entered the number %c\n", x); // prints the contents of x
  }
}
`} /> 
<p>
  Output:
</p>
<CodeBlock notCode code={`
s 5   <-User Input
s 5
y
The user entered s 5 
`} />
<br/><br/>
    <p>Working with characters can be tricky for new students. 
      This is because whitespace characters (tabs, newlines, spaces, etc.) all 
      count as individual characters. In addition, digits like 1, 2, and 3 also 
      have a character representation. The character 5 is not the same as the integer 
      5. In fact, characters do hold an integer value. The integer value is normally the 
      ascii value of the character. Each character has an asigned ascii value and can be 
      casted as an integer. You can also cast an ascii value to a character if you need that 
      character. Looking at an ascii chart, you also see that the character 'X' is different from 
      'x'. All of these factors tend to make learning about characters a large task.
    </p>

    <p>
      There are many specifiers a developer could use in printf() because there are 
      many data types. These lessons are focused more towards new students and will mostly cover
      int, float, and char.
    </p>
    </>
  );
}
export default IO;
