import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function FileIO(){
  return (
    <>
      <TopHeader title="Structures" />
      <p>
      File IO is done very similar to Command Line IO which is shown in the basics section.
      
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default FileIO;
