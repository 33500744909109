import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Functions(){
  return (
    <>
      <TopHeader title="Functions" />

      <p>
      Functions are a great way to make programs more modular. This allows the developer 
      to break down a problem into multiple steps. The developer can program each of the steps 
      individually, which enables the developer to test each step to makes sure it's working correctly. 
      The developer can put these steps together to solve the actual problem. Functions can also 
      reduce repetative code by creating a general solution that you can use repeatedly and 
      reliably. An very basic analogy could be, your boss asks you to build a program that paints a 
      tree. You can break this down into many steps. First build a function to paint the outline of 
      the tree. Then build two more functions to paint the leaves green and the rest of the tree brown.
      You can test each function rigorosly to make sure it works. Then put it all together by calling 
      each function in order. What if your boss asks you to paint 1000 trees? That's right, you can 
      use a loop to call your functions 1000 times.
      </p>
      <br/>
      <p>You've actually already some functions. The previous sections have shown main(), printf(), 
        and scanf(). These are all functions. The parenthases '()' are how you pass information to 
        the function. Let's start with a very short example. Build a function named Add2Ints 
        to add two integers and return the sum of those integers. This is shown below.
      </p>
<CodeBlock code={`
# This is defining a function called Add2Ints
def Add2Ints(num1,num2):
  result = num1+num2
  return result

# The program starts here
# The program can call the function above at any time.
x = 5
y = 3

# This is calling the function
total = Add2Ints(x,y)

# x is in the first position and the value in x get's passed to num1
# y is in the secont position and the value in y get's passed to num2
# The function returns a number to where the function is called.
# The sum variable stores the result that the function returns.

print(x, "+", y, "=", total)
`}/>
<p>Output: </p>
<CodeBlock code={`
5 + 3 = 8
`}/>
<br/>
<p> Here's a break down of what is happening when the function is being called.
  <br/>1. x is in the first position and the value in x get's passed to num1. 
  <br/>2. y is in the second position and the value in y get's passed to num2. 
  <br/>3. Now we move to the function Add2Ints. In the function, a variable 
  called results is declared.
  <br/>4. Then, the result variable stores the sum. 
  <br/>5. The result is returned using the return keyword. 
  <br/>6. The return tells the program to exit the current function and return the contents of the 
  result variable to where the function was called. 
  <br/>7. Back in the program the sum variable stores the result that the function returns.
</p>
<br/>
<p>
  A function must be defined before it is called.
</p>
<CodeBlock code={`
# The Add2Ints's function definition.  It must be defined before it is called.
def Add2Ints(x,y):
  return x+y

x = 5
y = 3

# x is in the first position and the value in x get's passed to num1
# y is in the secont position and the value in y get's passed to num2
# The function returns a number to where the function is called.
# The sum variable stores the result that the function returns.

# Here we are calling the custom function within the print function.
print(x, "+", y, "=", Add2Ints(x,y))

`}/>
<p>Output: </p>
<CodeBlock code={`
5 + 3 = 8
`}/>
<br/>

<p>
  If you're still confused, check out the program below. 
  It is a very simple program to help simplify what is happening. 
</p>
<CodeBlock code={`
#the function is defined
def printhello():
  print("Hello, World!")

#the program start here.
printhello()
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
Hello, World!
`}/>
<br/>

<CodeBlock code={`
Hello, World!
`}/>
<br/>
<p>
You can pass any valid data type as parameters to a function. In our example we only passed two 
parameters(num1 and num2). You can pass as many as you like, but if the list get's large consider 
breaking your function up into two seperate functions. Later lessons, will cover how to pass structures 
and pointers to functions as well. This allows the developer greater flexibility. But in this lesson, 
students should focus on learning the basics of functions.
</p>
<br/>
<p>
  There's a couple of nuances you should be aware of here. First every function has it's own local 
  variables. In the above examples, main can have a variable x and the function can also have a variable 
  x. Those variables are not the same variable. Each function keeps track of their own variables. These 
  variables are local variables. Another lesson, will discuss global variables.
</p>
<CodeBlock code={`
def MetersToFeet(meters):
feet = meters*3.28084
return feet

i = 0
while i<5:
m = float(input("Enter a distance (meteres): "))
f = MetersToFeet(m)
print(m, "meters =", f)
i = i+1
`}/>
<br/>
<p>Example 2: Build a function that takes two parameters. The first parameter should be an int and 
  the second parameter should be a float. The first parameter is the number of lines the function 
  should print and the second parameter is the number that should be repeated on each line. The 
  function should return nothing. Test the function with user input.
</p>
<CodeBlock code={`
def PrintXTimes(count,data):
i = 0
while i<count:
  print(data)
  i = i+1

num = int(input("Enter the number of lines you want to print: "))
value = int(input("Enter the value to show for each line: "))

PrintXTimes(num,value)
`}/>
<br/>
<p>Example 3: Build a functon to perform float division on two integers. Test the function with user 
  input.
</p>
<CodeBlock code={`
def DivdeInts(x,y):
return int(x/y)

num = int(input("Please enter a numerator and demoniator as integers: "))
den = int(input())

print(num, "/", den, "=", DivdeInts(num,den))
`}/>
<br/>

<h3><strong>Multiple Functions</strong></h3>
<p>If a function exists in a file, you can call that function from any other function. In fact, 
  a function can call itself (recursion). Learning to call multiple functions is just an extension 
  of the basics of functions. The computer will execute each command and each function call in order and 
  just process everything one step at a time.
</p>
    </>
  );
}

export default Functions;
