class HttpHelper {
  static async fetch(url, method, headers, body) {
    let options = Object.assign({ method });
    if (headers) {
      options = Object.assign(options, { headers });
    }
    if (body && method !== 'GET') {
      options = Object.assign(options, { body });
    }
    const request = new Request(url, options);
     //console.log(options);
     //console.log(request);
    try {
      const response = await fetch(request);
      //console.log(response);
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      const error = new Error(`${response.statusText}(${response.status}), URL: ${response.url}`);
      error.response = response;
      //console.log(error);
      return Promise.reject(error);
    }
    catch (error_1) {
      console.log("AMIHERE!!!!!!!!!!!!!!!!!!!!!!")
      throw error_1;
    }
  }
}

export default HttpHelper;
