import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function FirstProg() {
  return (
    <>
            <TopHeader title="Run Your First Program" />
      <p>
        Your first program is usually a small short program to make sure your development
        environment is running correctly. You want to make sure you can write code and run code
        before you start anything else. Traditionally, this is the hello world program. This
        program will print hello world to the screen in some way, shape, or form. If everything
        works, then you know your environment is set up correctly. The hello world program
        is the first program shown in all the programming languages here at shoCODE.
        </p>
      <p>
        Go ahead and select the language you want to learn from the Navigation Bar at the top.
        Find the hello world program in the side menu and move on to other topics at your own
        pace. In general, the topics in the side menu are organized by the order in which you
        should learn them. Start from the top and work your way to the bottom.
        </p>

    </>
  );
}

export default FirstProg;
