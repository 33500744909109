import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function WhatIs() {
  return (
    <>
      <TopHeader title="What is programming?" />
      <p>
        This is a question we get a lot and actually it's very simple.
        A computer is a tool that will execute instructions step by step.
        This step by step document that a computer follows is simply called a
        program. Think about all the apps you use or games you play, all of
        those are programs that are just following a set of instructions. When
        you make the set of instructions, that's what programming is.
        <br /><br />  All computers speak the same/similar
        language. This is called assembly language or machine language.
        What most programmers do, is write code in an intermediate language
        like python, C, Java, etc. Once The code is written in one of those
        languages, a compiler (or interpreter in some cases) translates the
        code into machine language that the computer can then understand. So
        a compiler(or interpreter) translates your code from a language like C or python to
        an instruction set that a computer can use.
        <br /><br /> If you want to make your computer do something,
        you must give it a set of instructions. Instead of writing machine language
        or assembly language you can learn another language like C. If you write code 
        in that language then a compiler can understand you and will translate your 
        code into a program that a computer can understand. The computer can now follow 
        your instructions by running your program.
        <br /><br /> 
        
        In this class you are learning python. The very first program we are going to learn is 
        called "Hello World!". It's a simple program that gives 1 instruction to the computer.
        The instruction is simply to print "Hello World!" to the screen. As you progress in this course,
        you will be able to build longer instruction sets that are more complex. 
        <br/>
        <br/>
        You can find the first lesson by: clicking on the Basics Section below. Then, select Hello World from the menu. 
        <br/>
        <br/>
        I encourage you to use the menu so you understand how to navigate the course notes. <br/>But just in case you
        you can't find the first lesson, you can click <a className="link-danger" href="https://shocode.com/python100/hello"><strong>here</strong></a>.
      </p>
    </>
  );
}

export default WhatIs;
