import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContent() {
  return (
    <>
      <div id="fadeInAnim">
        <div className="section section-content section-gray">
          <Container>
            <Row>
              <Col md="7">
                <div className="image-container">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/mimg/sections/ipad-content-2.png")}
                  />
                  <img
                    alt="..."
                    className="area-img add-animation"
                    src={require("assets/mimg/sections/ipad-left-img.jpg")}
                  />
                  <img
                    alt="..."
                    className="info-img add-animation"
                    src={require("assets/mimg/sections/ipad-right-img.jpg")}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="section-description">
                  <h3 className="title">Simple Short Examples</h3>
                  <h6 className="category">Reinforced With Larger Programming Projects</h6>
                  <h5 className="description text-dark">
                    Many intersting programming projects are very large. But, those projects
                    are just many features built to work together. Each of those features are
                    built using smaller basic components. At shoCODE, you wont stop learning 
                    after a small example, you'll read or experience how that example can 
                    be part of the bigger picture. At certain checkpoints, you'll be challenged 
                    with a more involved program that will test your ability to apply previous 
                    knowledge. 
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
