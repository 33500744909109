import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Strings(){
  return (
    <>
      <TopHeader title="Strings (Charactar Arrays)" />
      <p>
      A String is actually just a character array. Each character is stored in an element in the array. 
      The illustration below show's how the characters are stored in the array.
  </p>
  <br />
      <img
        alt="..."
        style={{ width: "30%" }}
        className="code-image"
        src={require("assets/mimg/code/c/string.png")}
      />
      <br />
        
      <h3><strong>String Input/Output</strong></h3>
      <p> The input function can be used to get strings. Using the split() function 
        on strings will store seperate variables for words seperated by spaces.
      </p>
      <CodeBlock code={`
firstName, lastName = input("Enter your full name: ").split()

print("First name:", firstName)
print("Last name:", lastName)
`}/>
<p> 
  Output: User enters sho\nCODE
</p>
<CodeBlock notCode code={`
sho     <-User Input
CODE    <-User Input
First Name: sho
Last Name: Code
`}/>
<br/>
<p>
  The null character tells the printf() when it's done printing the string. Both arrays have 100 character spaces but the printf() 
  function knows when to stop printing the string based on the '\0'. Alos, look closely at the scanf() calls above. There is no & 
  symbol. That & symbol used to be in every scan statement you've done so far. You don't need the & symbol here because array 
  varaiables without the subscript brackets are the address to the array. If you're interested in what the & symbol was and why 
  we don't need it here check out the pointers lesson.
</p>
<br/>
<p>
  If we do not use the split command the entire line will be read into the variable.
</p>
<CodeBlock  code={`
stdin = input("Enter a sentence: ")

print("Your sentence is below:")
print(stdin)
`}/>
<br/>
<p>Output:</p>
<CodeBlock notCode code={`
Enter a sentence:
shoCODE is AWESOME!         <-User Input
Your Sentence is below:
shoCODE is AWESOME!
`}/>
    </>
  );
}
export default Strings;
