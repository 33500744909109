import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Cast(){
  return (
    <>
      <TopHeader title="Type Casting (Type Conversions)" />
      <p>
This section expands a little on type casting which was shown earlier in the int Input Lesson above. Remeber, we can use the funtion int()
to turn something into an int. Similarly, you can use float() and str() to cast as a string.
</p>
<CodeBlock code={`
var = '9.8' # declares and initialized a variable
# var now holds the string 9.8

var_float = float(var)  # casts var to a float and puts the result in var_float
var_int = int(var_float) # casts the float to an int
var_str = str(float) # casts the float to a string

print("var =", var, "it's type is:", type(var))
print("var_float =", var_float, "it's type is:", type(var_float))
print("var_int =", var_int, "it's type is:", type(var_int))
print("var_str =", var_str, "it's type is:", type(var_str))

`} />
<p> Output:</p>
<CodeBlock notCode code={`
var = 9.8 it's type is: <class 'str'>
var_float = 9.8 it's type is: <class 'float'>
var_int = 9 it's type is: <class 'int'>
var_str = <class 'float'> it's type is: <class 'str'>

`} />
<p>
  Casting has been shown before when you were learning the input() function. All inputs 
  are treated as strings. We simply need to cast those strings as int or float to perform 
  mathematical operations on them.
</p>
<br/>
<br/>

<p>
  There are many other uses for typecasting. Often 
  it's easier to temporarily change a character's type instead of storing two different 
  types of the same information. You will definitely see more use cases as you build larger 
  programs.
</p>
    </>
  );
}

export default Cast;
