import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Macros(){
  return (
    <>
      <TopHeader title="Macros" />
      <p>
      A Macro is a preprocessor. This means that before the programs are compiled from this language to assembly or machine 
      language the preprocessor runs. Macros basically are a way to perform search and replace in your code and can also make 
      your code a little bit more readable. We can create a macro by using #define.
      <br/>
      For example, let's build a program to calculate the area of a circle. We can use 3.14 for PI but another programmer 
      might be confused about what this number actually is. We can have the program replace all text that is the same as PI
      with 3.14. An example is shown below.
      </p>
      <CodeBlock code={`
#include <stdio.h>

/* This line creates a macro */
/* It will search this text file and replace */
/* all PI texts with 3.14 */
#define PI 3.14

int main(){
  float radius = 5.2;
  float area;

  /* We can use the macro here */
  area  =  PI * radius * radius; 

  printf("The Area is: %f \\n", area);
  return 0;
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
The Area is: 84.9056
`}/>
<br/>
<p>
  It's important to state that macros are not variables. We cannot assign them a value during runtime. It cannot hold 
  data. Macros just replace text before compiling happens. Since it just performs search and replace in text, you can write 
  large chunks of code as macros. That's not recommended but it is possible. 
</p>
<h3><strong>Function Like Macros</strong></h3>
<p>
  Since macros perform search and replace with text, there is a way to use them like functions. For example we can define 
  a macro as<br/><br/> #define AddTen(x) (x + 10) <br/><br/> and use this macro like a function. This is shown below.
</p>
<CodeBlock code={`
#include <stdio.h>

#define AddTen(x) (x+10);

int main(){
  int y = 5;
  int result;

  result = AddTen(y)

  printf("The result is: %d \\n", result);
  return 0;
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
The result is: 15
`}/>
<br/>
<p>
  So what exactly happened above? Remember Macros are used before compiling. So the command AddTen(y) just gets replaced 
  by the text (y+10);. Since y was in the parenthases, the x in the original macro gets replaced with y. With function like 
  macros you can "pass" more than one parameter. Pass is not the correct word here but it does look like a function call.
  <br/>
  Notice the semicolon in the macro and notice there is no semicolon in the code after AddTen(y). After the expansion, the 
  semicolon exists in the right place so everything works out. That's just something you have to be aware of because it's a 
  common mistake.
</p>
    </>
  );
}

export default Macros;
