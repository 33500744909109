import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Arrays() {
  return (
    <>
      <TopHeader title="Lists & Arrays" />
      <p>
        Lists are very similar to Arrays in other languages. The difference is lists in python come with some pretty nice built in functions.
        Lists and arrays are one of the most usefule data type to programmers. 
      </p>
      <br />
      <p>A list is a group of variables in memory. In python, they don't need to be the same data type but it's usefule to keep the data types
        the same in the beginning. Consider
      a problem where you need to have variables for 5 of your friend's high scores in a game. 
      Creating 5 variables and naming 5 variables can take up a lot of space in code and make it
      hard to read. What if we could store all of the information in one variable called 'scores'. The code 
      below will show you how to do this with 5 variables versus doing this with a list.
      </p>
      <CodeBlock code={`
'''
Declares 5 scores for each of your friends
score0 = 100
score1 = 150
score2 = 75
score3 = 99
score4 = 125
'''
#instead of the above we can declare the scores in one variable that is a list.
scores = [100, 150, 75, 99, 125]
# scores is a list of integers
print(scores) # you can print lists
`} />
<p>Output:</p>
<CodeBlock notCode code={`
[100, 150, 75, 99, 125]
`} />      <br />
      <p>
        The above code declares an array of 5 integers from scores[0] to scores[4]. The square brackets
        are called the subscript operator or index. The index is used to access each of the array elements.
        It starts at index 0. Since there are 5 total integers, that means the index goes from 0 to 4. The image below
        illustrates how you can think of an array that stores 5 ints and how to access each element.
        <br/>
        The code scores = [100, 150, 75, 99, 125] creats an array of 5 integers and you can access the 2nd integer (which is 150)
        but using scores[1]. See the image below for reference.
      </p>
      <br />
      <img
        alt="..."
        className="code-image"
        style={{ width: "40%" }}
        src={require("assets/mimg/code/python/lists.png")}
      />
      <br />
      <p>
        We can experiment a little more on accessing list data and changing list data. See if you can predict the output for the following code.
      </p>
      <CodeBlock code={`
scores = [100, 150, 75, 99, 125]

print("scores:",scores)

scores[0] = 5
scores[4] = 1
print("scores:",scores)
print("scores[2] =", scores[2])

`} />
     <p>
      Output:
      </p>
      <CodeBlock notCode code={`
scores: [100, 150, 75, 99, 125]
scores: [5, 150, 75, 99, 1]
scores[2] = 75
`} />
      <p>
        Lists can also have other data types. You can have lists of ints, floats, or strings. 
        An example of creating and using a list of strings is given below.
</p>

<CodeBlock code={`
names = ['Chris', 'David', 'Emily']

print("names:", names)

names[0] = 'Bob'
names[1] = 'Sarah'
print("names:", names)
print("names[2] =", names[2])

`} />
     <p>
      Output:
      </p>
      <CodeBlock notCode code={`
names: ['Chris', 'David', 'Emily']
names: ['Bob', 'Sarah', 'Emily']
names[2] = Emily
`} />

<br/>
<h4>Adding and Removing from a List</h4>
      <p>
        We can also add and remove items by using the list.append() or list.remove() functions. An example of this is given below.
</p>
<CodeBlock code={`
scores = [100, 150, 75, 99, 125]

print("scores-1:",scores)

scores.append(10) #add the number 10 to the scores list
print("scores-2:", scores)
scores.remove(150) #remove the first 150 in the list
print("scores-3:", scores)
print("scores[2] =", scores[2])

`} />
     <p>
      Output:
      </p>
      <CodeBlock notCode code={`
scores-1: [100, 150, 75, 99, 125]
scores-2: [100, 150, 75, 99, 125, 10]
scores-3: [100, 75, 99, 125, 10]
scores[2] = 99

`} />
<h4>Nested Lists</h4>
      <p>
        Lists can even hold other lists. That may be confusing at first but everythign follows the same rules that 
        are shown above.
</p>
<CodeBlock code={`
scores1 = [100, 150, 125]
scores2 = [1, 2, 3]
scores3 = [50, 75, 90]

mainList = [scores1, scores2, scores3]
print("mainList:", mainList)
print("mainList[1] =", mainList[1])
print("mainList[1][2] =", mainList[1][2])

`} />
     <p>
      Output:
      </p>
      <CodeBlock notCode code={`
mainList: [[100, 150, 125], [1, 2, 3], [50, 75, 90]]
mainList[1] = [1, 2, 3]
mainList[1][2] = 3

`} />
      <p>
        Nested Lists can be confusing. After a bit of practice, you will be able to understand it much better.
</p>
    </>
  );
}
export default Arrays;
