import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Hello() {
  return (
    <>
      <TopHeader title="Hello World" />
      <p>
        Create a new .js file called hello.js and insert the following code.
      </p>
      <CodeBlock
        code={`
"use strict"

console.log("Hello, World");
`} />
      <p>
        Then run the program.
        If Everything went smoothly you should see something similar to the output below.
      </p>
      <CodeBlock notCode code={`
Hello, World
`} />
      <p>
        Let's break it down.
      <br />

        <strong>{`use strict`} </strong>- This is a special drictive that was introduced 
        into javascript. Without that it's possible to write code that is a little sloppy. It's 
        best to always use this because errors will be thrown that force you to write code that is 
        more secure.
      <br /><strong>{`console.log("Hello, World");`} </strong>- This function prints to the screen everything within the quotation marks.

    </p>

      <br />

      <p>
        Comments can also be used to keep notes or give other developers clues about what 
        the program is trying to accomplish. There are two ways to comment in JavaScript.
        The first is the multi line comment and the second is a single line comment.
        Comments are completely ignored by the compiler and will not effect the executable 
        program. Both types of comments are shown below. 
      </p>
      <CodeBlock code={`
/*
This is a multi lined comment
Everything between the  asterisks
is ignored by the compiler
*/

"use strict"
// this is a single line comment.
console.log(3);
console.log(2);
console.log(1);
console.log(0);
//print "Bye" to the screen
console.log('Bye'); //the program ends here
`} />
<p>Output:</p>
<CodeBlock notCode code={`
3
2
1
0
Bye
`} />
  <p> 
    The program started at the top of the file and executed statements one at a time 
    until the program ends. This is an important concept in programming and will be 
    important to you as a programmer.
    <br/>
    This page just showed basic functionality. More detail about printing to the 
    screen will be shown in the Input/Output lesson. Before moving on,
    why not try building a program to print your own name, today's date, and 
    your favorite color to the screen on different lines.
  </p>
    </>
  );
}

export default Hello;
