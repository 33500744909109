import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components


function Index() {
  return (
    <>
        <div>
        <Container>
        <h3><strong>Is Your Password Strong Enough?</strong></h3>
        </Container>
      </div>
    </>
  );
}

export default Index;
