import React, { Component } from "react";
import "./Compiler.css";
import { languages, getHelloWorld } from "./languages";
const Jud0APIEndpoint = `https://j0.shocode.com`;

export default class Compiler extends Component {
  constructor(props) {
    super(props);
    console.log("debug", getHelloWorld(54));
    const langID = localStorage.getItem("language_Id") || 71;
    const code = getHelloWorld(parseInt(langID));
    this.state = {
      input: code,
      output: ``,
      language_id: langID,
      user_input: ``,
    };
  }
  input = (event) => {
    event.preventDefault();

    this.setState({ input: event.target.value });
    localStorage.setItem("input", event.target.value);
  };
  userInput = (event) => {
    event.preventDefault();
    this.setState({ user_input: event.target.value });
  };
  language = (event) => {
    event.preventDefault();

    this.setState({
      language_id: event.target.value,
      input: getHelloWorld(parseInt(event.target.value)),
    });
    localStorage.setItem("language_Id", event.target.value);
  };

  submit = async (e) => {
    e.preventDefault();

    let outputText = document.getElementById("output");
    //outputText.innerHTML = "";
    outputText.value = "";
    //outputText.innerHTML += "Creating Submission ...\n";
    outputText.value += "Creating Submission ...\n";
    console.log(Jud0APIEndpoint + "/submissions/")
    const response = await fetch(Jud0APIEndpoint + "/submissions/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        source_code: this.state.input,
        stdin: this.state.user_input,
        language_id: this.state.language_id,
        wall_time_limit: "5",
      }),
    });
    //outputText.innerHTML += "Submission Created ...\n";
    outputText.value += "Submission Created ...\n";
    const jsonResponse = await response.json();

    let jsonGetSolution = {
      status: { description: "Queue" },
      stderr: null,
      compile_output: null,
    };

    while (
      jsonGetSolution.status.description !== "Accepted" &&
      jsonGetSolution.stderr == null &&
      jsonGetSolution.compile_output == null
    ) {
      //outputText.innerHTML = `Creating Submission ... \nSubmission Created ...\nChecking Submission Status\nstatus : ${jsonGetSolution.status.description}`;
      outputText.value = `Creating Submission ... \nSubmission Created ...\nChecking Submission Status\nstatus : ${jsonGetSolution.status.description}`;

      if (jsonResponse.token) {
        let url =
          Jud0APIEndpoint +
          `/submissions/${jsonResponse.token}?base64_encoded=true`;

        const getSolution = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        jsonGetSolution = await getSolution.json();
      }
    }
    console.log(atob(jsonGetSolution.stdout));

    if (jsonGetSolution.stdout) {
      const output = atob(jsonGetSolution.stdout);

      //outputText.innerHTML = "";
      outputText.value = "";

      //outputText.innerHTML += `Results :\n${output}\n\n\nExecution Time : ${jsonGetSolution.time} Secs\nMemory used : ${jsonGetSolution.memory} bytes`;
      outputText.value += `Results :\n${output}\n\n\nExecution Time : ${jsonGetSolution.time} Secs\nMemory used : ${jsonGetSolution.memory} bytes`;
    } else if (jsonGetSolution.stderr) {
      const error = atob(jsonGetSolution.stderr);

      //outputText.innerHTML = "";
      outputText.value = "";

      //outputText.innerHTML += `\n Error :${error}`;
      outputText.value += `\n Error :${error}`;
    } else {
      const compilation_error = atob(jsonGetSolution.compile_output);

      //outputText.innerHTML = "";
      outputText.value = "";

      //outputText.innerHTML += `\n Error :${compilation_error}`;
      outputText.value += `\n Error :${compilation_error}`;
    }
  };
  render() {
    return (
      <>
        <label htmlFor="tags" className="mr-1">
          <b className="heading">Language:</b>
        </label>
        <select
          value={this.state.language_id}
          onChange={this.language}
          id="tags"
          className="form-control form-inline mb-2 language"
        >
          {languages.map((lang) => (
            <option key={lang.id} value={lang.id.toString()}>
              {lang.name}
            </option>
          ))}
        </select>
        <br />
        <div className="row container-fluid">
          <div className="col-6 ml-4 ">
            <label htmlFor="solution ">
              <span className="badge badge-info heading mt-2 ">
                <i className="fas fa-code fa-fw fa-lg"></i> Code Here
              </span>
            </label>
            <textarea
              required
              name="solution"
              id="source"
              onChange={this.input}
              className=" source"
              value={this.state.input}
            ></textarea>
          </div>
          <div className="col-5">
            <div>
              <span className="badge badge-info heading my-2 ">
                <i className="fas fa-exclamation fa-fw fa-md"></i> Output
              </span>
              <textarea id="output" readOnly></textarea>
              <span className="badge badge-primary heading my-2 ">
                <i className="fas fa-user fa-fw fa-md"></i> User Input
              </span>
              <br />
              <textarea id="input" onChange={this.userInput}></textarea>
              <button
                type="submit"
                className="btn btn-danger ml-2 mr-2 "
                onClick={this.submit}
              >
                <i className="fas fa-cog fa-fw"></i> Run
              </button>
            </div>
          </div>
        </div>
          {/*
        <div className="mt-2 ml-5">
          <span className="badge badge-primary heading my-2 ">
            <i className="fas fa-user fa-fw fa-md"></i> User Input
          </span>
          <br />
          <textarea id="input" onChange={this.userInput}></textarea>
        </div>
          */}
      </>
    );
  }
}
