import React from "react";



function TopHeader({title, stick}){
    return(
    <div className={(stick ? "topHeader sticky-top":"topHeader")}>
    <h6>{title}</h6>
    <br/>
    </div>
    )
}
TopHeader.defaultProps = {stick: true}
export default TopHeader;