import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Strings(){
  return (
    <>
      <TopHeader title="Strings (Charactar Arrays)" />
      <p>
      Arrays are one of the most usefule data type to programmers so why did do we introduce it 
      at the end of C. To work with and make full use of array, you will need to apply much of 
      your previous learning. If you can master arrays, you will definitely be able to build
      useful programs.
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default Strings;
