import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function Unavailable() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("error-500");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-500");
    };
  });
  return (
    <>
      <div
        className="background-img"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/sections/the-how-photographer.jpg") +
            ")"
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 style={{color:"white"}}>
               Temporarily Unavailable
              <br />
              <br/>
              <p className="error-msg" style={{fontSize:"22"}}>
                
                We're sorry, but the content you have requested is currently being updated 
                and is temporarily unavailable. Be sure to check back soon.<br />
                
              </p>
            </h1>
          </Row>       
        </Container>
      </div>
    </>
  );
}

export default Unavailable;
