import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Cast(){
  return (
    <>
      <TopHeader title="Type Casting (Type Conversions)" />
      <p>
Type casting allows us to change the data type of a variable for one line of code.
This can be useful as programmers often need to sometimes treat variables differently
to accomplish certain tasks. It's actually a very simple operation. You'll see more use
cases in future examples and larger examples. For now let's just learn the syntax and 
how to use it. A sample code is shown below.
</p>
<CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  float var = 9.8; //declares & initializes var to hold 9.8
  
  cout << "the value is " << (int) var << endl; //<-casting var as an int
`} />
<p> Output:</p>
<CodeBlock notCode code={`
the value is 9
`} />
<p>
  Basically putting (int) before a float will turn that float into an int for that specific 
  line of code. It does that by throwing away the decimal information. It does not round.
</p>
<br/>
<br/>
<h3><strong>Integer Division</strong></h3>
<br/>
<p>
  As mentioned in the math section, division between two numbers where at least one is a float 
  variable works like normal division. But if we divide two int variables, the computer returns
  and integer and we just lose the decimal information if there is anyway. One way to remedy this 
  is to cast one or both of the values as a float instead.
</p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  int x, y, a;
  float b;
  x = 5; y = 2;
  
  a = x/y;                    //integer division 
  b = (float)x / (float)y;    //casting to avoid integer divison
  cout << "a = " << a << endl;      //printing the results of int division
  cout << "b = " << b << endl;      //printing the results of normal divison
  cout << "x:" << x << " y:" << y << endl; //printing x and y again to check.
}

`}/>
<p>Output:</p>
<CodeBlock notCode code={`
a = 2
b = 2.500000
x:5 y:2
`} />

<p>
  We see that we were successfully able to change the type of x and y for one line only.
  Using x and y later in the print statement as integers worked and those variables allows
  retained their orignal integer values. There are many other uses for typecasting. Often 
  it's easier to temporarily change a character's type instead of storing two different 
  types of the same information. You will definitely see more use cases as you build larger 
  programs.
</p>
    </>
  );
}

export default Cast;
