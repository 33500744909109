import React from "react";
import Setup from './topics/Setup';
import Hello from './topics/Hello';
import Variables from './topics/Variables';
import IO from './topics/IO';
import Math from './topics/Math';
import Cast from './topics/Cast';
import Condition from './topics/Condition';
import If from './topics/If';
import WhileFor from './topics/WhileFor';
import BreakCont from './topics/BreakCont';
import OtherControl from './topics/OtherControl';
import ShortHand from './topics/ShortHand';
import Functions from './topics/Functions';
import Recursion from './topics/Recurse';
import SepComp from './topics/SepComp';
import Arrays from './topics/Arrays';
import Strings from './topics/Strings';
import Enum from './topics/Enum';
import Rand from './topics/Rand';
import WhatNext from './topics/WhatNext';
import EG1 from './topics/EG1';
import EG2 from './topics/EG2';
import EG3 from './topics/EG3';
import EG4 from './topics/EG4';
import EG5 from './topics/EG5';
import EG6 from './topics/EG6';
import EG7 from './topics/EG7';

export const header = "Python";
export const slug = "python";
export const vTab1 = "setup";

export const NavBuilder = [
  {navname: 'Basics (I/O, vars)', nid: 'basics', topics: 
    [
      {name: 'Setup', id: 'setup', route: <Setup/>},
      {name: 'Hello World', id: 'hello', route: <Hello/>},
      {name: 'Variables', id: 'vars', route: <Variables/>},
      {name: 'Input/Output (I/O)', id: 'io', route: <IO/>},
      {name: 'Basic Arithmetic (Math)', id: 'math', route: <Math/>},
      {name: 'Type Casting', id: 'castt', route: <Cast/>},
      {name: 'Basics Examples', id: 'eg1', route: <EG1/>}
    ]},
  {navname: 'Control Flow', nid: 'control', topics: 
    [
      {name: 'Conditional Math', id: 'condition', route: <Condition/>},
      {name: 'If/Else Statement', id: 'ifelse', route: <If/>},
      {name: 'While & For Loops', id: 'whilefor', route: <WhileFor/>},
      {name: 'Break & Continue', id: 'break-continue', route: <BreakCont/>},
      {name: 'OtherControl', id: 'othercontrol', route: <OtherControl/>},
      {name: 'Shorthand Operators', id: 'shorthand', route: <ShortHand/>},
      {name: 'Control Examples', id: 'eg2', route: <EG2/>}
    ]},
  {navname: 'Functions', nid: 'functions', topics: 
    [
      {name: 'Basic Functions', id: 'functions', route: <Functions/>},
      {name: 'Recursion', id: 'recursion', route: <Recursion/>},
      {name: 'Seperate Files', id:'separate', route: <SepComp/>},
      {name: 'Function Examples', id: 'eg3', route: <EG3/>}
    ]},
  {navname: 'Arrays', nid: 'arrays', topics: 
    [
      {name: 'Arrays', id: 'arrays', route: <Arrays/>},
      {name: 'Strings', id: 'strings', route: <Strings/>},
      {name: 'Array Examples', id: 'eg4', route: <EG4/>}
    ]},
  {navname: 'More Features', nid: 'misc', topics: 
    [
      {name: 'Enumeration', id: 'enum', route: <Enum/>},
      {name: 'Random Numbers', id: 'rand', route: <Rand/>},
      {name: "What's Next", id: 'whatnext', route: <WhatNext/>},
    ]},
]

