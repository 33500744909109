import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function SectionExamples() {
  return (
    <>
      <div className="section section-examples section-dark" id="examples">
        <Container>
          <Row>
            <div className="title text-center">
              <h2 className="title">Ready To Use Development Environments</h2>
              <h5 className="description">
                There is no computer setup needed to start learning now. The online code editor
                can be used immediately to run your code. Additional options such as 
                pre-configured Virtual Machines and local enironment set up are only Recommended
                for more experienced users.
              </h5>
            </div>
          </Row>
          {/*
          <Row>
            <Col md="4">
              <div className="details-text">Twitter Redesign</div>
              <Card className="card-image card-hover-effect">
                <Link to="/twitter-redesign" target="_blank">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/examples/twitter.png")}
                  />
                </Link>
              </Card>
            </Col>
            <Col md="4">
              <div className="details-text">Register Page</div>
              <Card className="card-image">
                <Link to="/register-page" target="_blank">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/examples/register.png")}
                  />
                </Link>
              </Card>
              <div className="details-text">Public Profile</div>
              <Card className="card-image">
                <Link to="/profile-page" target="_blank">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/examples/profile.png")}
                  />
                </Link>
              </Card>
              
            </Col>
            <Col md="4">
              <div className="details-text">Blog Posts</div>
              <Card className="card-image">
                <Link to="/blog-posts" target="_blank">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/examples/blog-posts.png")}
                  />
                </Link>
              </Card>
              
            </Col>
          </Row>
  */}
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
