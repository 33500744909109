import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Union(){
  return (
    <>
      <TopHeader title="Unions" />
      <p>
      Union is not really used that much.
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default Union;
