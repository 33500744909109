import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function VM() {
  return (
    <>
           <TopHeader title="Linux Virtual Machine" />
      <br />

      <p>We provide free VM images with all the tools already set up for you. We recommend using our images with Virtual Box. All you have to do is 
        follow the instructions below to get started.
        </p>

    </>
  );
}

export default VM;
