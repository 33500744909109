import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function WhileFor(){
  return (
    <>
      <TopHeader title="While & For Loops" />
      <p>
      The While and For loops are also used to control the flow of the code. These loops use 
      conditions just like the If/Else statement. If the condition is true, the block of code 
      in the While or For loop will will execute. After execution of the block is done, the 
      loop condition will be tested again. If the condition is still true, then 
      the code block inside the loop will run again. There are 4 important parts of the two loops 
      you should consider. 
      <br/>
      <br/>
      <strong>1. Condition Initialization</strong> - The initial state of the variable you will test 
      the condition on.
      <br/><strong>2. Test Condition</strong> - The test to determine if the loop should run again.
      <br/><strong>3. Condition Update</strong> - The conditon variable must be updated. If it is never 
      updated inside the loop, the loop while continue on forever.
      <br/><strong>4. Loop Body</strong> - This is the portion referred to above as the code block. It is the 
      group of code that will repeat if a condition is true.
      <br/>
      <br/>
      The example below shows both the while and for loop with comments 
      showing the labeled parts of the loop. The while loop is used to print
      2 5 6 8! and the for loop is used to print 3 2 1 0!.
      </p>

<CodeBlock code={`
public class while
{
  public static void main(String[] args)
  {
    int i, j;

    /* The While Loop */
    i = 2;          /* Condition Initialization */
    while(i < 9)   /* while loop Test Condition */
    {
      /* This the Start of the while Loop Body */
      
      System.out.println(i);
      i = i + 2; /* The while loop condition update */ 

      /* This the End of the while Loop Body */
    }
    //After the while loop exits.
    //The program continues here
    System.out.print("!");
    System.exit(0);
  }
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
2 4 6 8 !
`}/> 
<CodeBlock code={`
public class for
{
  public static void main(String[] args)
  {
    int j;
    
    /* The For Loop */
    /* The Condition Initalization, Test, and Update are all on one line */
    /* (<Condition Initialization>; <Condition Test>; <Condition Update>) */
    for (j = 3; j >= 0; j = j - 1) 
    {
      /* This the Start of the For Loop Body */
      
      System.out.println(j);

      /* This the End of the For Loop Body */
    }
    // After the for loop is done.
    // The program continues here.
    System.out.print("!");
    System.exit(0);
  }
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
3 2 1 0 !
`}/>  
<br/>
<p> 
  It's easy to see that these two loops are very similar. Most of the time, you should be able to 
  easily convert between a while loop and for loop. Many students want to know when you should pick 
  one or the other. A good rule of thumb to follow is:
  <br/><br/>
  <strong> 1. </strong> If you know how many times a loop should run before you start the loop, then a 
  <strong> for loop </strong> is usually used. This is usually if your looping through a set 
    amount of data or you may have calulated exactly how many times something must run.
  <br/><strong> 2. </strong> If the amount of times the loop will run is unknown, then a 
  <strong> while loop </strong>is usually used. This can happen in the case where you are 
  running a loop until the user enters a specific value, or if you're reading data from a file until you get to the end.
  <br/>
  <br/>
  Again, this is not a rule that must be followed. You can write your programs using a loop that 
  you are most comfortable with.
</p>
<br/>
<p>
  Just like the if statement, you can use the space between the curly brackets to add any amount
  of valid code to be repeated. And yes, nested while loops and nested for loops are also possible. 
  Some basic examples are posted below.
</p>
<p> <strong>Example 1: </strong> Build a program to allow a user to enter integers. The program should continue to allow the 
  the user to enter new integers until the user enters 0. Once the user enters 0, the program 
  should tell the user how many numbers were entered and also show the sum of all integers entered.
  The solution to this is shown below. 
</p>
<CodeBlock code={`
import java.util.Scanner;

public class while_eg
{
  public static void main(String[] args)
  {
    int input, count, sum;
    sum = 0;
    count = 0;
    
    //Get the users first input    
    /* The While Loop */
    Scanner val = new Scanner(System.in);
    input = val.nextInt();    /* Condition Initialization */
    while(input != 0)         /* Test Condition */
    {
      /* This the Start of the while Loop Body */
      
      sum = sum + input;    //sum all inputs
      count = count + 1;    //keep count of all the inputs

      input = val.nextInt(); /* Condition update */ 
    
      /* This the End of the while Loop Body */
    }
    System.out.printf("You entered %d numbers which sum to %d \n", count, sum);
    System.exit(0);
  }
}
`}/>
<p>Output:  With user input as 2 4 5 0</p>
<CodeBlock notCode code={`
2 4 5 0      <- User Input
You entered 3 numbers which sum to 11
`}/> 
<br/>
<br/>
<p>
  <strong>Example 2: </strong> Build a program that allows the user to enter an integer. The 
  factorial of that number should be printed to the screen. For example 5!(5 factorial) is eqaul 
  to 5*4*3*2*1 which is equal to 120. Another example is 3! = 3*2*1 = 6. In addtion, the program 
  should print how many times the for loop had to run.
</p>
<CodeBlock code={`
import java.util.Scanner;

public class for_eg
{
  public static void main(String[] args)
  {
    int input, count, fact;
    count = 0;

    Scanner val = new Scanner(System.in);
    input = val.nextInt();
    
    /* The For Loop */
    for (fact = 1; input > 1; input--) 
    {
      /* This the Start of the For Loop Body */
      
      fact =  fact * input;
      ++count;
      
      /* This the End of the For Loop Body */
    }

    System.out.printf("%d! = %d\n", input+count, fact);
    System.out.printf("This program looped %d times\n", count);
    System.exit(0);
  }
}
`}/>
<p>Output: with user input as 5</p>
<CodeBlock notCode code={`
5     <-User Input
5! = 120
This program looped 4 times
`}/>  
<br/>
<br/>
<p>The programs above just show some basic uses of loops. It should be apparent that 
  solutions to specific problems are not unique. The developer can choose to implement 
  different types of control and use different variables to accomplish the same goal. As 
  a new developer, you should focus on learning the tools, but you should also try to keep 
  in mind how any new tool you learn can be used to accomplish a specific task.
</p>
<h3>
  <strong>
  Common Mistakes With Loops
</strong>
</h3>
<p>
  There a bunch of common mistakes that some people make when learning loops. The common mistakes and 
  their solutions are shown below.
<br/><br/>
<strong> 1. The program seems to freeze or you get a lot of output on the screen. </strong> - In this 
case, students commonly forget to update the test condition variable. Make sure there is an update to 
the test condition. If there is no update, the test condition will always be true and your loop 
will run forever. 
<br/><br/>
<strong> 2. The program loop does not run at all. Or it doesn't run the correct amount of 
times </strong> The initialization of the condition varibale is just as important. 
If the program tries to test ({`x < 5`}), then x must be initilized or set to some number 
before hand. This way the loop knows where to start.
<br/><br/>
<strong> 2. The loop does not exit at the right time. Or the loop exits but still performs 
  unwanted code before exiting. </strong> - Check where your condition update is. Remember that 
  the entire loop body is executed before the condition is checked. You can reorganize your loop. 
  If reorganization is not possible, then this can be fixed with the break and continue keywords. 
  You can check that section if reorganizing your loop seems like 
  it might not work.
  
  </p>
    </>
  );
}

export default WhileFor;
