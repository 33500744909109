import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Enum(){
  return (
    <>
      <TopHeader title="Enumeration" />
      <p>
      Enumeration really helps you code become more readable. It also limits the number of 
      "things" you can put into a variable or into a function. It allows us to create a bin of only 
      specific integers. Below we create an enumerator named color and color can only take on the values 
      listed. By default the first option listed (red in this case), takes the value 0. The next option 
      takes the value 1 and so on. 
      </p>
      <CodeBlock code={`
#include <stdio.h>

enum color {red, green, blue};

int main()
{
    /* create a color variable named BackgroundColor */
    /* BackgroundColor's type is enum color */ 
    enum color BackgroundColor;
    BackgroundColor = red;
    printf("BGColor = %d\\n", BackgroundColor);
    BackgroundColor = green;
    printf("BGColor = %d\\n", BackgroundColor);
    BackgroundColor++;
    printf("BGColor = %d\\n", BackgroundColor);
    return 0;
}
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
BGColor = 0;
BGColor = 1;
BGColor = 2;
`}/>
<br/>
<p>
  Enums really are just integers. In the above example, BGColor could be assigned red, green, or blue. It's possible to assign 
  with the enum variables or with the corresponding integers but no other assignments are valid. 
</p>
<br/>
<p>
  In reality, most programming can be accomplished without the uses of enumarations. However, there are many times that 
  this is useful. Consider the case were you build a function that other developers will be using. If the input is an 
  enumeration, you can limit the input choices of the function. You wont need to worry about making the program robust 
  against invalid inputs. That's not the only case. Another case is using it to set user options or flags. 
  <br/><br/>
  By default the 
  first value of the enum is 0 but if that's changed to another number n. Then the next value will be assigne n+1 and so on.
  In addition we can actually assign each individual value. For user options and flags, it is common to assign every option 
  to be a power of 2. This often used in conjunction with the bitwise OR operator.
</p>
    </>
  );
}

export default Enum;
