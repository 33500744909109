import React from "react"

import CodeBlock from "./CodeBlock.js";

export default function SetupAll(){
    return(
        <>
        <p>hello</p>
        <p>
        If your Environment is already set up and you know how to run programs, then 
        you can skip this section.
        </p>
        <br/><br/><p> 
        There are two options for setting up your system for all the starter languages on 
        this site.
      </p>
      <br/>
      <p>
      1. Use shoCODE's All-In-One (AIO) starter package (Link Here)
      <br/>2. Set up your own Environment for the language you want. (Link Here)
      </p>
      
      <h3>shoCODE's AIO (easy play button)</h3>
      <br/><p>
      The shoCODE Package is a Linux environment. Since programs are nothing but text 
      you can choose any text editor you want. Just make sure your files have the right 
      extension for the language you are learning.
      </p>
      <p>
        1 - In the shoCODE AIO, open up the VS Code Application
        <br/> 2 - Click on File in the Menu at the top and Navigate to open
        <br/> File {"->"} Open or creat a new file
        <br/> 3 - Navigate to the File you want to open
        <br/> 4 - Press the play button in the upper right hand corner
      </p>

       <h3> shoCODE's AIO (compile your own)</h3>
      <p>
        In the shoCODE AIO, you can compile on the
        command line by using the gcc command. You can use the command line in VS Code by 
        clicking on Terminal in the menu and selecting New Terminal.
      </p>
      <p>
        C programing uses the .c extension.
        For example, you can create a .c text file named hello.c and fill it in with the 
        following code:
      </p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  printf("Hello, World");
}
`}/>
      <p>
        
      </p>

      <p>Save the file.
        You can then compile the file by typing the following command in the Terminal 
        you opened using VS Code.
      </p>
<CodeBlock code={`
gcc hello.c        
`}/>
      <p>
        You should now see that an executable file named a.out was created in your current folder.
        Finally, you can run by using the command shown below.
      </p>
<CodeBlock code={`
./a.out        
`}/>
      <p>
        One simple improvement we can make right of the back is the ability to rename the a.out file
        to whatever we want. For instance say we wanted to rename are executable from 'a.out' to 'hi'.
        The commands to compile and execute would change to this.
      </p>
      <CodeBlock code={`
gcc hello.c -o hi
./hi
`}/>
      <p>
        If all went well, you should see Hello, World printed to the screen followed by your program 
        ending. Now, don't go and skip the next lesson. The Hello World lesson will go more in detail 
        about the code and the nuances of printing to the screen.
      </p>
      </>
    );
}