import React from "react";
import { Link, useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Collapse,
} from "reactstrap";
// core components
import TopHeader from "../../../components/MyCustom/TopHeader.js";

import * as Topics from "./Topics";
import Error404Topic from "views/errorpages/Error404Topic.js";

function Index({ match }) {
  const [vTabs, setVTabs] = React.useState(Topics.vTab1);
  const [sTabs, setSTabs] = React.useState("");
  
  const location = useLocation();

  React.useEffect(() => {
    //find path ending
    const path = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    //set active tab
    setVTabs(path)
    
    //use active tab to set active side bar
    Object.values(Topics.NavBuilder).forEach((entry1) =>{
      Object.values(entry1.topics).forEach((entry2)=>{
        if(path === entry2.id)
            setSTabs(entry1.nid)
      })
    })

    },
    [location]
  )
  return (
    <>
      <div className="section">
        <div id={Topics.slug}>
          <Container fluid >
            <Row className="d-flex justify-content-center">
              <Col xl="2" lg="2" md="3" sm="4" xs="6">
                <div className="nav-tabs-navigation sticky-top">
                  <div className="nav-tabs-wrapper">
                    <TopHeader title={Topics.header} stick={false} />
                    <Nav
                      className="flex-column nav-stacked"
                      role="tablist"
                      tabs
                    >
                      {Topics.NavBuilder.map(({ navname, nid, topics }) => (
                        <div key={nid}>
                          <NavLink 
                          className={sTabs === nid ? "active activeNItem" : "nItem"}
                          onClick={() => {
                              if((sTabs === nid))
                                setSTabs('');
                              else
                                setSTabs(nid)
                          }}
                          >
                            <p>
                              <i 
                              className={sTabs === nid ? 
                                "nc-icon nc-minimal-down sho-icon-active":
                                "nc-icon nc-minimal-down sho-icon"}
                              />
                              &nbsp;
                            {navname}
                            </p>

                          </NavLink>
                          <Container key={`${nid}/collapse`} 
                          className="nItem"
                          isOpen={sTabs === nid ? true : false}
                          tag={Collapse}>
                            {topics.map(({ name, id }) => (
                              <NavItem key={id}>
                                <NavLink
                                  className={vTabs === id ? "active activeNLink" : "nLink"}
                                  to={`${match.url}/${id}`}
                                  tag={Link}
                                >
                                  <p>{name}</p>
                                </NavLink>
                              </NavItem>
                            ))}
                          </Container>
                        </div>
                      ))}

                    </Nav>
                  </div>
                </div>
              </Col>
              <Col xl="9" lg="9" md="8" sm="8" xs="6">
                {/* Tab panes */}
                <div className="SectionContent">
                  <Switch>
                    {Topics.NavBuilder.map(({ nid, topics }) => (
                      topics.map(({ id, route }) => (
                        <Route key={id} path={`${match.path}/${id}`} render={props => route} />
                      ))
                    ))}
                    <Redirect exact from={`${match.path}`} to={`${match.path}/${Topics.vTab1}`} />
                    <Route path="*" render={props => <Error404Topic />} />
                  </Switch>

                </div>
              </Col>
            </Row>
          </Container>

        </div>
      </div>
    </>
  );
}

export default Index;
