import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function BreakCont(){
  return (
    <>
      <TopHeader title="Break & Continue" />
      <p>
      In any loop, the break and continue keywords can be used to add additional control inside any 
      loop. The break statement will break the loop immediately and move on to code after the loop.
      The continue statement will skip the current iteration of the loop and immediately check the 
      test condition to make a decision on if it should loop again or break the loop. Both break and 
      continue are almost always used with an if statment.
      </p>
      <h3><strong>The Break Statement</strong></h3>
      <p> The following program scans in 10 positive integers from a user. The program then 
        displays the average of all numbers and exit the program. If a user enters a negative 
        number the program will print "invalid input" and exit the program.
      </p>
<CodeBlock code={`
#include<stdio.h>
int main()
{
  int num, sum, i, valid;
  sum = 0;
  valid = 1;
  
  /* A for loop that runs 10 times */
  for(i = 0; i < 10; i=i+1){
    scanf("%d", &num);

    if(num < 0){  /*if the number is negative */
      valid = 0;
      printf("invalid input\\n");
      break;
    }
    sum = sum + num;
  }

  /* If valid is still 1, print output */
  if(valid){
    printf("The average is: %d \\n", sum/10.0);
  }

  return 0;
}
`}/>
<p>Output: User input is 12 3 5 6 4 7 9 1 15 62
</p>
<CodeBlock notCode code={`
The average is: 12.4
`}/>
<br/>
<p>Output: User input is 12 3 -5 6 4 7 9 1 15 62
</p>
<CodeBlock notCode code={`
invalid input
`}/>
<br/>
<h3><strong>The Continue Statement</strong></h3>
<p>The following program will sum 10 integers, which are entered by the user. If a user enters 
  and integer that is less than 100, then that integer will not be used in the sum. 
</p>
<CodeBlock code={`
#include<stdio.h>
int main()
{
  int num, sum, i;
  sum = 0;
  /* A for loop that runs 10 times */
  for(i = 0; i < 10; i=i+1){
    scanf("%d", &num);

    if(num < 100){  /*if the number is < 100 */
      continue;
    }
    sum = sum + num;
  }

  /* print the sum to the screen */
  printf("The sum(ignoring numbers below 100) is: %d \\n", sum);
  return 0;
}
`}/>
<p>Output: User input is 12 125 5 225 4 7 9 350 15 62
</p>
<CodeBlock notCode code={`
The sum(ignoring numbers below 100) is: 700
`}/>
<br/>

<p>
  If a loop needs to be ended early, then break could be good choice. If we want to continue looping 
  but sometimes ignore some data, continue would be the correct choice. These are just some general 
  uses. Break and Continue can be used in anyway to accomplish specific designs.
</p>
    </>
  );
}


export default BreakCont;
