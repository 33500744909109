import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Variables() {
  return (
    <>
      <TopHeader title="Variables" />
      <p>
        You can think of variables as containers that can hold only one item. The
        type of an item is called a data type. In C we need to tell the program what
        data type a variable can hold. There are many data types but for now, let's
        focus on the three data types shown below.
      <br />
        <br />
        <strong>int</strong>   - declares a container for an integer (whole number)
      <br /><strong>float</strong> - declares a container for a decimal number
      <br /><strong>char</strong>  - declares a container to hold a character
      <br />
        <br />
        We can declare variables by using one of the keywords above (int, float, char)
        in front of a name that the programmer chooses to call the variable. We can then
        store information in the variables by using the assingment operator or equals(=)
        sign. For example, here's a short program.
      </p>



      <CodeBlock code={`
#include<stdio.h>
int main()
{
  int x; //declares an integer named x
  float y; //declares a float named y
  char ch;

  x = 1024 //x holds the number 1024
  y = 3.14 //y holds the number 3.14
  ch = 'y' //ch holds character y
  return 0; // The program exits 
}
`} />
      <p>
        Variables can be named with almost any name. You can use names like 
        'x_is_an_integer', 'characterx', or 'num5'. The only names you cannot 
        use are keywords already taken by the C language. You'll see more keywords 
        as you learn but you know some already. For instance you cannot name your 
        variable int, float, or char. Why? Well, we don't want to change the meaning 
        of those keywords, so an error will be shown when compiling.
</p>
<p>
  A common mistake is to think a variable can hold more than one "thing". Remember that
  they can only hold one at a time. So if I assign (ues the =) again. That variable will hold 
  a new value. Consider the code below.
</p>
<CodeBlock code={`
#include<stdio.h>
int main()
{
  int x; //declares an integer named x

  x = 5; //at this line x holds the number 5;
  x = 10; //x no longer holds 5, it holds 10
  return 0;
}
`} />
<p>
  <strong>Pop Quiz</strong> - What will the above program print to the screen if we compiled and 
  executed the program? The correct answer is nothing! There are no print statements 
  in the program so nothing will be printed to the screen. The next lesson will show you 
  how to print a variable to the screen. The following code just shows some different ways 
  that we can declare and initialize variables.
</p>
<CodeBlock code={`
#include<stdio.h>
int main()
{
  int x = 5; //declares & initializes x to hold 5
  int y = 0; //declares & initializes y to hold 0
  float a, b; //declare two decimal numbers
  a = b = 9.8 //x and y both hold 9.8
  
  y = x; // y now holds 5
  b = 3.14 //b now holds 3.14
  a = b; //a now holds 3.14
}
`} />

    </>
  );
}

export default Variables;
