import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function OtherControl(){
  return (
    <>
      <TopHeader title="Other Types Of Flow Control" />
      <p>
      This section will discuss other control flow statements you can use in C.
      </p>
      <h3><strong>The Do While Loop</strong></h3>
      <p> The do while loop behaves almost exactly the same as the while loop. The 
        difference is that the loop body is gauranteed to run the first time. The program 
        below is used to caluclate the factorial. Remember 5! (five factorial) = 5*4*3*2*1.
        The also demonstrates the while loop.
      </p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  int num, fact, i;
  
  cout << "Enter an Integer" << endl;
  cin >> num;
  if(num < 0)
  {
    cout << "invalid input" << endl;
    return 0;
  }
  if(num == 0)
  {
    cout << "0! = 1" << endl;
  }

  fact = 1;
  i=num;
  do
  {   /* The loop body will run at least 1 time */
    fact = fact * i;
    i = i - 1;
  }
  while(i>0); /* There's semicolon here */

  cout << num << "! = " << fact << endl;
  return 0;
}
`}/>
<p>Output: User enters 3</p>
<CodeBlock notCode code={`
3   <- User Input
3! =  6
`}/>
<br/>
<h3><strong>The Switch Statement</strong></h3>
<p> The switch statement can be used in place of the if...else if format to perform multiple checks.
  We can accomplish this by using the break statement. The case statement inside the switch statement 
  will execute code for the case that matches the switch variable's value.
  The default statement works like a catch all for any cases that are not defined. 
  The program below shows how the switch statement can be used.
</p>
<CodeBlock notCode code={`
#include<iostream>

using namespace std;

int main()
{
  int x;
  cout << "Please enter an Integer" << endl;
  cin >> x;
  
  switch(x)
  {
    case 1:
        cout << "Welcome!" << endl;
        break;
    case 3:
        cout << "How may I help you?" << endl;
        break;
    case 5:
        cout << "What is your name?" << endl;
        break;
    case 7:
        cout << "Please Wait a moment." << endl;
        break;
    default: /* This case acts like a catch all */
        cout << "That option is not available" << endl;
  }
}
`}/>
<br/>
<p>Output: User enters 3</p>
<CodeBlock notCode code={`
Please enter an Integer
3   <- User Input
How may I help you?
`}/>
<br/>
<p>Output: User enters 4</p>
<CodeBlock notCode code={`
Please enter an Integer
4   <- User Input
That option is not available.
`}/>
<p>Output: User enters 7</p>
<CodeBlock notCode code={`
Please enter an Integer
7   <- User Input
Please wait a moment.
`}/>
<br/>
<p>
  There can be any amount of valid code for each case and the default case is optional. In 
  additon, you can't use the switch statement with data types like float and double. It does 
  work with characters and enumerators though.
</p>
<p> There is another way to use the switch statement. An that is to let the code flow through 
  other cases without using the break statement. If a break is encountered,
  the switch statement will be exited. This case can be quite confusing to use and 
  will take some practice for beginners. The program below is made using the same switch statement 
  as above with some breaks taken out. Different ouptus are shown based on different user inputs.
</p>
<CodeBlock notCode code={`
#include<iostream>

using namespace std;

int main()
{
  int x;
  cout << "Please enter an Integer" << endl;
  cin >> x;
  
  switch(x)
  {
    case 1:
        cout << "Welcome!" << endl;
    case 3:
        cout << "How may I help you?" << endl;
    case 5:
        cout << "What is your name?" << endl;
        break;
    case 7:
        cout << "Please Wait a moment." << endl;
    default: /* This case acts like a catch all */
        cout << "That option is not available" << endl;
  }
}
`}/>
<br/>
<p>Output: User enters 1</p>
<CodeBlock notCode code={`
Please enter an Integer
1   <- User Input
Welcome!
How may I help you?
What is your name?
`}/>
<p>Output: User enters 3</p>
<CodeBlock notCode code={`
Please enter an Integer
3   <- User Input
How May I help you?
What is your name?
`}/>
<p>Output: User enters 5</p>
<CodeBlock notCode code={`
Please enter an Integer
5   <- User Input
What is your name?
`}/>
<p>Output: User enters 7</p>
<CodeBlock notCode code={`
Please enter an Integer
7   <- User Input
Please wait a moment.
That option is not available.
`}/>
<p>Output: User enters 0</p>
<CodeBlock notCode code={`
Please enter an Integer
0   <- User Input
That option is not available.
`}/>
<br/>
<p> The most common use for switch statements is to always use them with breaks.</p>
    </>
  );
}

export default OtherControl;
