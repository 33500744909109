import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function IO() {
  return (
    <>
      <TopHeader title="Input/Output (I/O)" />
      <h3><strong>Output</strong></h3> <br />
      <p>
        Now you know how to declare variables and store information in those variables.
        What can we do with variables? We can manipulate them, print them to the screen,
        and even store input from a user. This lesson will focus on printing a
        variable's contents to the screen and storing user input into a variable.
        To print a variable to the screen, we will still use the printf function but we
        will use it with something called conversion specifiers. With variables, the code must
        specify if it's an int, float, etc. When variables are printed to the screen,
        the print statment needs to know what kind of variable it's printing. The program
        below is the exact same program from the last lesson but has the addition of print
        statements.
      </p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  int x = 1024; 
  float y = 3.14
  char ch = 'y'

  //printing to the screen
  printf("the value in x is %d\\n", x);
  printf("y = %f \\n", y);
  printf("%c is stored in ch\\n",ch)

  //Print Multiple Variables to the screen
  printf("x: %d y: %f ch: %c \\n",x , y, ch)
  return 0;
}
`} /><p>
        Output:
</p>
      <CodeBlock notCode code={`
the value in x is 1024
y = 3.14
y is stored in ch
x: 1024 y: 3.140000 ch: y
`} />
      <p>
        The conversion specifiers are the <strong>%d, %f , and %c
    for printing ints, floats, and chars respectively.</strong>
        The last line of the program shows how you can print multiple
        variable to the screen in one print statement. The first specifier, in order
        from left to right, is %d (takes an integer), so the first variable after the ", must be an
        integer. The second specifier in order is %f so the  second variable used at the end of the
        print statement must be a float.
        The output of the program is shown below.
</p>

      <p>
        Note that we can use this printf statement to test our programs. We can make sure
        the code is doing what we expect. Consider the following code from the last lesson.
        By adding in print statements we can see what the program is executing in order.
</p>
      <CodeBlock code={`
#include<stdio.h>
int main()
{
  int x = 5; //declares and initializes x to hold 5
  int y = 0; //declares and initializes y to hold 0
  float a, b; //declare two decimal numbers
  a = b = 9.8 //a and b both hold 9.8
  
  printf("x: %d, y: %d, a: %f, b: %f\\n",x,y,a,b);
  y=x; //y now holds 5;
  printf("x: %d, y: %d, a: %f, b: %f\\n",x,y,a,b);
  b = 3.14 //b now holds 3.14
  printf("x: %d, y: %d, a: %f, b: %f\\n",x,y,a,b);
  a = b; //a now holds 3.14
  printf("x: %d, y: %d, a: %f, b: %f\\n",x,y,a,b);
}
`} />
      <p>
        Output:
</p>
      <CodeBlock code={`
x:5 ,y:0 ,a:9.800000 ,b:9.8000000
x:5 ,y:5 ,a:9.800000 ,b:9.8000000
x:5 ,y:0 ,a:9.800000 ,b:3.1400000
x:5 ,y:0 ,a:3.140000 ,b:3.1400000
`} />
      <br />
      <br />
      <h3><strong>Input</strong></h3>
      <br />
      <p>
        Now that we can print a variable to the screen, let's see how we can
           allow a user to store information into a variable in the program. Here
           we introduce a new function. The first function we learned was printf().
           This function is called scanf(). The scanf() function comes from stdio.h
           just like printf(). The code below, shows how we can use the scanf() function.
  </p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  int x;
  scanf("%d", &x);
  printf("You entered the number %d\\n", x);
}
`} />
      <p>
        Pay attention to the & symbol before the variable x. This is needed. The & gives the scanf
        function the address of the variable x. To fully understand what the & symbol is doing 
        you must progress up to the Pointers lesson.
        For now, you must put an & before the variables you want to scan in or your program will not
        compile. Running the above code will show nothing at first. The scanf statement is waiting
        for a user to input an integer. If you enter the number 35 and press enter, the output
        will be
  </p>
      <CodeBlock notCode code={`
35    <- User Input
You entered the number 35
`} />
      <p>
        It's better to put a print statement before a scan statement when asking for user input.
        It help the user of the program understand what they are supposed to do. Consider the following
        program.
</p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  float num;
  printf("Please enter a decimal number:\\n");
  scanf("%f", &num);
  printf("You entered the number %f\\n", num);
}
`} />
      <p>The output of the program with user input of 512 is shown below.</p>
      <CodeBlock notCode code={`
Please enter a decimal number:
512  <- User Input
You entered the number 512
`} />
      <p>
        Just like printing multiple variables, scanf() can also read in multiple variables.
        This is done in a very similar way. The scanf() with floats and ints will delimit numbers 
        by whitspace(newlines, tabs, spaces, etc.). The user input when running the program below 
        is seperated by spaces but the numbers can be seperated by any combination of whitespace and 
        a scan will only take the number up to the whitespace.
</p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  int x1, x2;
  float y;
  printf("Please enter two ints and a float:\\n");
  scanf("%d %d %f", &x2, &x1, &y);
  printf("\nx1: %d\\n x2: %d\\n y: %f\\n", x1, x2, y);
}
`} />
      <p>
        Output: Note the scan statement scans for x2 before x1.
</p>
      <CodeBlock notCode code={`
Please enter two ints and a float:
25 4 91.7    <- User Input

x1: 4
X2: 25
y: 91.700000
`} />
      <p>
        Make note of the multi scan being used in the above code. Specifiers in scanf() should
        be seperated by spaces only. If you put commas or some other delimiter, scanf() will not
        understand what you are trying to do.
</p>
      <p>
        Now let's deal with controlling the decimal on those float values. By default, printf() will
        show 6 decimal places for float variables. We can control the width of the output and the decimal
        places you want to print. This is accomplished by changing your %f specifiers in printf().
      <br /><br />
        <strong>%w.df</strong> - w and d are ints. Print a decimal number with total width w and d decimal places.
      <br /><strong>%5.2f</strong> - print a float with total width 5 and 2 decimal places.
      <br /><strong>10.1f</strong> - print a float with total width 10 and 1 decimal place.
      <br /><br />
        The width is the total count of characters being printed to the screen. The decimal places is how many
        characters after the decimal you want to print. There's some small nuances you should be aware of.
      <br />1. The decimal or period counts towards the width. It counts as 1 character.
      <br /> 2. If the decimal place causes the width of the output to be greater than the width specified,
        the decimal places win and override the width. The width is ignored in this case.
      <br /> 3. If the number has the correct decimal places specified but is still shorter than the width
        specified, then the number is padded on the left side by spaces.
      <br />
        That's all so wordy and confusing. So check out the example below and then come back and read
        this to hopefully gain a better understanding.
    </p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  float pi = 3.141593;
  
  //width 2 and 4 decimal places
  printf("%2.4f\\n", pi); //This is case 2 above
  
  //width 6 and 2 decimal places
  printf("%6.2f\\n", pi); //This is case 3 above
  
  //width 5 and 3 decimal places
  printf("%5.3f\\n", pi);

  //width 6 and 2 decimal places and leading 0
  printf("%06.2f\\n", pi);
  return 0;
}
`} />
      <p>
        Output:
</p>
      <CodeBlock notCode code={`
3.1416
3.14
3.142
003.14
`} />
<p>

</p>
      <br /><br />
      <h3><strong>Character I/O</strong></h3>
      <br /><p>
      Character I/O behaves differently than when you are scanning numbers like ints and floats.
      When scanning ints and floats, we can seperate input by whitespace (space, tab, newline, etc.) and 
      scanf will work properly. With characters we read one character at a time, which includes
      space, tab, and newline. That is because those are also characters.
      <br/><br/>
      Character I/O can be accomplished with printf and scanf using the specifier %c. Since 
      this is actually used often, C actually implements a seperate function for printf and 
      scanf. These functions are putchar() (equivalent to printf() with %c) and getchar() 
      (equivalent to scanf() with %c). The code below shows a very simple
      example to echo the user's input for 3 characters and then generate a print statement 
      to show all the inputs in one line.
      </p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  char ch1, ch2, ch3, ch4;

  /* This is how you can declare your own character */
  ch4 = 'y'; // single quotes must be used

  ch1 = getchar(); //similar to scanf("%c", &ch1);
  putchar(ch1);
  ch2 = getchar(); //similar to scanf("%c", &ch2);
  putchar(ch2);
  ch3 = getchar(); //similar to scanf("%c", &ch3);
  putchar(ch3);
  putchar('\n');
  putchar(ch4);
  putchar('\n');
  printf("The user entered %c %c %c\\n", ch1, ch2, ch3);
  return 0;
}
`} /> 
<p>
  Output:
</p>
<CodeBlock notCode code={`
s 5   <-User Input
s 5
y
The user entered s 5 
`} />
<br/><br/>
    <p>Working with characters can be tricky for new students. 
      This is because whitespace characters (tabs, newlines, spaces, etc.) all 
      count as individual characters. In addition, digits like 1, 2, and 3 also 
      have a character representation. The character 5 is not the same as the integer 
      5. In fact, characters do hold an integer value. The integer value is normally the 
      ascii value of the character. Each character has an asigned ascii value and can be 
      casted as an integer. You can also cast an ascii value to a character if you need that 
      character. Looking at an ascii chart, you also see that the character 'X' is different from 
      'x'. All of these factors tend to make learning about characters a large task. Sometimes 
      students understand characters more after progressing to Arrays and Strings. Strings are 
      generally easier for new students because the specifier(%s) causes scanf to be whitespace 
      tolerant, similar to what we saw with numbers.
    </p>

    <p>
      There are many specifiers a developer could use in printf() and scanf() because there are 
      many data types. These lessons are focused more towards new students and will mostly cover
      int, float, and char.
    </p>
    </>
  );
}
export default IO;
