import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function Shop() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("error-500");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-500");
    };
  });
  return (
    <>
      <div
        className="background-img"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/sections/jan-sendereks.jpg") +
            ")"
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 style={{color:"white"}}>
               Shop <br />
              <p className="error-msg" style={{fontSize:"22"}}>
                We will be selling environments that come set up ready to program as well as source code 
                from this site. Come back soon.
                 <br />
                
              </p>
            </h1>
          </Row>       
        </Container>
      </div>
    </>
  );
}

export default Shop;
