import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Rand(){
  return (
    <>
      <TopHeader title="Random Number Generation" />
      <p>
      Random number generation is useful in programming.
      you can use the Math.random() to generate a random number between 0 and 1. We can manipulate 
      that result by multiplying to generate numbers between 1 and 10.
      </p>
      <CodeBlock code={`
"use strict"

//declare variable
let x;

//assign a random integer, from 1 to 10, to x
//Math.random() returns a random number between 0 and 1.
x = Math.floor(Math.random()*11); 
// the floor function removes the decimal information

let y = parseInt(Math.random()*10)+1;
//another way to generate a random int from 1-10

//print x and y
console.log(x);
console.log(y);
`}/>
      <CodeBlock notCode code={`
4      <-should be different for everyone and everytime you run the program.
2      <-should be different for everyone and everytime you run the program.
`}/>
<br/>
<p>
  Math.random() can be further manipulate using similar methods for whatever random 
  generation is needed.
</p>

    </>
  );
}

export default Rand;
