import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Hello() {
  return (
    <>
      <TopHeader title="Hello World" />
      <p>
        Create a new .py file called hello.py and insert the following code.
      </p>
      <CodeBlock
        code={`
print("Hello World")
`} />
      <p>
        Then run the program.
        If Everything went smoothly you should see something similar to the output below.
      </p>
      <CodeBlock notCode code={`
Hello, World
`} />
      <p>
        Notice that anything
        that is typed between the two quotation marks will be printed to the screen. 
      <br />
      </p>

      <p>
        Comments 
        can be used to keep notes or give other developers clues about what 
        the program is trying to accomplish. There are a few ways you can comment in python.
        You can use """, ''', or # in differnt ways. Some basic comments are shown below.
        Comments are essentially ignored by the computer. We only use them for notes. 
      </p>
      <CodeBlock code={`
"""
this is a multi line comment. Anthing between 
the three quotations will be ignored. This type 
is usually used for long comments like a description
"""

'''
it can also be written with
three apostrophes
'''

"""
The program always starts at the top 
and goes 1 line at a time.
"""

# This is a one line comment.
print("3\\n")
# Anything after these '#' is ignored and can be placed between code
print("2\\n")
# \\n just means newline so the print function prints a newline
print("1\\n")
print("0\\n")
print("Bye\\n") # The program is finished
`} />
  <p> 
    The program started at the fist line and executed statements one at a time 
    until the program ends. This is an important concept in programming and will be 
    important to you as a programmer.
    <br/>
    This page just showed basic functionality. More detail about printing to the 
    screen will be shown in the Input/Output lesson. Before moving on,
    why not try building a program to print your own name, today's date, and 
    your favorite color to the screen on different lines.
  </p>
    </>
  );
}

export default Hello;
