import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Variables() {
  return (
    <>
      <TopHeader title="Variables" />
      <p>
        You can think of variables as containers that can hold only one item. The
        type of an item is called a data type. In JavaScript we don't need to strongly 
        type our variables. Basically, this means we can create containers and the language 
        knows what type of containers they are based on what we put in. Below is a program 
        that stores an Integer (whole number), float (decimal number) and a character. 
        x y and ch below are all variables that hold some information.
      </p>

      <CodeBlock code={`
//This program declares three different variable types: an integer float and character. It then sets 1024 to the integer x, 3.14 to the float y, and the character y to the character ch. The program stores these variables and ends the program when it hits return zero, the only thing that happens is values being stored into variables.
"use strict" //Required line
//Declaring variables x, y, and ch
let x; 
let y;
let ch;
 
x = 1024; //Set x equal to 1024
y = 3.14; //Set y equal to 3.14
ch = 'y'; //Set ch equal to the letter 'y'
`} />
      <p>
        Variables can be named with almost any name. You can use names like 
        'x_is_an_integer', 'characterx', or 'num5'. The only names you cannot 
        use are keywords already taken by the JavaScript language. You'll see more keywords 
        as you learn but you know some already. For instance you can't name a variable let.
</p>
<p>
  A common mistake is to think a variable can hold more than one "thing". Remember that
  they can only hold one at a time. So if I assign (ues the =) again. That variable will hold 
  a new value. Consider the code below.
</p>
<CodeBlock code={`
"use strict" // required

let x; //declares an integer named x
x = 5; // at this line x holds the number 5
x = 10; // x no longer holds 5, it now holds 10
`} />
<p>
  <strong>Pop Quiz</strong> - What will the above program print to the screen if we compiled and 
  executed the program? The correct answer is nothing! There are no print statements 
  in the program so nothing will be printed to the screen. The next lesson will show you 
  how to print a variable to the screen. The following code just shows some different ways 
  that we can declare and initialize variables.
</p>
<CodeBlock code={`
"use strict"

let x = 5; //declares & initializes x to hold 5
let y = 0; //declares & initializes y to hold 0
var a, b; //declare two decimal numbers
  a = b = 9.8; //x and y both hold 9.8
  
  y = x; // y now holds 5
  b = 3.14; //b now holds 3.14
  a = b; //a now holds 3.14
`} />

    </>
  );
}

export default Variables;
