import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap"
// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";

function Intro() {
  return (
    <>
      <TopHeader title="Introduction to Python 100" />
      <p>
        Welcome to Python 100 (ages 10-14). This course is designed for younger students who are 
        completely new to programming. If you found this portion of the website, you've already signed up
        for a class and have live tutoring once a week.
      </p>
      <br/>
      <h4>Programming Environment</h4>
      <p>There's no need to install any programming environment. Registered students are automatically given access
        to a web code editor that you can use to program in python. Please ask your Instructor if you can't find the
        python code editor.
      </p>
      <br/>

      <h4><strong>How to use these notes</strong></h4>
      <p>
        First, make sure you communicate with your instructor during your weekly meetings. The Instructor 
        will go through the topics in the left navigation bar one by one. After the class and in between meetings 
        or tutoring you should use these notes to review what you learned.
        <br/>
        <br/>
        The instructor will also post homework challenges once in a while to make sure you are practicing and reviewing 
        what you learned.
      </p>
      
      
      
    </>
  );
}

export default Intro;
