import React from "react";

// reactstrap components
import {
  Button,
  Collapse
}from 'reactstrap';
// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";
import EGPreface from "../../../../components/MyCustom/EGPreface.js";

function EG4(){
  const [collapse, setCollapse] = React.useState({eg1:false,eg2:false,eg3:false});
  const [status, setStatus] = React.useState({eg1:"View Solution",eg2:"View Solution",eg3:"View Solution"});
  
  const updateCollapse = e =>{
    setCollapse({
      ...collapse,
      [e.target.id]: !collapse[e.target.id]   
    });
  };
  const updateEntered = e =>{
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Close Solution":" View  Solution ")
    });
  };
  const updateTransition = e =>{
    console.log(e.id)
    setStatus({
      ...status,
      [e.id]: (collapse[e.id]?"Opening...":"Closing...")
    });
  };


  //const toggle1 = () => setCollapse(!collapse);
  return (
    <>
      <TopHeader title="Arrays Section Examples" />
      <EGPreface/>
      <p>
        <strong>Problem 1</strong>
        <br/><br/>
        Build a program to scan in 10 numbers(float) from a user. After recieving all the data, the program should 
        prompt the user to enter 1 or 2. If 1 is selected, the program should search for the number 10.0 in the data. The 
        program should display if it has that number or not. If 2 is slected, the program should test to see if there are 
        any data points above 100. The program should display if it found any data points over 100 or not.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg1" onClick={updateCollapse}>{status.eg1}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg1" 
      isOpen={collapse.eg1}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  float data[10], option, check;

  //Get the data from the users
  for(int i = 0; i <10; i++){
    scanf("%f", &x[i]);
  }

  //user should pick option 1 or 2
  printf("Enter (1) to check if 10.0 is in the data set\\n");
  printf("Enter (2) to check if there are numbers over 100\\n");
  scanf("%d", &option);

  check = 0; //check will be used in either option
  
  /* execute option 1 */
  if(option == 1){
    for(int i = 0; i< 10; i++){
      if(data[i]==10.0){
        check = 1;
        break;
      }
    }
    if(check==1)
      printf("10.0 Found!\\n");
    else
      printf("10.0 NOT Found!\\n");
  }

  /* execute option 2 */
  if(option == 2){
    for(int i = 0; i<10; i++){
      if(data[i]>100){
        check = 1;
        break;
      }
    }
    if(check==1)
      printf("Numbers over 100.0 Found!\\n");
    else
      printf("No Numbers over 100.0 Found!\\n");
  }

  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 2</strong>
        <br/><br/>
        Build a program to scan in the user's first name as a string. You can assume that someone's first name will never 
        be longer than 100 characters. The program should display how long the users first name is and also display the first the 
        5th character in the name. If there is no fifth character in someone's name, then the program should display
        'no fifth character'.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg2" onClick={updateCollapse}>{status.eg2}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg2" 
      isOpen={collapse.eg2}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables
  char name[100];
  int count;

  //Get user's first name
  printf("Enter your first name\\n");
  scanf("%s", name);

  count = 1;
  while(count[i] != '\0'){
    count++;
  }

  //print the count to the screen
  printf("The user's name is %d characters long\\n", count);
  if (count >= 5)
    printf("the 5th character is %c.\\n", name[4]);
  else
    printf("no fifth character\\n");

  return 0;
}
`}/>
    </Collapse>
    <br/><br/>
    <p>
    <strong>Problem 3</strong>
        <br/><br/>
        Build a program with a 2D array.
      </p>
      <br/>
      <br/>
      <Button color="danger" id="eg3" onClick={updateCollapse}>{status.eg3}</Button>
      <br/>
      <br/>
      <Collapse 
      id="eg3" 
      isOpen={collapse.eg3}
      onEntered={updateEntered}
      onExited={updateEntered}
      onExiting={updateTransition}
      onEntering={updateTransition}
      >
      <CodeBlock code={`
#include<stdio.h>
int main(){
  //Declare Variables


  return 0;
}
`}/>
    </Collapse>
    </>
  );
}

export default EG4;
