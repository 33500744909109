import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function WhatIs() {
  return (
    <>
      <TopHeader title="What is Programming?" />
      <br />
      <p>
        This is a question we get a lot and actually it's very simple.
        A computer is a tool that will execute instructions step by step.
        This step by step document that a computer follows is simply called a
        program. Think about all the apps you use or games you play, all of
        those are programs that are just following a set of instructions. When
        you make the set of instructions, that's what programming is.
        <br /><br />  All computers speak the same/similar
        language. This is called assembly language or machine language.
        What most programmers do, is write code in an intermediate language
        like python, C, Java, etc. Once The code is written in one of those
        languages, a compiler (or interpreter in some cases) translates the
        code into machine language that the computer can then understand. So
        a compiler(or interpreter) translates your code from a language like C or python to
        an instruction set that a computer can use.
        <br /><br /> If you want to make your computer do something,
        you must give it a set of instructions. Instead of writing machine language
        or assembly language you can learn another language like C. If you write code 
        in that language then a compiler can understand you and will translate your 
        code into a program that a computer can understand. The computer can now follow 
        your instructions by running your program.
        <br /><br /> So you want to learn programming and become a developer.
        Where should you start? As a future programmer you'll have to decide
        on the following:
        <br />
        <br />1. Choose a starter language
        <br />2. Choose a development environment
        <br />
        <br />The side bar to your left has a link to how you can pick a Language.
        After that you should also read Pick an Environment. You can always
        Look up other reseources on your own but, here at shoCODE, we have
        put together a bunch of useful resources you can use.
      </p>
    </>
  );
}

export default WhatIs;
