import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Condition() {
  return (
    <>
      <TopHeader title="Conditional Statement" />
      <p>
        Before learning control statements like if/else and while, It is neccessary to understand
        conditional statements and how to implement those statments. These are really just
        boolean statements that return true or false. For computer programmin purposes, you should
        think of true as 1 and false as 0. In reality, computers usually see any non zero value as
        true. These statements are inequality statements from traditional math. Things like is x equal
        to y. Or is y less than or equal to z. The answers are just 1 or 0 for true or false.
        The operators for these statments are shown below.
        <br />
        <br />
        <strong>{`<`}</strong> - Less Than
        <br /><strong>{`>`}</strong> - Greather Than
        <br /><strong>{`<=`}</strong> - Less Than or Equal To
        <br /><strong>{`>=`}</strong> - Greater Than or Equal To
        <br /><strong>{`==`}</strong> - Equal To
        <br /><strong>{`!=`}</strong> - Not Equal To
      </p>
      <br />
      <br />
      <p>
        The code below show the basic use of conditional statements as well as printing the
        result of those statmenst to the screen. You can see that these output 0 for false
        and 1 for true.
      </p>
      <CodeBlock code={`
"use strict"
 
//declare variables
let result1, result2, result3, result4, result5, result6, result7;
let num1, num2;
 
num1 = 5;
num2 = 10;
 

result1 = (num1 < 8);
result2 = (num1 > 8);
result3 = (num1 > num2);
result4 = (num1 == num2);
result5 = (num1 != 5);
result6 = (num2 <= 10);
result7 = (num2 >= 8);
 
console.log("num1 < 8 : %d", result1);
console.log("num1 > 8 : %d", result2);
console.log("num1 > num2 : %d", result3);
console.log("num1 == num2 : %d", result4);
console.log("num1 != 5 : %d", result5);
console.log("num2 <= 10 : %d", result6);
console.log("num2 >= 8 : %d", result7);
`} />
      <p>
        Output:
</p>
      <CodeBlock notCode code={`
num1 < 8 : 1
num1 > 8 : 0
num1 > num2 : 0
num1 == num2 : 0
num1 != 5 : 0
num2 <= 10: 1
num2 >= 8 : 1
`} />
      <br />
      <h3> <strong>Logical AND (&&) and Logical OR (||)</strong></h3>
      <br />
      <p>
        The commands && and || stand for logical AND and logical OR respectively.
        You can use the && and || operators to test more than 1 conditional statements at 
        a time. 
      <br/>
      <br/><strong>&& </strong> - All statements involved must be true(1) in order for the whole statement to be 
      true. Otherwise the statement is false.
      <br/><strong>|| </strong> - Only one statement must be true(1) in order for the whole statement to be true.
      Otherwise the whole statement is false.
      <br/><br/> 
      Below is a basic example demonstrating && and ||.
      </p>
      <CodeBlock code={`
"use strict"

let num1 = 120;
let num2 = -13;
let result1, result2, result3;

//test if num1 larger than 100, smaller than 500 and even.
//result 1 should be true (1)
result1 = ( (num1 > 100) && (num1 < 500) && (num1 %2) == 0);

//test if num2 even or less than 0
//result2 should be true(1)
result2 = ( ( (num2 % 2) == 0 ) || (num2 < 0)  );

//test if num2 (positive) or (odd and less than num1)
//result3 should be true(1)
result3 = ( (num2 > 0) || (((num2 % 2)!=0) && (num2 < num1)) );

//print results to screen
//console.log is the same as printf statements.  \n isn't required and automatically done?
//result1
console.log("Is %d larger than 100 and smaller than 500 and even?",num1);
console.log("result1: %d",result1);
//result2
console.log("Is %d even or less than 0?",num2);
console.log("result2: %d",result2);
//result3
console.log("Is %d positive? Or, is it odd and less than %d?",num2,num1);
console.log("result3: %d",result3);
`} />
<p>
  Output:
</p>
<CodeBlock notCode code={`
Is 120 larger than 100 and smaller than 500 and even?
result1: 1
Is -13 even or less than 0?
result2: 1
Is -13 postive? Or, is it odd and less than 120?
result3: 1
`} />
    <p>
      All the statements above turn out to be true. Try your best to understand what's going on.
      Pay extra attention to all the parentheses in each of the statement. The parenthases should  
      be used to control which conditional statments you want to run in order. Depending on 
      the order of execution, the result might be completely different. These types of statements 
      are commonly used in controlling the flow of the program. Check of the IF/Else section 
      to try some simple control flow programming.
    </p>
    </>
  );
}

export default Condition;
