import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function IO() {
  return (
    <>
      <TopHeader title="Input/Output (I/O)" />
      <h3><strong>Output</strong></h3> <br />
      <p>
        Now you know how to declare variables and store information in those variables.
        What can we do with variables? We can manipulate them, print them to the screen,
        and even store input from a user. This lesson will focus on printing a
        variable's contents to the screen and storing user input into a variable.
        To print a variable to the screen, we simply need to give the variable to the cout function 
        The program below is the exact same program from the last lesson but has additonal cout statements.
      </p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  int x = 1024; 
  float y = 3.14;
  char ch = 'y';

  //printing to the screen
  cout << "the value in x is "<< x << endl;
  cout << "y = "<< y << endl;
  cout << ch << " is stored in ch" << endl;

  //Print Multiple Variables to the screen
  cout << "x: " << x << " y: " << y << " ch: " << ch << endl;
  return 0;
}
`} /><p>
        Output:
</p>
      <CodeBlock notCode code={`
the value in x is 1024
y = 3.14
y is stored in ch
x: 1024 y: 3.140000 ch: y
`} />
      <p>
        The output of the program is shown below.
</p>

      <p>
        Note that we can use these statements to test our programs. We can make sure
        the code is doing what we expect. Consider the following code from the last lesson.
        By adding in cout statements we can see what the program is executing in order.
</p>
      <CodeBlock code={`
#include<iostream>
using namespace std;
int main()
{
  int x = 5; //declares and initializes x to hold 5
  int y = 0; //declares and initializes y to hold 0
  float a, b; //declare two decimal numbers
  a = b = 9.8 //a and b both hold 9.8
  
  cout<< "x: << x << "y: " << y << "a: " << a << "b: " << b << endl;
  y=x; //y now holds 5;
  cout<< "x: << x << "y: " << y << "a: " << a << "b: " << b << endl;
  b = 3.14 //b now holds 3.14
  cout<< "x: << x << "y: " << y << "a: " << a << "b: " << b << endl;
  a = b; //a now holds 3.14
  cout<< "x: << x << "y: " << y << "a: " << a << "b: " << b << endl;
}
`} />
      <p>
        Output:
</p>
      <CodeBlock code={`
x:5 ,y:0 ,a:9.800000 ,b:9.8000000
x:5 ,y:5 ,a:9.800000 ,b:9.8000000
x:5 ,y:0 ,a:9.800000 ,b:3.1400000
x:5 ,y:0 ,a:3.140000 ,b:3.1400000
`} />
      <br />
      <br />
      <h3><strong>Input</strong></h3>
      <br />
      <p>
        Now that we can print a variable to the screen, let's see how we can
           allow a user to store information into a variable in the program. Here
           we introduce a new function. The first function we learned was cout.
           This function is called cin. The cin function comes from the std namespace just like
           cout. The std namespace comes from iostream.
           The code below, shows how we can use cin to scan in input from the user.
  </p>
      <CodeBlock code={`
#include<iostream>
using namespace std;

int main()
{
  int num;
  cin >> num;
  cout << "You entered the number " << num << endl;
}
`} />
      <p>
        Running the above code will show nothing at first. The cin statement is waiting
        for a user to input an integer. If you enter the number 35 and press enter, the output
        will be
  </p>
      <CodeBlock notCode code={`
35    <- User Input
You entered the number 35
`} />
      <p>
        It's better to put a cout (or print) statement before a cin (or scan) statement when asking for user input.
        It help the user of the program understand what they are supposed to do. Consider the following
        program.
</p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  float num;
  cout << "Please enter a decimal number:" << endl;
  cin >> num;
  cout << "You entered the number " << num << endl;
}
`} />
      <p>The output of the program with user input of 5.12 is shown below.</p>
      <CodeBlock notCode code={`
Please enter a decimal number:
5.12  <- User Input
You entered the number 5.12
`} />
      <p>
        Just like printing multiple variables, cin can also read in multiple variables.
        This is done in a very similar way but make sure to pay attention to the order
        Here's a sample code block to for scanning in multiple items.
</p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
    int x1, x2;
    float y;
    cout << "Please enter two ints and a float:" << endl;
    cin >> x2 >> x1 >> y;
    cout << "x1: " << x1 << endl;
    cout << "x2: " << x2 << endl;
    cout << "y: " << y << endl;
}
`} />
      <p>
        Output: Note the scan statement scans for x2 before x1.
</p>
      <CodeBlock notCode code={`
Please enter two ints and a float:
25 4 91.7    <- User Input

x1: 4
X2: 25
y: 91.700000
`} />

      <p>
        Now let's deal with controlling the decimal on those float values. By default, cout will
        show 6 decimal places for float variables. We can control the width of the output and the decimal
        places you want to print. Check out the code below.
    </p>
      <CodeBlock code={`
#include<iostream>
#include<iomanip>

using namespace std;

int main()
{
  float pi = 3.141593;
  
  //width 2 and 4 decimal places
  cout << fixed << setw(2) << setprecision(4) << pi << endl; //This is case 2 above
  
  //width 6 and 2 decimal places
  cout << fixed << setw(6) << setprecision(2) << pi << endl; //This is case 3 above
  
  //width 5 and 3 decimal places
  cout << fixed << setw(5) << setprecision(3) << pi << endl;

  //width 6 and 2 decimal places and leading 0
  cout << setfill('0') << fixed << setw(6) << setprecision(2) << pi << endl;

  return 0;
}
`} />
      <p>
        Output:
</p>
      <CodeBlock notCode code={`
3.1416
3.14
3.142
003.14
`} />
      <br /><br />
      <h3><strong>Character I/O</strong></h3>
      <br /><p>
      <br/><br/>
      Character I/O can be accomplished with cout and cin. Since 
      these operations happen often, C++ actually implements a seperate function for cout and 
      cin. These functions are putchar() and getchar(). The code below shows a very simple
      example to echo the user's input for 3 characters and then generate a print statement 
      to show all the inputs in one line.
      </p>
      <CodeBlock code={`
#include<iostream>

using namespace std;

int main()
{
  char ch1, ch2, ch3, ch4;

  /* This is how you can declare your own character */
  ch4 = 'y'; // single quotes must be used

  ch1 = getchar(); //similar to cin
  putchar(ch1);    //similar to cout
  ch2 = getchar(); //similar to cin
  putchar(ch2);    //similar to cout
  ch3 = getchar(); //similar to cin
  putchar(ch3);    //similar to cout
  putchar('\\n'); //\\n is the newline character. 
  putchar(ch4);
  putchar('\\n');
  cout << "The user entered " << ch1 << " " << ch2 << " " << ch3 << endl;
  
  return 0;
}

`} /> 
<p>
  Output:
</p>
<CodeBlock notCode code={`
s c 5   <-User Input
The user entered s c 5 
`} />
<br/><br/>
    <p>Working with characters can be tricky for new students. 
      Thins is because whitespace characters (tabs, newlines, spaces) all 
      count as individual characters. In addition, digits like 1, 2, and 3 also 
      have a character representation. The character 1 is not the same as the integer 
      1. It can be challenging to understand the small nuances when using getchar() but 
      the best way is to move forward and not get stuck here. After learning arrays 
      and strings(character arrays), it will be easier to work with and understand characters. 
    </p>
    </>
  );
}
export default IO;
