import React, { useEffect } from "react";
import { Link, useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import TopHeader from "../../components/MyCustom/TopHeader.js";

import { topics, header, pathhead, firsttopic } from "./Topics.js";
import Error404Topic from "views/errorpages/Error404Topic.js";

function Index({ match }) {
  const [vTabs, setVTabs] = React.useState({ firsttopic });
  const location = useLocation();

  useEffect(
    () => {
      const path = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
      if (path === { pathhead })
        setVTabs({ firsttopic })
      else
        setVTabs(path)


    },
    [location]
  )
  return (
    <>
      <div className="section">
        <div id={pathhead}>
          <Container fluid >
            <Row className="d-flex justify-content-center">
              <Col xl="2" lg="2" md="3" sm="4" xs="6">
                <div className="nav-tabs-navigation sticky-top">
                  <div className="nav-tabs-wrapper">
                    <TopHeader title={header} />
                    <Nav
                      className="flex-column nav-stacked"
                      role="tablist"
                      tabs
                    >

                      {topics.map(({ name, id }) => (
                        <NavItem key={id}>
                          <NavLink
                            className={vTabs === id ? "active nLink" : "nLink"}
                            onClick={() => {
                              setVTabs(id);
                            }}
                            to={`${match.url}/${id}`}
                            tag={Link}
                          >
                            <h6>{name}</h6>
                          </NavLink>
                        </NavItem>
                      ))}


                    </Nav>
                  </div>
                </div>
              </Col>
              <Col xl="9" lg="9" md="8" sm="8" xs="6">
                {/* Tab panes */}
                <div className="SectionContent">
                  <Switch>
                    {topics.map(({id, route}) => (
                        <Route key={id} path={`${match.path}/${id}`} render={props => route} />

                    ))}
                    <Redirect exact from={`${match.path}`} to={`${match.path}/${firsttopic}`} />
                    <Route path="*" render={props => <Error404Topic />} />
                  </Switch>

                </div>
              </Col>
            </Row>
          </Container>

        </div>
      </div>
    </>
  );
}

export default Index;
