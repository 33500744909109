import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Macros(){
  return (
    <>
      <TopHeader title="Macros" />
      <p>
      Enumeration really helps you code become more readable. It also limits the number of 
      things you can put into a variable or into a function. Enumeration can be helpful in binning
      as well.
      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default Macros;
