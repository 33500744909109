import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function Cast(){
  return (
    <>
      <TopHeader title="Type Casting (Type Conversions)" />
      <p>
Type casting allows us to change the data type of a variable for one line of code.
This can be useful as programmers often need to sometimes treat variables differently
to accomplish certain tasks. It's actually a very simple operation. You'll see more use
cases in future examples and larger examples. For now let's just learn the syntax and 
how to use it. A sample code is shown below.
</p>
<CodeBlock code={`
public class casting
{
  public static void main(String[] args)
  {
    float var = 9.8f; // declares & initializes var to hold 9.8
    
    // System.out.printf("the value is %d\n", var); // <-doesn't work
    System.out.printf("the value is %d\n", (int)var);// <-casting var as an int
  }
}
`} />
<p> Output:</p>
<CodeBlock notCode code={`
the value is 9
`} />
<p>
  Pay attention to the print statement above. It uses %d, which is for integers. Since
  var is not an int, the compiler will complain and tell the programmer that it's expecting
  an int. We can correct this by just casting var as an int. 
</p>
<br/>
<br/>
<h3><strong>Integer Division</strong></h3>
<br/>
<p>
  As mentioned in the math section, division between two numbers where at least one is a float 
  variable works like normal division. But if we divide two int variables, the computer returns
  and integer and we just lose the decimal information if there is anyway. One way to remedy this 
  is to cast one or both of the values as a float instead.
</p>
      <CodeBlock code={`
public class casting2
{
  public static void main(String[] args)
  {
    int x, y, a;
    float b;
    x = 5; y = 2;
    
    a = x/y;                    //integer division 
    b = (float)x / (float)y;    //casting to avoid integer divison
    System.out.printf("a = %d\n", a);      //printing the results of int division
    System.out.printf("b = %f\n", b);      //printing the results of normal divison
    System.out.printf("x:%d y:%d\n",x, y); //printing x and y again to check.
  }
}
`}/>
<p>Output:</p>
<CodeBlock notCode code={`
a = 2
b = 2.500000
x:5 y:2
`} />

<p>
  We see that we were successfully able to change the type of x and y for one line only.
  Using x and y later in the print statement as integers worked and those variables allows
  retained their orignal integer values. There are many other uses for typecasting. Often 
  it's easier to temporarily change a character's type instead of storing two different 
  types of the same information. You will definitely see more use cases as you build larger 
  programs.
</p>
    </>
  );
}

export default Cast;
