import React from "react";
import Online from './topics/Online.js';
import VM from './topics/VM.js';
import DIY from './topics/DIY.js';

export const header = "Dev Tools";
export const pathhead = "devtools";
export const firsttopic = "online";
export const topics = [
    {name: 'Online', id: 'online', route: <Online/>},
    {name: 'Virtual Machine', id: 'vm', route: <VM/>},
    {name: 'Do It Yourself', id: 'diy', route: <DIY/>},
  ]
