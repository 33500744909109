import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function SepComp(){
  return (
    <>
      <TopHeader title="Seperate Compilation" />

      <p>
      Separate compilation is pretty much the same on all systems. The only difference might be 
      in compiling. This section will discuss how to seperate function into files and how to 
      compile the seperate files together using GNU on Linux. Again, seperating functions into files 
      will be the same no matter which system you are compiling on.
      </p>
      <br/>
      <p>
        To separate functions to different files, you should understand what a function is 
        and what a function prototype is. Below is a program taken from the functions lesson. This 
        example will be used to show how to seperate functions into seperate files.
      </p>
<CodeBlock code={`
/* This is your only main.java file */
public class main
{
  public static void main(String[] args)
  {
    HelloWorld();    
  }
  public static void HelloWorld(){
    System.out.println("Hello, World!");
  }
}
`}/>
<br/>
<p> The above function is normally your only .java file. Suppose the file is named name.java. You might 
  compile such a file using the 'javac name.java' command. We can seperate this file into 2 different files.
  The names are choosen arbitrarily. These files are main.java, function.java. The seperation is shown in 
  the 2 files below.
</p>
<CodeBlock code={`
/* separate_function.java */
public class separate_function
{
  public static void HelloWorld()
  {
    System.out.println("Hello World!");
  }
}
`}/>
<br/>
<CodeBlock code={`
/* separate_main.java */
public class separate_main
{
  public static void main(String[] args)
  {
    separate_fuction.HelloWorld();    
  }
}
`}/>
<br/>
<p>
  You can see that the main function will start the program and then will eventually call the 
  HelloWorld() functon in the other file.
 </p>
<br/>
<p>
  Compiling becomes slightly different. Instead of 'javac name.java', we would use the 'javac separate_main.java  
  separate_function.java' command for the above example.
</p>
<br/>
<p>
  You can add as many methods to the classes in each file. Remember that only one main method is needed and
  and that main method is where the program starts.
  <br/>
</p>

<p>
  Developers should also not group their methods or functions randomly. They should group them according to use. 
  For example the you could put all methods and functions dealing with math in one file and put 
  utility functions in another file.
</p>
<br/>
    </>
  );
}

export default SepComp;
