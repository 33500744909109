import React from "react";

// reactstrap components

// core components
import TopHeader from "../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function ArgsToMain(){
  return (
    <>
      <TopHeader title="Arguments To Main" />
      <p>
      The main() function is a funtion like any other function. As a function, parameters 
      can be passed to the function. Check out the example below on how we can pass arguments 
      to main.

      </p>
      <CodeBlock code={`
Hello, World
`}/>
    </>
  );
}

export default ArgsToMain;
