import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import SetupAll from "../../../../components/MyCustom/SetupAll.js";
function Setup(){
  return (
    <>
      <TopHeader title="Setup - How To Compile & Run" />
      <SetupAll/>
    </>
  );
}

export default Setup;
