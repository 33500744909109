import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function FileIO(){
  return (
    <>
      <TopHeader title="File Input/Ouput (I/O)" />
      <p>
      File IO is done very similar to Command Line IO which is shown in the basics section. The functions look the same 
      except the first argument is a pointer to a FILE. The functions are fprintf() instead of printf() and fscanf() instead 
      of scanf(). An example using the I/O code from the basics section is converted for File I/O below. The fopen() function 
      used below uses the option 'w', wich openas a file for writing. If the file with the same name exists, then the file is  
      erased and the file will be a new empty file. All options are listed after the example. 
      </p>
      <CodeBlock code={`
#include<stdio.h>
int main(){
  int x1, x2;
  float y;

  FILE *file_pointer = fopen("NewFile.txt", "w");

  printf("Please enter two ints and a float:\\n"); //normal printf
  scanf("%d %d %f", &x2, &x1, &y); //normal scanf() 
  fprintf(file_pointer, "\\nx1: %d\\n x2: %d\\n y: %f\\n", x1, x2, y);
}
`} />
      <p>
        Output: (The output goes to the file NewFile.txt and not the screen)
</p>
      <CodeBlock notCode code={`
Please enter two ints and a float:
25 4 91.7    <- User Input

x1: 4         <- The Output from fprintf that should be in NewFile.txt
X2: 25
y: 91.700000
`} />
<br/>
<p>
  You can see above, that if we use fprintf() instead of printf(), we need to give it a pointer to a file. The print 
  statements will just go to the file and not the screen. The same holds true for scanf(). 
  If we use fscnaf() instead and give it a pointer to a file, it will read input from that file the same as it would 
  see on a command line. Below is the input data from the user followed by the same code above but modified for fscanf() 
  as well. The fopen option 'r' is used for read.
</p>
<CodeBlock notCode code={`
25 4 91.7

This file is named input.txt
`} />
<br/>
<CodeBlock code={`
#include<stdio.h>
int main(){
  int x1, x2;
  float y;

  FILE *in_file = fopen("input.txt", "r");
  FILE *out_file = fopen("output.txt", "w");

  fscanf(in_file, "%d %d %f", &x2, &x1, &y); 
  fprintf(out_file, "\\nx1: %d\\n x2: %d\\n y: %f\\n", x1, x2, y);
}
`} />
      <p>
        Output: (The output goes to the file output.txt and not the screen)
</p>
      <CodeBlock notCode code={`
x1: 4       
X2: 25
y: 91.700000
`} />
<br/>
<p>
  The above example used fscanf() to read the input data from a file named input.txt and then used 
  fprintf to print to a file name output.txt. There are 6 different options you can choose to use 
  with the fopen() function. All options are listed below:
  <br/>
  <br/>
  <strong> 1. </strong> "r" - opens the file for reading only. The file must already exist.
  <br/><strong>2. </strong> "w" - opens the file for writing. An existing file will be overwritten with a new file.
  <br/><strong>3. </strong> "a" - appends ot a file. This means adding to the end of an existing file. The file does 
  not have to exist. If it does not exist, it will be created.
  <br/><strong>4. </strong> "r+" - opens a file for read and write operations but the file must already exist.
  <br/><strong>5. </strong> "w+" - creates an empty file for read and write operations
  <br/><strong>6. </strong> "a+" - opens a file for read and append operations.
  <br/>
</p>
<p>
  Any combination of printf(), scanf(), fprintf(), and fscanf() is valid. It's up to the developer to design the program 
  as needed.
</p>
    </>
  );
}

export default FileIO;
