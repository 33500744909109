export const languages=[
    {"name": "Python 3", id: 71, hello: "print('Hello World!')"},
    {"name": "C", id: 50, hello: '#include<stdio.h>\nint main(){\n\tprintf("Hello World!");\n}'},
    {"name": "C++", id: 54, hello: '#include<iostream>\nint main(){\n\tstd::cout << "Hello World" << std::endl;\n}'},
    {"name": "Java", id: 62, hello: 'public class Main{\n\tpublic static void main(String[] args){\n\t\tSystem.out.println("Hello World!");\n\t}\n}'},
    {"name": "javascript", id: 63, hello: 'console.log("Hello World!")'}   
];
export function getHelloWorld(ID){
    console.log('getting hello')
    const result = languages.find(({id}) => id === ID );
    return result.hello
}