import React from "react";

// reactstrap components

// core components


function Error404Topic(){
  return (
    <>
      <h3>Error 404</h3>
      <br />
      <p>
        The page you requested could not be found. If you entered the url manually please make sure 
        that it is typed correctly. Otherwise, the content you are looking for may have been moved.
      </p>
    </>
  );
}

export default Error404Topic;
