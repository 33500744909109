import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../../components/MyCustom/TopHeader.js";
import CodeBlock from "../../../../components/MyCustom/CodeBlock.js";


function ShortHand(){
  return (
    <>
      <TopHeader title="Short Hand Notations" />
      <p>
      If you've made it this far into the lesson, you will have gained enough knowledge 
      to learn some of the basic shortcuts in this language. Below, are 3 common shortcuts 
      used very often in programming
      </p>
      <br/>
      <h3><strong>AutoIncrement/AutoDecrement</strong></h3>
      
<CodeBlock code={`
//Post Increment and Post Decrement

i++;     // equivalent to i = i + 1;
i--;     // equivalent to i = i - 1;

//Pre Increment and Pre Decremnt
++i;     // equivalent to i = i - 1;
--i;     // equivalent to i = i + 1; 
`}/>
<br/>
<p>
  At first glance, you'll think the post and pre operators above are the same. They technically do the 
  same thing but the difference is when the increment or decrement is done. It's about when the value 
  is actually updated. The pre operators evaluate the statement immediately. The post operators 
  evaluate the statement after the code updates to the next line. 
  The example below makes this very clear. 
</p>
<CodeBlock code={`
"use strict"

let x = 0, y = 0;

if(x++ > 0){
    console.log('x was updated');
}else{
    console.log('x was not updated');
}
console.log("the value of x is ", x);

if(++y > 0){
    console.log('y was updated');
}else{
    console.log('y was not updated');
}

console.log("The value of y is ", y);
`}/>
<p>Output: </p>
<CodeBlock notCode code={`
i WAS NOT updated above
The value of i is 1
j WAS Updated above
The value of j is 1
`}/>
<br/>
<p> 
  Both i and j above stored a final value of 1. But they updated at different times. The example 
  above showed that pre operators updated on the line they are typed. Post operators update the value 
  after the line that was typed.
</p>
      <h3><strong>Arithmetic Assignment Operators</strong></h3>
      <p> Auto increment or decrement always add or subtract a constant value of 1 to the 
        variable. It is possible to make the decrement or increment an arbritary variable.
        In addition, it is also possible to not just use addition and subtraction. You can also 
        use multiplication, division, and the modulus operator. These shorthand notations 
        and equivalents are shown below.
      </p>

<CodeBlock code={`
     j += 3        // equivalent to    j = j + 3
     k -= 2        // equivalent to    k = k - 2
     a *= b        // equivalent to    a = a * b
     c /= a+b      // equivalent to    c = c / (a+b)
     y %= 6        // equivalent to    y = y % 6
`}/>
<br/>

<h3><strong>Turnary Operator (shorthand if/else)</strong></h3>
<p>The Turnary Operator allows us to perform a shorthand if/else statement.
  For example suppose we had the following code. Pay attention to the if statement because
  that's what we'll change later.
</p>
<CodeBlock code={`
"use strict"
const prompt = require('prompt-sync')();

int data, newData;

data = prompt("Enter an Integer:");

if(data>0){
  newData = data/2;
}else{
  newData = data*data;
}

console.log("newData: ", newData);
`}/>
<br/>
<p>
  The equivalent if statment using the turnary operator would look like this.
</p>
<CodeBlock code={`
"use strict" 
const prompt = require('prompt-sync')(); 

let data, newData;
data = prompt('Enter an Integer:'); 

newData = (data > 0) ? data/2 : data*data;

console.log("newData " + newData);
`}/>
<p> The general form of the if statement is {`if ( <condition> ) <statement1> else < statement2 >`}.
The equivalent general form of the turnary operator is {`<condition> ? <expression 1> : <expression 2>`}.
This one is not so difficult to get used to. You can put multiple expressions together to form a compound 
statement using brackets or parenthesis.
</p>
<br/>
<p>
  There are many other shorthand operators out there. These are just some of the most common shortcuts seen.
</p>
    </>
  );
}

export default ShortHand;
