import React from "react";

// reactstrap components

// core components
import TopHeader from"../../../components/MyCustom/TopHeader.js";


function PickEnv() {
  return (
    <>
            <TopHeader title="Picking a Development Environment" />
      <br />
      <p>
        Basically, a Development Environment is collection of Tools
        that assist the programmer is testing and debugging a program
        or application. Nobody get's the program right the first time and
        I'm sure you've seen bugs or things just not working in programs
        sometimes. That's why extensive testing is needed. The environment you
        choose will have all the tools you need to test and debug anything you make.
        To move on you must have already choosen a language to start with.
        If you haven't yet, you can read about choosing a language here.
      </p>
      <p>
        Since you've choosen a language to start with already, It narrows down your
        choices a little bit. Even within a single language, there are a lot of different
        environments you can choose from. We will list them for each language we talk about
        at shoCODE below.
      </p>
    </>
  );
}

export default PickEnv;
