import React from "react";
import WhatIs from './topics/WhatIs.js';
import PickLang from './topics/PickLang.js';
import PickEnv from './topics/PickEnv.js';
import FirstProg from './topics/FirstProg.js';
import WhatNext from './topics/WhatNext.js';
import Expect from './topics/Expect.js';
import Learn from './topics/Learn.js';

export const header = "How to Start";
export const pathhead = "start";
export const firsttopic = "whatis";
export const topics = [
    {name: 'What is Programming', id: 'whatis', route: <WhatIs/>},
    {name: 'Pick a Language', id: 'picklang', route: <PickLang/>},
    {name: 'Pick an Environment', id: 'pickenv', route: <PickEnv/>},
    {name: 'Your First Program', id: 'firstprog', route: <FirstProg/>},
    {name: "What's Next", id: 'whatnext', route: <WhatNext/>},
    {name: "What To Expect", id: 'expectation', route: <Expect/>},
    {name: "Mindset To Learn", id: 'learning', route: <Learn/>},
  ]
