import React from "react";
import Highlight from "react-highlight";


function CodeBlock({notCode,code}){
    return(
        <pre className="CodeBox">
        <code>
          {!notCode ?
          <Highlight className="highlight">
        {code}
        </Highlight>
        :
        <div>
        {code}
        <br/>
        </div>
        }
        </code>
      </pre>
    )
}
CodeBlock.defaultProps = {notCode:false}
export default CodeBlock;